<template>
  <div v-if="isDialogShow" class="registrationDialog">
    <div class="registrationDialog_box">
      <div class="title_img"></div>

      <div class="close_box" @click="closeClick"></div>

      <div class="text_img"></div>

      <div class="input_box">
        <div class="registered">
          <div class="text">注册链接</div>
          <div class="content">
            <div
              class="link_test"
              :title="
                '写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
                code +
                '  ' +
                discountMsg
              "
            >
              http://passport.writ...
            </div>
            <div
              class="copy_text"
              :title="
                '写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
                code +
                '  ' +
                discountMsg
              "
              @click="copyClick(1)"
            >
              复制
            </div>
          </div>
        </div>
        <div class="zifu_text">
          复制注册链接，分享好友注册后双方各获得5000字符
        </div>
      </div>

      <div class="log_box"></div>
    </div>
  </div>
</template>
<script>
import { findUserRecommendCode } from "api/header";
export default {
  name: "registrationDialog",
  data() {
    return {
      isDialogShow: false,
      code: "",
      characterNum: "",
      discountMsg: "",
      isShowBtn: false,
      ishaveid: "",
      obj: "",
    };
  },
  watch: {
    isDialogShow: {
      handler: function (val) {
        if (val) {
          this.getFindUserRecommendCode();
        }
      },
    },
  },
  methods: {
    handelClick() {
      this.$emit("registerClick", true);
    },

    closeClick() {
      this.isDialogShow = false;
    },

    async getFindUserRecommendCode() {
      let user = JSON.parse(window.localStorage.getItem("vuex"));

      if (user.user_info) {
        this.isShowBtn = true;
        this.ishaveid = user.user_info.haveid;
      } else {
        this.isShowBtn = false;
        this.ishaveid = "";
      }
      const res = await findUserRecommendCode();
      // console.log(res);
      if (res.code != 200) {
        return this.$message({
          message: res.message,
        });
      }
      this.obj = res.data;
      this.code = res.data.code;
      this.characterNum = res.data.characterNum;
      this.discountMsg = res.data.discountMsg;
    },

    copyClick(val) {
      let oInput = document.createElement("input");
      val == 1
        ? (oInput.value =
            "写手智能科技公司专属注册链接，点击  " +
            this.obj.shareUrl +
            "     " +
            this.discountMsg)
        : (oInput.value = this.code);
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.registrationDialog {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;

  .registrationDialog_box {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 329.9px;
    height: 429.8px;
    padding-top: 60px;
    background: url("~assets/images/exclusive-registration/bgc2.png") no-repeat;
    background-size: 100% 100%;
    .title_img {
      position: absolute;
      top: -15px;
      left: 50%;
      width: 160px;
      height: 76px;
      transform: translateX(-50%);
      background: url("~assets/images/exclusive-registration/title.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .close_box {
      position: absolute;
      top: 35px;
      right: 15px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: url("~assets/images/exclusive-registration/close.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .text_img {
      margin: 0 auto 0;
      width: 294.6px;
      height: 89.8px;
      background: url("~assets/images/exclusive-registration/text6.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .input_box {
      margin: 0 auto;
      width: 329.2px;
      height: 185.4px;
      background: url("~assets/images/exclusive-registration/bgc3.png")
        no-repeat;
      box-shadow: 0px 4px 16px 0px rgba(4, 107, 221, 0.17);
      background-size: 100% 100%;
      transform: translateY(-20px);
      .registered {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .text {
          text-shadow: 0px 0px 28px #1067e8;
          margin: 30px 0 20px;
          text-align: center;
        }

        .content {
          display: flex;
          box-sizing: border-box;
          padding: 20px;
          background-color: #248eff;
          justify-content: space-between;
          align-items: center;
          width: 251px;
          height: 40px;
          background: #248eff;
          border: 2px solid #ffffff;
          border-radius: 19px;

          .copy_text {
            cursor: pointer;
          }
        }
      }

      .zifu_text {
        width: 172px;
        margin: 15px auto 0;
        color: #fff;
        line-height: 1.5;
        font-size: 12px;
        text-align: center;
        opacity: 0.7;
      }
    }

    .log_box {
      margin: 30px auto;

      width: 192px;
      height: 54px;
      background: url("~assets/images/exclusive-registration/log.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
