<template>
  <div class="header">
    <!-- 注册弹窗 -->
    <div v-if="register_flag" class="register-main">
      <div @click="registerCancel" class="login-cancel">
        <i class="el-icon-close"></i>
      </div>
      <div class="register-wrap">
        <div class="register-logo"></div>
        <!-- 输入框-->
        <div class="register-input">
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                name="nickname"
                v-model="nickName"
                placeholder="请输入昵称(选填)"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                @blur="Re_name"
                name="mobile"
                v-model="phone_number"
                maxlength="11"
                placeholder="请输入手机号(必填)"
              />
            </div>
          </div>
          <!-- <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                name="nickname"
                v-model="nickName"
                placeholder="请输入昵称(选填)"
              />
            </div>
          </div> -->
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                :type="R_show_pass ? 'text' : 'password'"
                name="password"
                maxlength="20"
                v-model="R_password"
                @input="
                  R_password = R_password.replace(/[\s\u4e00-\u9fa5]/g, '')
                "
                placeholder="请设置6-20位密码(必填)"
              />
            </div>
            <div class="register-input-type">
              <img
                v-if="!R_show_pass"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="R_show_pass = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="R_show_pass = false"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                :type="show_pass_again ? 'text' : 'password'"
                name="password"
                maxlength="20"
                v-model="password_again"
                @input="
                  password_again = password_again.replace(
                    /[\s\u4e00-\u9fa5]/g,
                    ''
                  )
                "
                placeholder="请再次输入密码(必填)"
              />
            </div>
            <div class="register-input-type">
              <img
                v-if="!show_pass_again"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="show_pass_again = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="show_pass_again = false"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input type="hidden" value="" id="rcodeId" />
              <input
                type="text"
                v-model="R_verif"
                placeholder="请输入验证码(必填)"
              />
            </div>
            <div
              class="register-input-sendcode"
              style="cursor: pointer"
              @click="R_sendVerif"
            >
              <span v-if="R_verif_seconds == 60">获取验证码</span>
              <span v-if="R_verif_seconds < 60"
                >已发送({{ R_verif_seconds }})</span
              >
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-tuijian.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                name="recommendCode"
                id="recommendCode"
                placeholder="请输入推荐码/体验卡（选填）"
              />
            </div>
          </div>
        </div>

        <div class="register-footer">
          <div class="register-footer-tips">
            已有账号，
            <a @click="toLogino" style="cursor: pointer">立即登录</a>
          </div>
          <div
            class="register-footer-btn"
            @click="register"
            style="cursor: pointer"
          >
            注册
          </div>
        </div>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <div v-if="login_flag" class="login-main">
      <div @click="loginCancel" class="close">
        <i class="el-icon-close"></i>
      </div>

      <div class="login">
        <!-- <div class="logo_box">
          <div class="login-logo">
            <img src="../../assets/images/logo1.png" height="70px" />
          </div>
        </div> -->
        <!-- cy_logo.png -->
        <div class="login_box">
          <div class="weixin">
            <div class="logo">
              <img :src="require('assets/images/logowit.png')" alt="" />
            </div>
            <div class="info">
              <div class="remark">
                <img :src="require('assets/images/gou.png')" alt="" />
                <span>AI成文 随意创作</span>
              </div>
              <div class="remark">
                <img :src="require('assets/images/gou.png')" alt="" />
                <span>专业文库 每日更新</span>
              </div>
              <div class="remark">
                <img :src="require('assets/images/gou.png')" alt="" />
                <span>优质模板 正版授权</span>
              </div>
              <div class="remark">
                <img :src="require('assets/images/gou.png')" alt="" />
                <span>闪亮金句 灵感源泉</span>
              </div>
            </div>
          </div>

          <div class="log_box">
            <div class="login-wrap">
              <div class="login-mode">
                <input
                  class="login-nav login-nav2"
                  id="mode2"
                  type="radio"
                  name="login-nav"
                />
                <input
                  class="login-nav login-nav1"
                  id="mode1"
                  type="radio"
                  name="login-nav"
                  checked=""
                />

                <input
                  class="login-nav login-nav3"
                  id="mode3"
                  type="radio"
                  name="login-nav"
                />
                <input
                  class="login-nav login-nav4"
                  id="mode4"
                  type="radio"
                  name="login-nav"
                />
                <ul class="login-mode-nav">
                  <li>
                    <label
                      for="mode1"
                      @click="
                        loginType = 2
                        phone_number_mode2 = phone_number_mode1
                      "
                      >手机登录</label
                    >
                  </li>
                  <li>
                    <label
                      for="mode2"
                      @click="
                        loginType = 1
                        phone_number_mode1 = phone_number_mode2
                      "
                      >密码登录</label
                    >
                  </li>

                  <li>
                    <label for="mode3" @click="loginType = 3">微信登录</label>
                  </li>
                </ul>
                <div
                  class="login-mode-content"
                  :style="{
                    display:
                      loginType == 1 || loginType == 2 ? 'block' : 'none',
                  }"
                >
                  <!-- 手机登录 -->
                  <div
                    class="login-mode-content-mode"
                    id="content-mode1"
                    :style="{ display: loginType == 1 ? 'flex' : '' }"
                  >
                    <form id="pwdLoginForm">
                      <div class="login-content-inpwrap">
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/user-blue.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input
                            type="text"
                            id="laccount"
                            name="mobile"
                            v-model="phone_number_mode1"
                            @input="
                              phone_number_mode1 = phone_number_mode1.replace(
                                /[^0-9]/g,
                                ''
                              )
                            "
                            maxlength="11"
                            placeholder="请输入手机号"
                          />
                        </div>
                      </div>
                      <div
                        class="login-content-inpwrap"
                        style="margin-top: 20px"
                      >
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/suo-blue.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input
                            :type="show_pass ? 'text' : 'password'"
                            id="password"
                            name="password"
                            v-model="password"
                            maxlength="20"
                            @input="
                              password = password.replace(
                                /[\s\u4e00-\u9fa5]/g,
                                ''
                              )
                            "
                            placeholder="请输入密码"
                          />
                        </div>
                        <div
                          class="login-content-inpwrap-type"
                          data-type="false"
                        >
                          <img
                            v-if="!show_pass"
                            :src="require('assets/images/login-changetype.png')"
                            alt=""
                            @click="show_pass = true"
                          />
                          <img
                            v-else
                            :src="require('assets/images/login-yan-h.png')"
                            alt=""
                            @click="show_pass = false"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- 短信登录 -->
                  <div
                    class="login-mode-content-mode"
                    id="content-mode2"
                    :style="{ display: loginType == 2 ? 'flex' : '' }"
                  >
                    <form id="codeLoginForm">
                      <div class="login-content-inpwrap">
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/user-blue.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            maxlength="11"
                            @input="
                              phone_number_mode2 = phone_number_mode2.replace(
                                /[^0-9]/g,
                                ''
                              )
                            "
                            v-model="phone_number_mode2"
                            placeholder="请输入手机号"
                          />
                        </div>
                      </div>
                      <div
                        class="login-content-inpwrap"
                        style="margin-top: 20px"
                      >
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/code-blue.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input
                            placeholder="请输入验证码"
                            type="text"
                            id="code"
                            v-model="verif"
                            name="code"
                          />
                        </div>
                        <div
                          class="login-content-inpwrap-sendcode"
                          id="btnSendcode"
                          @click="sendVerif"
                          style="cursor: pointer"
                        >
                          <span v-show="verif_seconds == 60">获取验证码</span>
                          <span v-show="verif_seconds < 60"
                            >已发送({{ verif_seconds }})</span
                          >
                        </div>
                        <input type="hidden" value="" id="lcodeId" name="id" />
                        <!-- <div class="login-content-inpwrap-type">
						  <img src="/images/login/login-changetype.png" alt="">
						</div> -->
                      </div>
                    </form>
                  </div>
                </div>
                <div
                  :style="{ display: loginType == 3 ? 'block' : '' }"
                  class="login-mode content3"
                  id="content-mode3"
                  style="margin-top: 30px; width: 100%"
                >
                  <div
                    class="scanContent"
                    id="scan-login"
                    style="
                      height: 250px;
                      text-align: center;
                      width: 298px;
                      margin-top: 40px;
                    "
                  >
                    <div style="height: 150px">
                      <iframe
                        v-if="isShowCode"
                        src="https://open.weixin.qq.com/connect/qrconnect?appid=wx0064c361acc150b7&scope=snsapi_login&redirect_uri=https://www.writemall.com/AuthRedirect&state=bind&login_type=jssdk&self_redirect=default&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7IGp1c3RpZnktY29udGVudDogY2VudGVyO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzOHB4OyBtYXJnaW4tdG9wOiAwcHg7IGJvcmRlcjogMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=="
                        frameborder="0"
                        scrolling="no"
                        width="138px"
                        height="138px"
                        target="_top"
                        sandbox="allow-scripts allow-top-navigation"
                      ></iframe>
                    </div>
                    <div
                      class="againGet"
                      @click="
                        () => {
                          isShowCodeClick()
                          isShowCode = false
                        }
                      "
                    >
                      重新获取
                    </div>
                    <div class="tips">
                      <img
                        :src="require('assets/images/login/weixin.png')"
                        alt=""
                      />
                      打开微信扫码即可登录
                    </div>
                  </div>

                  <div
                    class="login-mode-content-mode"
                    id="wcontent-mode1"
                    style="margin-top: 30px; display: none"
                  >
                    <!-- display:flex -->
                    <form id="wxForm">
                      <div class="login-content-inpwrap">
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/login-yonghu.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input
                            type="text"
                            id="waccount"
                            name="mobile"
                            v-model="phone_wx"
                            placeholder="请输入绑定手机号"
                          />
                        </div>
                      </div>
                      <div
                        class="login-content-inpwrap"
                        style="margin-top: 20px"
                      >
                        <div class="login-content-inpwrap-icon">
                          <img
                            :src="require('assets/images/login-codeico.png')"
                            alt=""
                          />
                        </div>
                        <div class="login-content-inpwrap-input">
                          <input type="hidden" value="" id="wcodeId" />
                          <input
                            type="text"
                            v-model="verif_wx"
                            placeholder="请输入验证码"
                          />
                        </div>
                        <div
                          class="login-content-inpwrap-sendcode"
                          style="cursor: pointer"
                          @click="sendVerif"
                        >
                          <span v-if="verif_seconds == 60">获取验证码</span>
                          <span v-if="verif_seconds < 60"
                            >已发送({{ verif_seconds }})</span
                          >
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- 底部操作 -->
                  <div
                    style="display: none !important"
                    class="login-footer content1"
                  >
                    <!-- display:flex -->
                    <div class="login-auto loginspwd" style="display: flex">
                      <input
                        type="checkbox"
                        id="wauto-login"
                        v-model="remenber_password"
                        name="avoidLogin1"
                        value="30"
                      />
                      <label for="wauto-login">自动登录</label>
                    </div>
                    <div
                      class="login-footer-btn"
                      id="wpwdBtn"
                      @click="toUpdate"
                      style="cursor: pointer; display: block"
                    >
                      登录
                    </div>
                  </div>
                </div>
                <div
                  :style="{ display: loginType == 4 ? 'block' : '' }"
                  class="login-mode content2"
                  id="content-mode4"
                  style="margin-left: 90px; margin-top: 30px; width: 258px"
                >
                  <div
                    class="scanContent"
                    id="scan-login-mobile"
                    style="margin-left: 50px"
                  >
                    <canvas width="158" height="158"></canvas>
                  </div>
                  <div
                    class="scanInfo"
                    id="scan-info"
                    style="margin-top: 10px; text-align: center"
                  >
                    <p id="scan-msg" style="color: #333333">
                      二维码已失效请刷新重试
                    </p>
                  </div>
                  <div
                    class="scanInfo"
                    style="margin-top: 10px; text-align: center"
                  >
                    <span
                      style="
                        width: 252px;
                        height: 12px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 500;
                        color: #999999;
                      "
                      >如无法正常扫码登录，请更新写手移动端版本</span
                    >
                  </div>
                  <input
                    type="hidden"
                    name="qrCodeKey"
                    value="ba310aa2-b50b-4d51-b4a5-01ebcb91e01e"
                    id="qrCodeKey"
                  />
                </div>
              </div>
              <!-- 底部操作 -->
              <div
                :style="{
                  display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
                }"
                class="login-tool content1 loginspwd"
              >
                <div class="login-auto loginspwd" style="display: flex">
                  <input
                    v-if="loginType == 1"
                    type="checkbox"
                    id="auto-login"
                    v-model="remenber_password"
                    name="avoidLogin1"
                    value="30"
                  />
                  <label v-if="loginType == 1" for="auto-login">记住密码</label>
                </div>
                <div class="login-aspwd loginspwd" style="display: block">
                  <span
                    style="margin-right: 10px"
                    class="login-forgetpwd"
                    @click="toRegister"
                    >注册</span
                  >
                  <span class="login-forgetpwd" @click="toForgot"
                    >忘记密码</span
                  >
                </div>
              </div>
              <div
                :style="{
                  display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
                }"
                class="login-footer content1"
              >
                <!-- <div class="login-footer-tips">
                  还没有注册账号，立即
                  <a @click="toRegister" style="cursor: pointer">注册</a>
                </div> -->
                <div
                  class="login-footer-btn"
                  id="pwdBtn"
                  @click="pwdLogin"
                  style="cursor: pointer"
                  :style="{ display: loginType == 1 ? 'block' : 'none' }"
                >
                  登录
                </div>
                <div
                  class="login-footer-btn"
                  id="codeBtn"
                  style="cursor: pointer"
                  :style="{ display: loginType == 2 ? 'block' : 'none' }"
                  @click="verifLogin"
                >
                  登录
                </div>
              </div>
              <div class="wx-login" v-if="loginType != 3">
                <img
                  v-if="!isiframe"
                  :src="require('assets/images/wx-blue.png')"
                  alt=""
                  @click="isiframe = !isiframe"
                />
                <div v-if="!isiframe">微信登录</div>
                <div v-else class="wxcode">
                  <iframe
                    src="https://open.weixin.qq.com/connect/qrconnect?appid=wx0064c361acc150b7&scope=snsapi_login&redirect_uri=https://www.writemall.com/AuthRedirect&state=bind&login_type=jssdk&self_redirect=default&styletype=&sizetype=&bgcolor=&rst=&style=black&href=data:text/css;base64,LmltcG93ZXJCb3gge2Rpc3BsYXk6IGZsZXg7IGp1c3RpZnktY29udGVudDogY2VudGVyO30KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEzOHB4OyBtYXJnaW4tdG9wOiAwcHg7IGJvcmRlcjogMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=="
                    frameborder="0"
                    scrolling="no"
                    width="138px"
                    height="138px"
                    target="_top"
                    sandbox="allow-scripts allow-top-navigation"
                  ></iframe>
                  <div
                    @click="
                      () => {
                        isiframeFun()
                        isiframe = false
                      }
                    "
                  >
                    重新获取
                  </div>
                </div>
              </div>
              <div class="wx-login" v-else>
                <img
                  :src="require('assets/images/phone-blue.png')"
                  alt=""
                  @click="goPhoneLogin"
                />
                <div>手机登录</div>
              </div>
              <!-- <div class="footer">
                <div class="left">
                  登录即同意<span>用户协议</span>和<span>隐私政策</span>
                </div>
                <div class="right" @click="toRegister">手机号注册></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 忘记密码弹窗 -->
    <div v-if="forgot_flag" class="login-main">
      <div @click="forgotCancel" class="login-cancel">
        <i class="el-icon-close"></i>
      </div>
      <div class="login-logo">
        <img src="../../assets/images/logo1.png" height="70px" />
      </div>
      <!-- 输入框 -->
      <div
        class="login-mode-content-mode"
        id="fcontent-mode1"
        style="
          margin-top: 30px;
          margin-left: 60px;
          display: flex;
          margin-right: 60px;
        "
      >
        <form id="forgotForm">
          <div class="login-content-inpwrap">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input
                type="text"
                id="faccount"
                name="mobile"
                maxlength="11"
                v-model="phone_forgot"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="login-content-inpwrap" style="margin-top: 20px">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input
                :type="f_show_pass ? 'text' : 'password'"
                id="fpassword"
                name="password"
                maxLength="20"
                v-model="password_forgot"
                placeholder="新密码6-20位字母或数字"
              />
            </div>
            <div class="login-content-inpwrap-type" data-type="false">
              <img
                v-if="!f_show_pass"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="f_show_pass = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="f_show_pass = false"
              />
            </div>
          </div>
          <div class="login-content-inpwrap" style="margin-top: 20px">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input type="hidden" value="" id="fcodeId" />
              <input
                type="text"
                v-model="verif_forgot"
                placeholder="请输入验证码"
              />
            </div>
            <div
              class="login-content-inpwrap-sendcode"
              style="cursor: pointer"
              @click="f_sendVerif"
            >
              <span v-if="f_verif_seconds == 60">获取验证码</span>
              <span v-if="f_verif_seconds < 60"
                >已发送({{ f_verif_seconds }})</span
              >
            </div>
          </div>
        </form>
      </div>
      <!-- 底部操作 -->
      <div style="display: flex" class="login-footer content1">
        <div class="login-footer-tips">
          已有账号，立即
          <a @click="toLogino" style="cursor: pointer">登录</a>
        </div>
        <div
          class="login-footer-btn"
          id="fpwdBtn"
          @click="toUpdate"
          style="cursor: pointer; display: block"
        >
          修改
        </div>
      </div>
    </div>
    <!-- 下载弹窗 -->
    <div v-if="downflag" @mouseleave="downflag = false" class="down">
      <div
        :class="wflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
      >
        <img v-if="wflag" src="../../assets/images/windows.png" />
        <img v-if="!wflag" src="../../assets/images/windows-m.png" />
        <span>Windows</span>
      </div>
      <div
        :class="iflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
        @click="clickIos"
      >
        <img v-if="iflag" src="../../assets/images/ios.png" />
        <img v-if="!iflag" src="../../assets/images/ios-m.png" />
        <span class="ml">Ios</span>
      </div>
      <div
        :class="aflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
        @click="clickIos"
      >
        <img v-if="aflag" src="../../assets/images/android.png" />
        <img v-if="!aflag" src="../../assets/images/android-m.png" />
        <span>Android</span>
      </div>
    </div>
    <!-- 修改信息弹窗 -->
    <div v-if="upFlag" class="means">
      <div class="nav">
        <div class="title">个人资料</div>
        <div @click="upclose" class="close">x</div>
      </div>
      <div class="uphead">
        <div
          class="wrap"
          @mouseover="enterAvatar = true"
          @mouseout="enterAvatar = false"
        >
          <input
            accept="image/*"
            @change="getup"
            ref="file"
            class="upfile"
            type="file"
          />
          <img
            class="head-img"
            :src="userInfoList.imgSrc ? userInfoList.imgSrc : defaultHeadImg"
            @error="imgError(userInfoList)"
          />
          <div
            class="mask"
            @click="handleClickAvatar"
            v-show="enterAvatar"
          ></div>
        </div>
      </div>
      <div class="upitem">
        <div class="item">
          <span>昵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称：</span>
          <input maxlength="7" minlength="2" v-model="userInfoList.nickName" />
          <div class="tip" :class="yes ? 'red' : ''">
            2-7个字符，支持中英文，数字
          </div>
        </div>
        <div class="item mt">
          <span>出生日期：</span>
          <el-date-picker
            v-model="userInfoList.birthday"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <!-- <input v-model="userInfoList.birthday" type="date" /> -->
        </div>
      </div>
      <div class="upitem">
        <div class="item-g">
          <span>性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
          <el-radio v-model="gender" label="0">男</el-radio>
          <el-radio v-model="gender" label="1">女</el-radio>
        </div>
        <div class="item">
          <span>个性签名：</span>
          <input
            placeholder="留下你的签名吧"
            v-model="userInfoList.signature"
            maxlength="50"
          />
        </div>
      </div>
      <div class="upitem">
        <div class="item">
          <span>手&nbsp;机&nbsp;号&nbsp;：</span>
          <input
            maxlength="11"
            @input="
              userInfoList.mobile = userInfoList.mobile.replace(/[^0-9]/g, '')
            "
            v-model="userInfoList.mobile"
          />
        </div>
        <div class="item-y">
          <span class="span">验&nbsp;证&nbsp;码&nbsp;：</span>
          <div class="send">
            <div class="login-content-inpwrap-icon icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input input">
              <input type="hidden" value="" id="fcodeId" />
              <input
                type="text"
                v-model="verif_up"
                placeholder="请输入验证码"
              />
            </div>
            <div
              class="login-content-inpwrap-sendcode sendcode"
              style="cursor: pointer"
            >
              <span @click="x_sendVerif" v-if="x_verif_seconds == 60"
                >获取验证码</span
              >
              <span v-if="x_verif_seconds < 60"
                >已发送({{ x_verif_seconds }})</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="upitem">
        <div class="item-j">
          <span class="introduce">个人简介：</span>
          <textarea
            v-model="userInfoList.introduction"
            maxlength="200"
            placeholder="介绍一下自己吧"
          ></textarea>
        </div>
      </div>
      <div style="text-align: center">
        <el-button @click="keep" type="primary">保存</el-button>
        <el-button @click="upclose">取消</el-button>
      </div>
    </div>
    <!-- 页头 -->
    <div
      class="wm-antiplagiarism-header"
      :class="
        routeName == 'InfoCompileIndex' ||
        routeName == 'templatetHtml' ||
        routeName == 'GoldenSentenceIndex' ||
        routeName == 'downloadPage' ||
        routeName == 'firmDynamic' ||
        routeName == 'cooperationPage' ||
        routeName == 'feedBackPage' ||
        routeName == 'join_about' ||
        routeName == 'IndexPage' ||
        routeName == 'shoppingMall' ||
        routeName == 'aboutus' ||
        routeName == 'speedPage' ||
        routeName == 'professional' ||
        routeName == 'help_center'
          ? 'bgcC'
          : ''
      "
    >
      <!-- <div class="wm-antiplagiarism-header-left">
        <div class="wm-antiplagiarism-header-left-logo" @click="logoClick">
          <img :src="require('assets/images/commonFooter/logo.png')" alt="" />
        </div>
        <div class="wm-antiplagiarism-header-left-nav"></div>
      </div> -->
      <!-- <div
        class="column"
        @click="
          $router.push('/TwentyBig');
          navitemIndex = -1;
        "
      >
        <img :src="require('assets/images/esd_icon.png')" alt="" />
        党的二十大专栏
      </div> -->
      <!-- navlist -->
      <div class="navlist newHeader">
        <newHeader :routeName="routeName" />
      </div>

      <!-- <div class="navlist">
        <div
          class="navitem"
          :class="navitemIndex == index ? 'itemActive' : ''"
          v-for="(item, index) in navLang"
          :key="index"
          @click="
            () => {
              navitemIndex = index;
              navClick(item);
            }
          "
        >
          {{ item.title }}
        </div>
      </div> -->
      <div class="wm-antiplagiarism-header-right">
        <!-- <div
          @click="vipClick"
          class="vipServer"
          :class="
            routeName == 'InfoCompileIndex' ||
            routeName == 'templatetHtml' ||
            routeName == 'GoldenSentenceIndex' ||
            routeName == 'downloadPage' ||
            routeName == 'firmDynamic' ||
            routeName == 'cooperationPage' ||
            routeName == 'feedBackPage' ||
            routeName == 'join_about' ||
            routeName == 'IndexPage' ||
            routeName == 'professional' ||
            routeName == 'shoppingMall' ||
            routeName == 'aboutus' ||
            routeName == 'speedPage' ||
            routeName == 'help_center'
              ? 'color'
              : ''
          "
        >
          <img :src="require('assets/images/newTweet/vip2.png')" alt="" />
          会员服务
        </div> -->
        <!-- <div class="netw" @click="$router.push({ path: '/disk' })">
					<img v-if="isEnterDisk" :src="require('assets/images/network-blue.png')" alt="" />
					<img v-else :src="require('assets/images/network.png')" alt="" />
					<span :class="isEnterDisk ? 'blue' : ''">智能网盘</span>
				</div> -->

        <!-- <div @click="go_down" class="download">软件下载</div>
        <div @click="go_feedback" class="download">意见反馈</div>
        <div @click="go_about" class="about">关于我们</div>
        <div @click="join_about" class="about">加入我们</div>
        <div @click="help_center" class="about">帮助文档</div> -->

        <div
          class="user"
          :class="
            routeName == 'InfoCompileIndex' ||
            routeName == 'templatetHtml' ||
            routeName == 'GoldenSentenceIndex' ||
            routeName == 'downloadPage' ||
            routeName == 'firmDynamic' ||
            routeName == 'cooperationPage' ||
            routeName == 'feedBackPage' ||
            routeName == 'join_about' ||
            routeName == 'IndexPage' ||
            routeName == 'shoppingMall' ||
            routeName == 'aboutus' ||
            routeName == 'speedPage' ||
            routeName == 'help_center'
              ? 'color'
              : ''
          "
          v-if="isLogin == true"
          @click="handleUserInfo"
        >
          <el-image
            style="
              width: 43px;
              height: 43px;
              margin-right: 10px;
              border-radius: 50%;
            "
            :src="userInfoList.imgSrc ? userInfoList.imgSrc : defaultHeadImg"
            @error="imgError(userInfoList)"
            fit="cover"
          ></el-image>
          <span class="login-link">{{ userInfoList.nickName }}</span>
        </div>
        <div
          class="user"
          :class="
            routeName == 'InfoCompileIndex' ||
            routeName == 'templatetHtml' ||
            routeName == 'GoldenSentenceIndex' ||
            routeName == 'downloadPage' ||
            routeName == 'firmDynamic' ||
            routeName == 'cooperationPage' ||
            routeName == 'feedBackPage' ||
            routeName == 'join_about' ||
            routeName == 'IndexPage' ||
            routeName == 'shoppingMall' ||
            routeName == 'aboutus' ||
            routeName == 'speedPage' ||
            routeName == 'help_center'
              ? 'color'
              : ''
          "
          v-else
        >
          <img
            @click="toLogino"
            :src="require('assets/images/user.png')"
            alt=""
          />
          <span class="login-link"
            ><span @click="toLogino">登录</span>/<span @click="toRegister"
              >注册</span
            ></span
          >
        </div>
      </div>

      <!-- 用户信息弹窗 -->
      <el-dialog
        :modal="false"
        top="0"
        :show-close="false"
        :visible.sync="userInfo"
        class="userInfoPopup"
      >
        <div class="user_info_box">
          <div class="user">
            <img
              class="headImg"
              :src="
                userInfoList.imgSrc
                  ? userInfoList.imgSrc
                  : require('@/assets/images/user.png')
              "
              alt=""
            />

            <div class="userName">
              <div class="name">
                {{ userInfoList.nickName }}
              </div>
              <div class="img_box">
                <img
                  v-if="userInfoList.isvip == 2"
                  :src="require('assets/images/VIP1.png')"
                  alt=""
                />
                <img
                  v-if="userInfoList.isvip == 3"
                  :src="require('assets/images/SVIP.png')"
                  alt=""
                />

                <span v-if="userInfoList.time">
                  {{ userInfoList.time }} 到期</span
                >
              </div>
            </div>

            <!-- <div class="exitLogin" @click="loginOut">退出登录</div> -->
          </div>
        </div>

        <div class="tab_box">
          <div
            class="tab_item"
            @click="tabListClick(index)"
            v-for="(item, index) in tabList"
            :key="index"
          >
            <img :src="item.img" alt="" />

            {{ item.name }}
          </div>
        </div>

        <div class="equity_text">尊享权益</div>

        <div class="equity_box">
          <div
            class="equity_item"
            v-for="(item, index) in equityList"
            :key="index"
            @mouseover="item.info = true"
            @mouseleave="item.info = false"
          >
            <div
              class="dialog1"
              v-if="item.name == '智能推文算法' && item.info"
            >
              <div class="title">可用算法引擎</div>
              <ul class="content">
                <li
                  v-for="(item, index) in arithmeticInfo.content"
                  :key="index"
                >
                  {{ index + 1 + '.' + item }}
                </li>
              </ul>
            </div>
            <div
              class="dialog2"
              v-if="item.name == '文库/模板/金句' && item.info"
            >
              <div class="h">文库/模板/金句免费使用类目</div>
              <div
                class="item"
                v-for="(item, index) in contextualInfo"
                :key="index"
              >
                <div class="title">{{ item.title }}</div>
                <div
                  class="content"
                  v-for="(item1, index1) in item.content"
                  :key="index1"
                >
                  {{ index1 + 1 + '.' + item1 }}
                </div>
              </div>
            </div>
            <img :src="item.img" alt="" class="icon" />

            <div class="top">
              {{ item.name }}
            </div>

            <div class="centre">
              {{ item.num }} <span>{{ item.type }}</span>
            </div>

            <div class="bottom">
              {{ item.describe }}
            </div>
            <!-- vipFree -->
          </div>

          <div class="equity_item">
            <img
              :src="require('assets/images/vipFree.png')"
              alt=""
              class="icon"
            />
            <div class="top">VIP体验卡</div>

            <div class="centre">
              <div class="btn" @click="getFreeVip">免费领取</div>
            </div>

            <div class="bottom">随意创作，每日更新</div>
          </div>
        </div>
        <div class="exit" @click="loginOut">退出登录</div>
      </el-dialog>

      <!-- 消息中心弹窗 -->
      <el-dialog
        :modal="false"
        top="0"
        :close-on-click-modal="false"
        :visible.sync="messageDialog"
        class="message_dialog"
        title="消息中心"
      >
        <el-tabs class="message_tabs" tab-position="left" style="height: 700px">
          <el-tab-pane>
            <div @click="getMessageList(1)" class="message_item" slot="label">
              <div class="img_box">
                <img src="../../assets/images/comments.png" alt="" /> 系统消息
              </div>
              <div v-if="unreadFindSysMsg.systemMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.systemMsgTotal }}
              </div>
            </div>
            <div class="comments">
              <div v-if="systemMessageList.length !== 0">
                <div class="comments_top">
                  <div class="text">系统消息</div>
                  <div
                    :class="isShowoperation ? 'operation' : 'red'"
                    @click="isShowoperation = !isShowoperation"
                  >
                    {{ !isShowoperation ? '编辑' : '取消' }}
                  </div>
                </div>
                <el-checkbox-group
                  v-model="check"
                  @change="handleCheckedCitiesChange"
                >
                  <div
                    class="comments_center"
                    v-for="(item, index) in systemMessageList"
                    :key="index"
                  >
                    <div class="center_box_top">
                      <div class="leftTemp">
                        <div class="choose">
                          <el-checkbox
                            :label="item.id"
                            v-show="isShowoperation"
                          >
                          </el-checkbox>

                          <div class="choose_box">
                            <div class="title">{{ item.title }}</div>
                            <div class="rigth">{{ item.createdate }}</div>
                          </div>
                          <div class="center_box_bottom">
                            {{ item.content }}
                            <!-- <span class="color_red">立即续费>></span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-checkbox-group>
              </div>
              <div class="img" v-else>
                <img src="../../assets/images/no-message.png" alt="" />
                暂无消息
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane>
            <div class="message_item" @click="getMessageList(2)" slot="label">
              <div class="img_box">
                <img src="../../assets/images/system-message.png" alt="" /> 评论
              </div>
              <div v-show="unreadFindSysMsg.commentMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.commentMsgTotal }}
              </div>
            </div>
            <div class="comments">
              <div v-if="commentsMessageList.length !== 0">
                <div
                  class="message_item_box"
                  v-for="(item, index) in commentsMessageList"
                  :key="index"
                >
                  <div class="message_title">
                    {{ item.title }}
                  </div>

                  <div class="message_content">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <div class="img" v-else>
                <img src="../../assets/images/no-message.png" alt="" />
                暂无评论
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <div class="message_item" @click="getMessageList(3)" slot="label">
              <div class="img_box">
                <img src="../../assets/images/praise.png" alt="" /> 赞
              </div>
              <div v-show="unreadFindSysMsg.praiseMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.praiseMsgTotal }}
              </div>
            </div>
            <div v-if="praiseMessageList.length !== 0">
              <div class="comments">
                <div class="comments_top">
                  <div class="text">赞</div>
                </div>

                <div
                  class="comments_center"
                  v-for="(item, index) in praiseMessageList"
                  :key="index"
                >
                  <div class="praise_top">
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                    {{ item.userName }}
                  </div>
                  <div class="praise_center">
                    点赞了
                    <img src="../../assets/images/fabulous-red1.png" alt="" />
                  </div>
                  <div class="praise_bottom">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="p">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="imgclass" v-else>
              <img
                class="img"
                src="../../assets/images/no-message.png"
                alt=""
              />
              暂无消息
            </div>
          </el-tab-pane>

          <el-tab-pane v-for="(item, index) in userMessageList" :key="index">
            <div
              class="message_item user_box"
              @click="getMessageList(4, item.userId)"
              slot="label"
            >
              <div class="img_box user_img">
                <img
                  :src="
                    item.headImg
                      ? item.headImg
                      : require('assets/images/user.png')
                  "
                  alt=""
                />
              </div>
              <div class="user_info">
                <div class="user_name">
                  <span class="name">{{ item.userName }}</span>
                  <span>{{ getTimer(item.sendTime) }}</span>
                </div>
                <div class="user_content">
                  <div class="content">
                    {{ item.content }}
                  </div>
                  <div v-show="item.isreadNum != 0" class="sum">
                    {{ item.isreadNum }}
                  </div>
                </div>
              </div>
            </div>

            <div class="user_message">
              <div class="user">
                {{ item.userName }}
              </div>
              <div class="message">
                <div class="date" v-show="usermessage.length != 1">
                  {{ getTimer(item.sendTime) }}
                </div>

                <div v-for="(item, index) in usermessage" :key="index">
                  <div v-show="item.is_show_time" class="date">
                    {{ getTimer(item.createdate) }}
                  </div>
                  <div v-show="item.userid == userId" class="left_mesage">
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                    <div class="text">{{ item.content }}</div>
                  </div>

                  <div class="rigth_mesage" v-show="item.userid != userId">
                    <div class="text">{{ item.content }}</div>
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="reply">
                <el-input
                  type="textarea"
                  :rows="7"
                  v-model="textarea"
                  maxlength="500"
                  show-word-limit
                  @keyup.enter.native="addmgs()"
                >
                </el-input>

                <el-button @click="addmgs(item.userId)">发送</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div class="comments_bottom" v-show="isShowoperation">
          <div class="select_all">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
          </div>
          <div class="delete" @click="delBatchMessage">删除</div>
        </div>
      </el-dialog>
    </div>

    <userInfoDialog ref="infoDialog"></userInfoDialog>

    <registrationDialog
      ref="registrationDialog"
      @registerClick="registerClick"
    />

    <registrationDialog1 ref="registrationDialog1" />
    <registrationDialog2 ref="registrationDialog2" />
  </div>
</template>
<script>
import registrationDialog from 'components/Exclusive-registration/registrationDialog.vue'
import registrationDialog1 from 'components/Exclusive-registration/registrationDialog1.vue'
import registrationDialog2 from 'components/Exclusive-registration/registrationDialog2.vue'
import Cookies from 'js-cookie'
import newHeader from './newHeader.vue'
import dayjs from 'dayjs'
import { getToken } from '../../utils/auth.js'
import userInfoDialog from 'components/header/userInfoDialog'
import {
  update,
  register,
  passLogin,
  codeLogin,
  getuserInfo,
  passwordLogin,
  userInfoUpd,
  getMobileAchCaptchas,
} from 'api/service'
import {
  FindSysMsg,
  findMsgInfoList,
  FindFriendMsg,
  Addmessage,
  Readmessage,
  DelBatchMessage,
} from 'api/header'
import {
  upload,
  getMobileAchCaptcha,
  checkMobileAchCaptchas,
  getFindRightsAndInterests,
} from 'api/common'
export default {
  name: 'Header',
  data() {
    return {
      contextualInfo: [],
      arithmeticInfo: {},
      navitemIndex: 1,
      //昵称
      nickName: '',
      isUser_info: '',
      //验证码
      verif_up: '',
      //验证码ID
      x_verif_code: '',
      //发送验证码计时
      x_verif_seconds: 60,
      //出生日期
      date: '',
      //修改信息弹窗
      upFlag: false,
      isShowCode: true,
      //下载图标
      wflag: false,
      iflag: false,
      aflag: false,
      //下载弹窗
      downflag: false,
      //【忘记密码】弹窗
      forgot_flag: false,
      //手机号
      phone_forgot: '',
      //密码
      password_forgot: '',
      //验证码
      verif_forgot: '',
      //发送验证码计时
      f_verif_seconds: 60,
      //验证码id
      f_verif_code: '',
      //显示密码
      f_show_pass: false,
      //【登录】密码登录输入手机号码
      phone_number_mode1: '',
      //验证码登录输入手机号码
      phone_number_mode2: '',
      //密码
      password: '',
      //验证码
      verif: '',
      //登录类型
      loginType: 2,
      //显示密码
      show_pass: false,
      //记住密码
      remenber_password: false,
      //验证码ID
      verif_code: '',
      //发送验证码计时
      verif_seconds: 60,
      //timer
      timer: null,
      //登录弹窗
      login_flag: false,
      //微信绑定手机号
      phone_wx: '',
      //微信验证码
      verif_wx: '',
      //【注册】手机号码
      phone_number: '',
      //密码
      R_password: '',
      //重复密码
      password_again: '',
      //显示密码
      R_show_pass: false,
      //显示重复密码
      show_pass_again: false,
      //验证码
      R_verif: '',
      //验证码ID
      R_verif_code: '',
      //发送验证码计时
      R_verif_seconds: 60,
      //推荐码(选填)
      recommendation_code: '',
      //注册弹窗
      register_flag: false,
      navLang: [
        {
          title: '首页',
          path: '/HomePage',
        },
        {
          title: '智能推文',
          path: '/recommendExperience',
        },
        {
          title: '专业服务',
          path: '/professional',
        },
        {
          title: '文库',
          path: '/infoCompile',
        },
        {
          title: '模板',
          path: '/templatetHtml',
        },
        {
          title: '金句',
          path: '/goldenSentence/goldenSentenceIndex',
        },
        {
          title: '智能网盘',
          path: '/disk',
        },
        {
          title: '核校',
          path: '/checking',
        },
        {
          title: '溯源',
          path: '/traceSource',
        },
        {
          title: '数据分析',
          path: '/dataAnalysisIndex',
        },
        {
          title: '网页采集',
          path: '/dataCollectionToolBar',
        },
        {
          title: '截图OCR',
          path: '/ocr',
        },
        {
          title: '下载成语',
          path: '/downloadGuide',
        },
        {
          title: '会员服务',
          path: '/VIPcenter',
        },
        {
          title: '商城',
          path: '/shoppingMall',
        },
        // {
        //   title: "成语写作课",
        //   path: "/videoWritingIndex",
        // },
        // {
        //   title: "第一议题学习专栏",
        //   path: "/depthLearn",
        // },
      ],
      tabList: [
        {
          name: '会员激活码',
          url: '',
          img: require('assets/images/user/tab1.png'),
        },
        {
          name: '个人云盘',
          url: '',
          img: require('assets/images/user/tab2.png'),
        },
        {
          name: '我的订单',
          url: '',
          img: require('assets/images/user/tab3.png'),
        },
        {
          name: '账号与资料',
          url: '',
          img: require('assets/images/user/tab4.png'),
        },
      ],
      equityList: [
        {
          name: '智能推文算法',
          describe: '根据鼠标选中或者输入的文本，精准匹配...',
          type: '种',
          num: 0,
          img: require('assets/images/user/icon1.png'),
          info: false,
        },
        {
          name: '成语券',
          describe: '余额可用于,需要成语券支付的商品',
          type: '券',
          num: 0,
          img: require('assets/images/user/icon2.png'),
        },
        {
          name: '截图取字',
          describe: '根据鼠标选中或者输入的文本，精准匹配...',
          type: '次',
          num: 0,
          img: require('assets/images/user/icon3.png'),
        },
        {
          name: '文库/模板/金句',
          describe: '当灵感匮乏时，可以输入指令，驱动AI智...',
          type: '类',
          num: 0,
          img: require('assets/images/user/icon4.png'),
          info: false,
        },
        {
          name: '智能网盘',
          describe: '根据鼠标选中或者输入的文本，精准匹配...',
          type: '',
          num: 0,
          img: require('assets/images/user/icon5.png'),
        },
        // {
        //   name: '字符余额',
        //   describe: '当灵感匮乏时，可以输入指令，驱动AI智...',
        //   type: '',
        //   num: 0,
        //   img: require('assets/images/user/icon6.png'),
        // },
      ],
      defaultHeadImg: require('assets/images/user.png'), //默认头像
      isLogin: false, //是否登录
      userInfo: false, //用户信息弹窗
      diskRario: 0,
      userInfoList: {
        isvip: 1, //1是普通会员 2是vip 3是svip
        imgSrc: require('assets/images/user.png'),
        name: '喝奶茶的恐龙',
        id: 2258697,
        totalSpace: '1GB',
        usedSpace: '0KB',
        time: '2020-02-02',
        character: '1.6W',
        birthday: '',
      },
      // 消息中心弹窗
      messageDialog: false,
      // 编辑
      isShowoperation: false,
      // 要删除的信息arr
      check: [],
      // 全选
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      // 系统消息
      systemMessageList: [],
      // 赞消息
      praiseMessageList: [],
      // 评论消息
      commentsMessageList: [],
      // 查询左侧用户未读消息
      userMessageList: [],
      // 用户消息
      usermessage: [],
      // 用户输入消息
      textarea: '',
      // 用户ID
      userId: this.$store.state.user_info?.id,
      //修改信息男女标识
      gender: null,
      //个性签名
      autograph: '',
      //验证手机号
      phone: '',
      //头像地址
      url: '',
      //修改手机是否正确
      phoneFlag: false,
      //个人简介
      introduction: '',
      //颜色标识
      yes: false,
      //昵称标识
      nameflag: true,
      //触摸头像
      enterAvatar: false,
      // 未读消息
      unreadFindSysMsg: '',
      // 路由name
      routeName: '',
      info: '',
      isiframe: false,
    }
  },
  components: {
    userInfoDialog,
    registrationDialog,
    registrationDialog1,
    registrationDialog2,
    newHeader,
  },
  computed: {
    isEnterDisk() {
      return this.$route.matched[0] && this.$route.matched[0].name === 'disk'
    },
  },

  mounted() {
    // if (!getToken("auth_token")) {
    //   this.login_flag = true;
    // }
    setTimeout(() => {
      this.navLang.forEach((item, index) => {
        // console.log(item.path, this.$route);
        if (this.$route.path == '/TwentyBig') {
          this.navitemIndex = -1
        }
        if (this.$route.path.indexOf(item.path) != -1) {
          this.navitemIndex = index
        }
      })
    }, 300)

    this.$bus.$on('changeNavIndex', (title) => {
      // console.log("changeNavIndex", index, this.selectShowMenu);
      this.navLang.forEach((item, i) => {
        if (item.title == title) {
          this.navitemIndex = i
        }
      })
    })

    window.loginFun = this.loginFun
    window.registerFun = this.registerFun
    window.addEventListener('scroll', this.scrollHandle, true)
  },
  methods: {
    async getEquityNum() {
      const res = await getFindRightsAndInterests()
      if (res.code == 200) {
        this.contextualInfo = res.data.contextualInfo
        this.arithmeticInfo = res.data.arithmeticInfo
        this.equityList[0].num = res.data.arithmetic
        this.equityList[1].num =
          res.data.aiPainting.freeQuantity + res.data.aiPainting.payedQuantity
        this.equityList[2].num = res.data.screenshots
        this.equityList[3].num = res.data.contextual
        this.equityList[4].num =
          res.data.disk.usedSpace + '/' + res.data.disk.totalSpace
        this.equityList[5].num = res.data.character
      }
    },
    tabListClick(index) {
      if (index === 0) {
        this.coupon()
      }

      if (index === 1) {
        this.$router.push('/disk')
      }

      if (index === 2) {
        this.handleCard()
      }
      if (index === 3) {
        this.updata()
      }
    },

    vipClick() {
      if (!getToken('auth_token')) {
        this.$message({
          message: '请先登录！',
          duration: 1500,
        })

        return window.loginFun()
      }
      if (this.$store.state.user_info.memberlevel == 1) {
        this.$router.push('/details/1/1')
      }
      if (
        this.$store.state.user_info.memberlevel == 2 ||
        this.$store.state.user_info.memberlevel == 3
      ) {
        this.$router.push('/details/1/2')
      }
    },
    isiframeFun() {
      setTimeout(() => {
        this.isiframe = true
      }, 800)
    },
    isShowCodeClick() {
      setTimeout(() => {
        this.isShowCode = true
      }, 800)
    },
    navClick(item) {
      this.$router.push({
        path: item.path,
      })
    },
    registerFun() {
      this.register_flag = true
    },
    loginFun() {
      this.toLogino()
      this.login_flag = true
    },

    // 专属优惠弹窗注册被点击
    registerClick(val) {
      this.register_flag = val
    },

    // 记住密码
    rememberClick(val) {
      if (val) {
        let infoobj = {
          phone: this.phone_number_mode1,
          password: this.password,
          remenber: val,
        }
        window.localStorage.setItem('infoobj', JSON.stringify(infoobj))
      } else {
        window.localStorage.setItem('infoobj', '')
      }
    },

    scrollHandle() {
      this.userInfo = false
    },
    // 图片容错
    imgError(val) {
      val.imgSrc = require('assets/images/user.png')
    },

    Re_name() {
      //  手机号验证
      if (this.phone_number == '' || this.phone_number.length != 11) {
        this.$message({
          type: 'error',
          message: '请正确输入手机号！',
        })
        return
      }
    },
    // 批量删除
    async delBatchMessage() {
      let form = new FormData()
      form.append('ids', this.check)

      const res = await DelBatchMessage(form)
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '删除成功',
        })
        this.getMessageList(1)
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    // 读消息
    async readmessage(id, type, sendid) {
      const data = {
        id,
        type,
        sendid,
      }

      const res = await Readmessage(data)
    },
    // 发送消息
    async addmgs(id) {
      const data = {
        userid: id,
        content: this.textarea,
        title: '试试',
        type: 4,
      }
      if (this.textarea == '') {
        return this.$message({
          type: 'error',
          message: '消息不能为空',
        })
      }
      const res = await Addmessage(data)
      this.textarea == ''
      this.getMessageList(4, id)
      this.findFriendMsg()
    },
    /*格式化日期*/
    getTimer(stringTime) {
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var week = day * 7
      var month = day * 30
      var time1 = new Date().getTime() //当前的时间戳
      var time2 = Date.parse(new Date(stringTime)) //指定时间的时间戳
      var time = time1 - time2
      var result = null
      if (time < 0) {
        alert('设置的时间不能早于当前时间！')
      } else if (time / month >= 1) {
        result = parseInt(time / month) + '月前'
      } else if (time / week >= 1) {
        result = parseInt(time / week) + '周前'
      } else if (time / day >= 1) {
        result = parseInt(time / day) + '天前'
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + '小时前'
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + '分钟前'
      } else {
        result = '刚刚'
      }
      return result
    },
    //循环处理时间
    /**
     * 参数
     * arr：数据数组
     * key：数组中对象的时间key键。
     * 新增属性
     * show_time_type：时间的类型
     * show_time：页面展示输出的时间
     * is_show_time：间隔上个时间是否大于5分钟，大于则显示当前时间，反之。
     **/
    checkShowRule(arr, key) {
      var newArr = arr.map((item, index, array) => {
        var obj = this.toggleTime(item[key])
        item['show_time_type'] = obj.type
        item['show_time'] = obj.time
        if (index > 0) {
          item['is_show_time'] = this.compareTimeInterval(
            array[index - 1][key],
            array[index][key]
          )
        } else {
          item['is_show_time'] = true
        }
        return item
      })
      return newArr
    },

    //根据不同时间的消息，输出不同的时间格式
    toggleTime(date) {
      var time
      var type = this.getDateDiff(date)
      //1：新消息，2：当天消息,3：昨天消息，4：今年消息，5：其他消息
      if (type == 1) {
        time = '以下为最新消息' //新消息，不显示时间，但是要显示"以下为最新消息"
      } else if (type == 2) {
        time = dayjs(date).format('H:mm') //当天消息，显示：10:22
      } else if (type == 3) {
        time = dayjs(date).format('昨天 H:mm') //昨天消息，显示：昨天 20:41
      } else if (type == 4) {
        time = dayjs(date)
          .format('M月D日 AH:mm')
          .replace('AM', '上午')
          .replace('PM', '下午') //今年消息，上午下午，显示：3月17日 下午16:45
      } else if (type == 5) {
        time = dayjs(date)
          .format('YYYY年M月D日 AH:mm')
          .replace('AM', '上午')
          .replace('PM', '下午') //其他消息，上午下午，显示：2020年11月2日 下午15:17
      }
      return {
        time: time,
        type: type,
      }
    },
    //判断消息类型
    getDateDiff(date) {
      var nowDate = dayjs(new Date()) //当前时间
      var oldDate = dayjs(new Date(date)) //参数时间
      var result
      if (nowDate.year() - oldDate.year() >= 1) {
        result = 5
      } else if (
        nowDate.month() - oldDate.month() >= 1 ||
        nowDate.date() - oldDate.date() >= 2
      ) {
        result = 4
      } else if (nowDate.date() - oldDate.date() >= 1) {
        result = 3
      } else if (
        nowDate.hour() - oldDate.hour() >= 1 ||
        nowDate.minute() - oldDate.minute() >= 5
      ) {
        result = 2
      } else {
        result = 1
      }
      return result
    },
    //判断两个时间差是否大于5分钟
    compareTimeInterval(t1, t2) {
      return dayjs(t2) - dayjs(t1) >= 300000 ? true : false
    },
    // 打开消息弹窗
    IsmessageDialog() {
      this.$router.push({
        name: 'MyCollection',
      })
      this.userInfo = false
      // this.messageDialog = true;
      // this.findSysMsg();
      // this.getMessageList(1);
      // this.findFriendMsg();
    },
    // 获取消息列表
    async getMessageList(type, userId) {
      const data = {
        userId,
        page: 1,
        limit: 100,
        type,
      }
      this.isShowoperation = false
      this.textarea = ''
      const res = await findMsgInfoList(data)
      if (res.code == 200) {
        if (type == 1) {
          this.systemMessageList = res.data.data
          this.readmessage('', 0)
        } else if (type == 2) {
          this.commentsMessageList = res.data.data
          this.readmessage('', 2)
        } else if (type == 3) {
          this.praiseMessageList = res.data.data
          this.readmessage('', 3)
        } else {
          this.readmessage('', 4, userId)
          this.usermessage = this.checkShowRule(
            res.data.data,
            'createdate'
          ).reverse()
        }
        this.findSysMsg()
        this.findFriendMsg()
      }
    },
    // 查询左侧用户未读消息
    async findFriendMsg() {
      const data = {
        page: 1,
        limit: 100,
      }

      const res = await FindFriendMsg(data)

      this.userMessageList = res.data.data
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.systemMessageList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.systemMessageList.length
    },
    // 全选
    handleCheckAllChange(val) {
      let arr = this.systemMessageList.map((item) => {
        return item.id
      })
      this.check = val ? arr : []
      this.isIndeterminate = false
    },
    // 查询未读消息
    async findSysMsg() {
      const res = await FindSysMsg()

      this.unreadFindSysMsg = res.data
    },
    lazy() {
      //正则验证
      var reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]{3,7}$/
      var regExp = new RegExp(reg)
      if (regExp.test(this.userInfoList.nickName)) {
        this.yes = false
        this.nameflag = true
      } else {
        this.yes = true
        this.nameflag = false
      }
    },
    //关闭修改框
    upclose() {
      this.upFlag = false
      this.yes = false
      this.userInfoList.mobile = this.phone
    },
    //发送验证码
    x_sendVerif() {
      if (this.userInfoList.mobile == '') {
        this.$message({
          type: 'error',
          message: '请输入手机号！',
        })
        return
      }
      let reg = /^1[3|4|5|7|8]\d{9}$/
      if (!reg.test(this.userInfoList.mobile)) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号！',
        })
        return
      }
      this.x_verif_seconds--
      let data = {
        type: 1,
        mobile: this.userInfoList.mobile,
      }
      getMobileAchCaptcha(data).then((res) => {
        if (res.code == 200) {
          this.x_verif_code = res.data.codeId
          this.x_setVerifTime()
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      })
    },
    //发送验证码计时
    x_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.x_verif_seconds > 0) {
            this.x_verif_seconds--
          } else {
            clearInterval(this.timer)
            this.x_verif_seconds = 60
            this.timer = null
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
      //setTimeout(this.checkMobileAchCaptcha(),60000)
    },
    //验证验证码
    async checkMobileAchCaptcha() {
      let data = {
        mobile: this.userInfoList.mobile,
        codeId: this.x_verif_code,
        code: this.verif_up,
      }
      let res = await checkMobileAchCaptchas(data)
      if (res.code == 200) {
        this.phoneFlag = true
      }
    },
    logoClick() {
      this.$router.push('/HomePage')
      this.$bus.$emit('changeNavIndex', '首页')
    },
    //更改信息
    async upData() {
      let data = {
        nickName: this.userInfoList.nickName,
        mobile: this.userInfoList.mobile,
        headImg: this.url,
        sex: this.gender,
        birthday: this.userInfoList.birthday,
        signature: this.userInfoList.signature,
        introduction: this.userInfoList.introduction,
      }
      let res = await userInfoUpd(data)
      if (res.code == 200) {
        this.upFlag = false
        this.$message.success('保存成功')
        this.date = ''
        this.gender = 3
        this.autograph = ''
        this.introduction = ''
        this.verif_up = ''
        this.userInfoList.mobile = this.phone
        this.getUserinfo()
      }
    },
    //修改信息
    async keep() {
      if (this.userInfoList.nickName.trim() == '') {
        this.$message.error('昵称不能为空')
        return
      }
      if (this.url == '') {
        this.url = this.userInfoList.imgSrc
      }
      console.log(this.userInfoList.mobile, this.phone)
      if (this.userInfoList.mobile != this.phone) {
        if (this.verif_up == '') {
          this.$message.error('请输入验证码')
          return
        }
        let data = {
          mobile: this.userInfoList.mobile,
          codeId: this.x_verif_code,
          code: this.verif_up,
        }
        checkMobileAchCaptchas(data).then((res) => {
          if (res.code == 200) {
            this.phone = this.userInfoList.mobile
            this.updata()
          } else {
            this.$message.error('手机号或验证码错误')
            return
          }
        })
      } else {
        this.updata()
      }
      this.upData()
    },
    //上传头像
    async getup() {
      let file = this.$refs['file'].files[0]
      if (file.type.indexOf('image') == -1) {
        this.$message.error(
          '上传文件必须是gif,jpg,jpeg,png,GIF,JPG,JEPG,PNG类型'
        )
        return
      }
      let form = new FormData()
      form.append('file', file)
      let res = await upload(form)
      if (res.code == 200) {
        this.url = this.$store.state.onlineBasePath + res.data
        this.userInfoList.imgSrc = this.url
      }
    },
    //点击头像
    handleClickAvatar() {
      this.$refs['file'].click()
    },
    //修改信息弹窗
    updata() {
      this.upFlag = true
      this.userInfo = false
      this.gender = String(this.userInfoList.sex)
    },
    //点击下载
    go_down() {
      // this.downflag = true;
      this.$router.push({
        path: '/downloadGuide',
      })
    },
    // 反馈
    go_feedback() {
      this.$router.push({
        name: 'Feedback',
      })
    },
    // 跳转
    clickIos() {
      if (event.target.outerText == 'Ios') {
        window.open('https://www.pgyer.com/VBDk')
      }
      if (event.target.outerText == 'Android') {
        window.open('https://www.pgyer.com/K2BH')
      }
    },
    //鼠标移入
    enter() {
      if (event.target.outerText == 'Windows') {
        this.wflag = true
      }
      if (event.target.outerText == 'Ios') {
        this.iflag = true
      }
      if (event.target.outerText == 'Android') {
        this.aflag = true
      }
    },
    //鼠标移出
    leaver() {
      if (event.target.outerText == 'Windows') {
        this.wflag = false
      }
      if (event.target.outerText == 'Ios') {
        this.iflag = false
      }
      if (event.target.outerText == 'Android') {
        this.aflag = false
      }
    },

    //关闭忘记密码弹窗
    forgotCancel() {
      this.forgot_flag = false
      this.phone_forgot = ''
      this.password_forgot = ''
      this.verif_forgot = ''
      this.f_verif_seconds = 60
      this.time = null
    },
    //发送验证码
    f_sendVerif() {
      if (this.timer) {
        return
      }
      let reg = /^1[3|4|5|7|8]\d{9}$/
      if (!reg.test(this.phone_forgot)) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号!',
        })
        return false
      }
      if (this.password_forgot == '') {
        this.$message({
          type: 'error',
          message: '请输入密码!',
        })
        return
      }
      if (this.password_forgot.length < 6) {
        this.$message({
          type: 'error',
          message: '密码不能小于6位!',
        })
        return
      }
      let data = {
        type: 2,
        mobile: this.phone_forgot,
      }
      this.f_setVerifTime()
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.f_verif_code = res.data.codeId
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      })
    },
    //发送验证码计时
    f_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.f_verif_seconds > 0) {
            this.f_verif_seconds--
          } else {
            clearInterval(this.timer)
            this.f_verif_seconds = 60
            this.timer = null
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    //修改密码
    async toUpdate() {
      let reg = /^1[3|4|5|7|8]\d{9}$/
      //  手机号验证
      if (!reg.test(this.phone_forgot)) {
        this.$message({
          type: 'error',
          message: '请正确输入手机号！',
        })
        return
      }
      // 密码验证
      if (this.password_forgot == '') {
        this.$message({
          type: 'error',
          message: '请输入密码！',
        })
        return
      } else if (this.password_forgot.length < 6) {
        this.$message({
          type: 'error',
          message: '密码不能少于6位',
        })
        return
      }
      // 验证码验证
      if (this.verif_forgot == '') {
        this.$message({
          type: 'error',
          message: '验证码不能为空！',
        })
        return
      }
      const data = {
        mobile: this.phone_forgot,
        codeId: this.f_verif_code,
        code: this.verif_forgot,
        newPassword: this.password_forgot,
      }
      const res = await update(data)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功！',
        })
        this.login_flag = true
        this.forgot_flag = false
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    //判断登陆状态
    iflogin() {
      if (!this.isLogin) {
        this.register_flag = false
        this.login_flag = false
      }
    },
    //【登录】点击取消登录
    loginCancel() {
      this.loginType = 2
      this.phone_number_mode1 = ''
      this.phone_number_mode2 = ''
      this.password = ''
      this.verif = ''
      this.login_flag = false
    },
    //去注册
    toRegister() {
      this.login_flag = false
      this.register_flag = true
      clearInterval(this.timer)
      this.timer = null
      this.x_verif_seconds = 60
      this.f_verif_seconds = 60
      this.verif_seconds = 60
      this.R_verif_seconds = 60
    },
    //忘记密码
    toForgot() {
      this.login_flag = false
      this.forgot_flag = true
      clearInterval(this.timer)
      this.timer = null
      this.x_verif_seconds = 60
      this.f_verif_seconds = 60
      this.verif_seconds = 60
      this.R_verif_seconds = 60
    },
    //手机验证
    validatePhone(phone) {
      let reg = /^1[3|4|5|7|8]\d{9}$/
      if (!phone) {
        this.$message({
          type: 'error',
          message: '请输入手机号!',
        })
        return false
      }
      if (!reg.test(phone)) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号!',
        })
        return false
      }
      return true
    },
    //密码登录
    pwdLogin() {
      this.rememberClick(this.remenber_password)
      if (!this.validatePhone(this.phone_number_mode1)) return
      if (!this.password) {
        return this.$message({
          type: 'error',
          message: '请输入密码!',
        })
      }
      const data = {
        mobile: this.phone_number_mode1,
        password: this.password,
      }
      passwordLogin(data)
        .then((res) => {
          let data = res
          localStorage.setItem('auth_token', JSON.stringify(data.access_token))
          data = JSON.stringify(data)
          //保存token
          Cookies.set('auth_token', data, {
            expires: 7,
            path: '/',
            domain: '.writemall.com',
          })
          this.$message({
            type: 'success',
            message: '登录成功!',
          })
          this.login_flag = false
          this.getUserinfo()

          if (this.routeName == 'TraceTheSource') {
            return
          } else if (this.routeName == 'StoreRightBox') {
            return
          }
          // console.log(this.$route.path);
          if (this.$route.path == '/AuthRedirect') {
            this.$router.push('/infoCompileIndex')
          }
          setTimeout(() => {
            const data = window.location.href
            if (data.includes('token=')) {
              location.href = 'https://www.writemall.com/HomePage'
            } else {
              window.location.reload(true)
            }
          }, 200)
        })
        .catch(() => {
          this.$message.error('手机号或密码错误')
        })
    },
    //发送验证码
    sendVerif() {
      if (this.timer) {
        return
      }
      if (!this.validatePhone(this.phone_number_mode2)) return
      const data = {
        type: 5,
        mobile: this.phone_number_mode2,
      }
      this.setVerifTime()
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.verif_code = res.data.codeId
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      })
    },
    //发送验证码计时
    setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.verif_seconds > 0) {
            this.verif_seconds--
          } else {
            clearInterval(this.timer)
            this.timer = null
            this.verif_seconds = 60
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    // 关于我们
    go_about() {
      let routeData = this.$router.resolve({ name: 'aboutus' })
      // window.open(routeData.href, 'newWindow', 'top=0'); //打开新窗口
      window.open(routeData.href, '_blank')
    },
    // 加入我们
    join_about() {
      this.$router.push({
        name: 'join_about',
      })
    },
    help_center() {
      this.$router.push({
        name: 'help_center',
      })
    },
    //验证码登录
    async verifLogin() {
      if (!this.validatePhone(this.phone_number_mode2)) return
      if (this.verif_code == '') {
        return this.$message({
          type: 'error',
          message: '请获取验证码!',
        })
      }
      if (!this.verif) {
        return this.$message({
          type: 'error',
          message: '请输入验证码!',
        })
      }
      const data = {
        mobile: this.phone_number_mode2,
        code: this.verif,
        id: this.verif_code,
      }

      try {
        let res = await codeLogin(data)
        if (res) {
          let data = res
          localStorage.setItem('auth_token', JSON.stringify(data.access_token))
          console.log(data)
          data = JSON.stringify(data)
          //保存token
          Cookies.set('auth_token', data, {
            expires: 7,
            path: '/',
            domain: '.writemall.com',
          })
          this.$message({
            type: 'success',
            message: '登录成功!',
          })
          this.getUserinfo()
          this.login_flag = false

          if (this.routeName == 'TraceTheSource') {
            return
          } else if (this.routeName == 'StoreRightBox') {
            return
          }
          // window.location.reload();
          // window.location.reload(true);
        }
      } catch (error) {
        console.log(error)
        this.$message({
          type: 'error',
          message: '验证码错误!',
        })
      }
    },
    getFreeVip() {
      this.$bus.$emit('showGrit')
    },
    goPhoneLogin() {
      this.loginType = 2
      const mode1 = document.querySelector('#mode1')
      mode1.checked = true
    },
    handleEnter(e) {
      if (e.keyCode === 13) {
        if (this.loginType == 1) {
          this.pwdLogin()
        }
        if (this.loginType == 2) {
          this.verifLogin()
        }
      }
    },
    //【注册】点击取消注册
    registerCancel() {
      this.register_flag = false
    },
    async register() {
      let reg = /^1[3|4|5|7|8]\d{9}$/
      //  手机号验证
      if (!reg.test(this.phone_number)) {
        this.$message({
          type: 'error',
          message: '请正确输入手机号！',
        })
        return
      }
      // 密码验证
      if (this.R_password == '' || this.password_again == '') {
        this.$message({
          type: 'error',
          message: '请输入密码！',
        })
        return
      } else if (this.R_password != this.password_again) {
        this.$message({
          type: 'error',
          message: '两次输入的密码不同！',
        })
        return
      } else if (this.R_password.length < 6) {
        this.$message({
          type: 'error',
          message: '密码不能少于6位',
        })
        return
      }
      // 验证码验证
      if (this.R_verif_code == '') {
        this.$message({
          type: 'error',
          message: '请获取验证码',
        })
        return
      }
      // 验证码验证
      if (this.R_verif == '') {
        this.$message({
          type: 'error',
          message: '验证码不能为空！',
        })
        return
      }
      //参数
      const data = {
        mobile: this.phone_number,
        password: this.R_password,
        codeId: this.R_verif_code,
        code: this.R_verif,
        nickName: this.nickName,
        inviteCode: this.recommendation_code,
      }
      //发送请求
      const res = await register(data)
      //是否注册成功
      if (res.code === 200) {
        //参数
        const content = {
          mobile: this.phone_number,
          password: this.R_password,
        }
        //密码登录
        passwordLogin(content).then((respon) => {
          let date = respon
          date = JSON.stringify(date)
          //保存token
          Cookies.set('auth_token', date, {
            expires: 7,
            path: '/',
            domain: '.writemall.com',
          })
          this.$message({
            type: 'success',
            message: '注册成功，已登录!',
          })
          this.register_flag = false
          this.getUserinfo()

          if (this.routeName == 'TraceTheSource') {
            return
          } else if (this.routeName == 'StoreRightBox') {
            return
          }

          window.location.reload(true)
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message,
        })
      }
    },
    //发送验证码
    R_sendVerif() {
      if (this.timer) {
        return
      }
      let reg = /^1[3|4|5|7|8]\d{9}$/
      if (!reg.test(this.phone_number)) {
        this.$message({
          type: 'error',
          message: '请输入正确的手机号!',
        })
        return false
      }
      if (this.R_password == '' || this.password_again == '') {
        this.$message({
          type: 'error',
          message: '请输入密码!',
        })
        return
      }

      if (this.R_password < 6 || this.password_again < 6) {
        this.$message({
          type: 'error',
          message: '密码不能小于6位!',
        })
        return
      }
      let data = {
        type: 3,
        mobile: this.phone_number,
      }
      this.R_setVerifTime()
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.R_verif_code = res.data.codeId
        } else {
          this.$message({
            type: 'error',
            message: res.message,
          })
        }
      })
    },
    //发送验证码计时
    R_setVerifTime() {
      this.timer = setInterval(() => {
        if (this.R_verif_seconds > 0) {
          this.R_verif_seconds--
        } else {
          clearInterval(this.timer)
          this.R_verif_seconds = 60
          this.timer = null
        }
      }, 1000)
    },

    //立即登录
    toLogino() {
      let infoobj
      try {
        infoobj = JSON.parse(window.localStorage.getItem('infoobj'))
        console.log(infoobj)
        if (infoobj) {
          this.phone_number_mode1 = infoobj.phone
          this.password = infoobj.password
          this.remenber_password = true
        }
      } catch (error) {
        this.phone_number_mode1 = ''
        this.password = ''
        this.remenber_password = false
      }
      this.loginType = 2
      this.phone_number_mode2 = ''
      this.verif = ''
      this.register_flag = false
      this.login_flag = true
      this.forgot_flag = false

      clearInterval(this.timer)
      this.timer = null
      this.x_verif_seconds = 60
      this.f_verif_seconds = 60
      this.verif_seconds = 60
      this.R_verif_seconds = 60
    },
    handleUserInfo() {
      this.userInfo = true
      this.getEquityNum()
      this.getUserinfo()
    },
    // puls卡券
    coupon() {
      this.userInfo = false
      this.$bus.$emit('isShowVipCode')
    },
    //退出登录
    loginOut() {
      this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.isLogin = false
          this.userInfo = false
          //Cookies.remove("auth_token");
          //清除token
          Cookies.set('auth_token', '', {
            expires: -1,
            path: '/',
            domain: '.writemall.com',
          })

          localStorage.removeItem('auth_token')
          //清除用户信息
          // localStorage.clear();
          this.$store.commit('SET_USER_INFO', {
            userInfo: null,
          })
          this.$store.commit('CLEAR_REAL_NAME')

          // this.$router.push("/infoCompileIndex");
          // this.$bus.$emit("changeNavIndex", "文库");
          window.location.reload(true)
          this.isUser_info = JSON.parse(window.localStorage.getItem('vuex'))
            ?.user_info
            ? JSON.parse(window.localStorage.getItem('vuex'))?.user_info
            : ''
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出',
          })
        })
    },
    // 计算百分比Authorization
    calculation() {
      // let num = this.userInfoList.usedSpace/this.userInfoList.totalSpace * 100
      let num = this.diskRario
      return num
    },

    //获取用户信息
    async getUserinfo() {
      try {
        const res = await getuserInfo()
        if (res.code === 200) {
          let userData = res.data
          this.info = res.data
          // localStorage.setItem("user_info", JSON.stringify(res.data));
          this.$store.commit({
            type: 'SET_USER_INFO',
            userInfo: userData,
          })
          this.$store.commit('CHECK_REAL_NAME')
          this.isLogin = true
          if (userData.memberlevel == 0 || userData.memberlevel == 1) {
            this.userInfoList.isvip = 1
          } else if (userData.memberlevel == 2) {
            this.userInfoList.isvip = 2
          } else if (userData.memberlevel == 3) {
            this.userInfoList.isvip = 3
          } else if (userData.memberlevel == 4) {
            this.userInfoList.isvip = 4
          }
          this.userInfoList.id = userData.number
          this.userInfoList.time = userData.expirationDate
          this.userInfoList.name = userData.username
          this.userInfoList.character = userData.character
          this.userInfoList.usedSpace = userData.usedSpace
          this.userInfoList.totalSpace = userData.totalSpace
          this.diskRario = userData.diskRario
          this.userInfoList.imgSrc = userData.headImg
          this.userInfoList.nickName = userData.nickName
          this.userInfoList.mobile = userData.mobile
          this.phone = userData.mobile
          this.userInfoList.sex = userData.sex
          this.userInfoList.introduction = userData.introduction
          this.userInfoList.signature = userData.signature
          this.userInfoList.birthday = userData.birthday

          this.isUser_info = JSON.parse(window.localStorage.getItem('vuex'))
            ?.user_info
            ? JSON.parse(window.localStorage.getItem('vuex'))?.user_info
            : ''
        }
      } catch (e) {
        this.$store.commit('SET_USER_INFO', {
          userInfo: null,
        })
        this.isUser_info = JSON.parse(window.localStorage.getItem('vuex'))
          ?.user_info
          ? JSON.parse(window.localStorage.getItem('vuex'))?.user_info
          : ''

        this.$store.commit('CLEAR_REAL_NAME')
      }
    },
    //打开账户记录
    handleAccount() {
      this.$router.push({
        name: 'AgencyEntrustment',
      })
      this.userInfo = false
      //  this.$router.push({
      //   path: "/accountRecords",
      // });
      // this.userInfo = false;
    },
    // 打开体验卡
    handleCard() {
      this.$router.push({
        name: 'OrderCenter',
      })
      this.userInfo = false
      // this.$refs.infoDialog.dialogCard = true;
    },
    // 打开vip弹窗
    handleVip() {
      this.$router.push('/VIPcenter')
      this.$bus.$emit('changeNavIndex', '会员服务')
      // this.$refs.infoDialog.dialogVisible = true;
    },
    // 打开设置中心
    handeleSetup() {
      this.$refs.infoDialog.dialogsetup = true
    },
  },
  watch: {
    $route(to, from) {
      this.routeName = to.name
    },
  },

  created() {
    this.iflogin()

    this.$bus.$on('unAuthorized', () => {
      this.toLogino()
    })
    this.getUserinfo()
  },
  destroyed() {
    this.$bus.$off('unAuthorized')
    window.removeEventListener('scroll', this.scrollHandle, true)
  },
}
</script>

<style lang="scss" scoped>
.header {
  min-width: 1400px;
  position: relative;
  z-index: 10;
}
.ml {
  width: 60px !important;
}
.red {
  color: #ff0000 !important;
}
.bg {
  background: #eff3f7;
}
.mt {
  margin-top: -25px;
}
.wm-antiplagiarism-header-left-logo {
  img {
    width: 105px;
    image-rendering: -webkit-optimize-contrast;
  }
}
.means {
  width: 833px;
  height: 616px;
  background: #fff;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  .upitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 45px;
    .item-j {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      .introduce {
        margin-top: 5px !important;
      }
      textarea {
        // width: 640px !important;
        flex: 1;
        height: 60px !important;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        box-sizing: border-box;
        padding: 13px 15px;
        resize: none;
      }
      span {
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .item-y {
      display: flex;
      align-items: center;
      align-items: center;
      .span {
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
      }
      .send {
        display: flex;
        align-items: center;
        width: 240px;
        height: 40px;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        .sendcode {
          color: #ff6900;
        }
      }
    }
    .item-g {
      span {
        width: 60px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      label {
        margin-left: 8px !important;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .nan {
        margin-left: 20px;
      }
      .nv {
        margin-left: 60px;
      }
    }
    .item {
      .tip {
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: 80px;
        margin-top: 10px;
      }
      span {
        width: 60px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      input {
        width: 240px;
        height: 40px;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
        box-sizing: border-box;
        padding: 13px 15px;
      }
    }
  }
  .uphead {
    @include flex-center;
    margin: 30px 0 13px 0;
    .wrap {
      position: relative;
      width: 100px;
      height: 100px;
      .upfile {
        display: none;
      }
      .head-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5)
          url('~assets/images/personal-avatar-maskicon.png') 50% 50% no-repeat;
        cursor: pointer;
      }
    }
  }
  .choose-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 44px;
    margin-left: 65px;
    opacity: 0.5;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .nav {
    width: 833px;
    height: 34px;
    background: #f4f6f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 12px;
    .title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .close {
      width: 10px;
      height: 10px;
      color: #999;
      cursor: pointer;
      margin-top: -10px;
    }
  }
}
.down {
  z-index: 10;
  position: absolute;
  top: 64px;
  right: 170px;
  width: 160px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 13px 0px rgba(155, 157, 170, 0.13);
  border-radius: 0px 0px 8px 8px;

  .span {
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
  }
  .send {
    display: flex;
    align-items: center;
    width: 240px;
    height: 40px;
    background: #f5f6f9;
    border-radius: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    .sendcode {
      color: #ff6900;
    }
  }
}
.item-g {
  span {
    width: 60px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  label {
    margin-left: 8px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .nan {
    margin-left: 20px;
  }
  .nv {
    margin-left: 60px;
  }
}
.item {
  .tip {
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 80px;
    margin-top: 10px;
  }
  span {
    width: 60px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  input {
    width: 240px;
    height: 40px;
    background: #f5f6f9;
    border-radius: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    box-sizing: border-box;
    padding: 13px 15px;
  }
}

.uphead {
  @include flex-center;
  margin: 30px 0 13px 0;
  .wrap {
    position: relative;
    width: 100px;
    height: 100px;
    .upfile {
      display: none;
    }
    .head-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5)
        url('~assets/images/personal-avatar-maskicon.png') 50% 50% no-repeat;
      cursor: pointer;
    }
  }
}
.choose-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 44px;
  margin-left: 65px;
  opacity: 0.5;
  cursor: pointer;
  margin-bottom: 30px;
}
.nav {
  width: 833px;
  height: 34px;
  background: #f4f6f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 12px;
  .title {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .close {
    width: 10px;
    height: 10px;
    color: #999;
    cursor: pointer;
    margin-top: -10px;
  }
}

.down {
  z-index: 10;
  position: absolute;
  top: 64px;
  right: 170px;
  width: 160px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 13px 0px rgba(155, 157, 170, 0.13);
  border-radius: 0px 0px 8px 8px;

  .down-item {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      margin-left: 17px;
      width: 58px;
      height: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: left;
    }
  }
}

.wxPhone {
  width: 168px;
  height: 13px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.register-main {
  position: fixed;
  z-index: 2100;
  top: 50%;
  left: 50%;
  width: 418px;
  height: 500px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  transform: translate(-50%, -50%);
  border: 0px solid #818790;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;

  .login-cancel {
    margin-top: 14px;
    margin-left: 394px;
    width: 10px;
    height: 10px;
    color: #c7cbd3;
    cursor: pointer;
  }

  .register-wrap {
    width: 100%;

    .register-input {
      margin-top: 30px;

      .register-input-wrap {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 298px;
        height: 43px;
        background: #f3f5f9;
        border-radius: 22px;

        .register-input-icon {
          margin: 0 18px;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: inherit;
          }
        }

        .register-input-content {
          flex: 1;
          height: 100%;
          padding-right: 18px;

          input {
            border: none;
            width: 100%;
            height: 100%;
            color: #999999;
            background: #f3f5f9;

            &::placeholder {
              font-size: 14px;
              color: #999;
            }
          }
        }

        .register-input-type {
          margin-right: 18px;
          width: 20px;
          height: 20px;
          cursor: pointer;

          img {
            width: 100%;
            height: inherit;
          }
        }

        .register-input-sendcode {
          position: relative;
          margin-right: 15px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #ff6900;
          line-height: 28px;
          cursor: pointer;

          &::after {
            display: block;
            position: absolute;
            content: ' ';
            left: -10px;
            top: 6px;
            width: 1px;
            height: 20px;
            background: #cccccc;
          }
        }
      }

      .register-input-wrap + .register-input-wrap {
        margin-top: 20px;
      }
    }

    .register-footer {
      padding-top: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 298px;
      margin: 0 auto;

      &-tips {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #999999;

        a {
          margin-left: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 500;
          text-decoration: underline;
          color: #ff6900;
          cursor: pointer;
        }
      }

      &-btn {
        width: 99px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        background: #ff6900;
        border-radius: 20px;
        cursor: pointer;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 5px 10px #e6e4e4;
        }

        &:active {
          box-shadow: 0 0 10px #e6e4e4;
        }
      }
    }
  }
}

//登录弹窗
.login-cancel {
  margin-top: 14px;
  // margin-left: 394px;
  // width: 10px;
  // height: 10px;
  color: #c7cbd3;
  cursor: pointer;
  text-align: right;
  padding-right: 20px;
}

.login-main {
  z-index: 1000000;
  position: fixed;
  top: 200px;
  left: 50%;
  // padding: 39px 0 57px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  transform: translatex(-50%);
  border: 0px solid #818790;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;

  .close {
    position: absolute;
    padding: 10px;
    cursor: pointer;
    right: 10px;
    top: 10px;
    z-index: 9;
  }

  .logo_box {
    margin-bottom: 40px;

    .login-logo {
      img {
        image-rendering: -webkit-optimize-contrast;
        width: 170px;
        height: 60px;
      }
    }
  }

  .login_box {
    display: flex;
    height: 470px;
    box-shadow: 0px 0px 13px 5px rgba(155, 157, 170, 0.6);
    .weixin {
      width: 232px;
      height: 100%;
      background-image: url('~@/assets/images/loginbg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      .logo {
        margin: 30px 0 0 30px;
        img {
          width: 77px;
          height: 33px;
        }
      }
      .info {
        margin: 30px 0 0 30px;
        .remark {
          display: flex;
          align-items: center;
          margin: 20px 0;
          img {
            margin-right: 15px;
            width: 14px;
            height: 14px;
          }
          span {
            color: #eaeffd;
          }
        }
      }
    }

    .log_box {
      height: 100%;
      padding: 30px 50px;
      box-sizing: border-box;
      // flex: 1;
      position: relative;
    }
  }
}

.login-wrap {
  width: 100%;
  /* height: 100%;
	  box-sizing: border-box; */
  /* height: inherit; */
}

.login-logo {
  text-align: center;
  img {
    image-rendering: -webkit-optimize-contrast;
    width: 170px;
    height: 60px;
  }
}

.login-mode {
  width: 100%;
  .scanContent {
    .title {
      margin-bottom: 15px;
    }
    .againGet {
      font-size: 13px;
      color: #4587dd;
      cursor: pointer;
      margin: 15px;
    }
    .tips {
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.login-mode .login-nav {
  display: none;
}

.login-mode-nav {
  @include noselect;
  display: flex;

  width: 100%;

  box-sizing: border-box;
}

.login-mode-nav li label {
  margin-right: 30px;
  width: 65px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.login-mode-nav .login-nav-active label {
  position: relative;
  font-weight: bold;
  color: #333333;
}

.login-mode-nav .login-nav-active label::after {
  display: block;
  content: ' ';
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 11px;
  height: 3px;
  background-color: #4588ff;
  transform: translateX(-50%);
}

.login-mode-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 110px;
  margin-top: 38px;
}

.login-mode-content .login-mode-content-mode {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: display 1s ease;
  /* background-color: #f14141; */
}

.login-mode-content-mode .login-content-inpwrap {
  display: flex;
  align-items: center;
  width: 298px;
  height: 43px;
  background: #f3f5f9;
  border-radius: 22px;
  overflow: hidden;
}

.login-content-inpwrap-icon {
  margin: 0 18px;
  width: 20px;
  height: 20px;
}

.login-content-inpwrap-icon img {
  width: 100%;
  height: inherit;
}

.login-content-inpwrap-input {
  flex: 1;
  height: 100%;
  padding-right: 18px;
}

.login-content-inpwrap-input input {
  border: none;
  width: 100%;
  height: 100%;
  color: #999999;
  background: #f3f5f9;
}

.login-content-inpwrap-input input::placeholder {
  color: #999999;
}

.login-content-inpwrap-type {
  margin-right: 18px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.login-content-inpwrap-type img {
  width: 100%;
  height: inherit;
}

/* 获取短信验证码 */
.login-content-inpwrap-sendcode {
  @include noselect;
  position: relative;
  margin-right: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #4587dd;
  line-height: 28px;
  cursor: pointer;
}

.login-content-inpwrap-sendcode::after {
  display: block;
  position: absolute;
  content: ' ';
  left: -10px;
  top: 6px;
  width: 1px;
  height: 20px;
  background: #cccccc;
}

/* 切换登录方式 */
/* 兄弟原生样式改变 变灰*/
.login-nav1:checked ~ .login-mode-nav li label,
.login-nav2:checked ~ .login-mode-nav li label,
.login-nav3:checked ~ .login-mode-nav li label,
.login-nav4:checked ~ .login-mode-nav li label {
  position: relative;
  font-weight: 500;
  color: #999999;
}

/*切换登录方式底部文本高亮*/
.login-nav1:checked ~ .login-mode-nav li:first-child label,
.login-nav2:checked ~ .login-mode-nav li:nth-child(2) label,
.login-nav3:checked ~ .login-mode-nav li:nth-child(3) label,
.login-nav4:checked ~ .login-mode-nav li:last-child label {
  font-weight: bold;
  color: #333333;
}

/* 切换登录方式底部伪类样式*/
.login-nav1:checked ~ .login-mode-nav li:first-child label::after,
.login-nav2:checked ~ .login-mode-nav li:nth-child(2) label::after,
.login-nav3:checked ~ .login-mode-nav li:nth-child(3) label::after,
.login-nav4:checked ~ .login-mode-nav li:last-child label::after {
  display: block;
  content: ' ';
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 11px;
  height: 3px;
  background-color: #4588ff;
  transform: translateX(-50%);
}

// /* 登录模板切换 */

// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav3:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav4:checked ~ .login-mode-content .login-mode-content-mode {
//   display: none;
// }
// /*手机登录*/
// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode:first-child ,
// /*验证码登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(2),
// /*微信登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(3),
// /*扫码登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:last-child {
//   display: flex;
// }

#content-mode3,
#content-mode4 {
  display: none;
}

.login-tool.content1,
.login-footer.content1 {
  display: none;
}

/*操作栏*/
.login-tool {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 298px;
  height: 40px;
  margin: 0 auto;
  /* background-color: #44f1dd; */
}

.login-auto {
  display: flex;
  align-items: center;
  height: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
}

.login-auto input {
  margin-right: 11px;
}

.login-aspwd {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
  cursor: pointer;
  display: inline-block;
  text-align: right;

  .login-forgetpwd {
    color: #4588ff;

    // &:hover {
    //   color: #777777;
    // }
  }
}

.login-scancode {
  color: #ff6900;
  margin-right: 11px;
}

.wx-login {
  width: 100%;
  text-align: center;
  color: #999999;

  img {
    width: 46px;
    height: 46px;
    margin: 10px 0 10px 0;
    cursor: pointer;
  }
  .wxcode {
    div {
      // margin-top: 10px;
      cursor: pointer;
    }
  }
}
.footer {
  height: 30px;
  background: #f8fafb;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  .left {
    color: #999999;
    font-size: 13px;
    span {
      font-size: 15px;
      color: #333;
    }
  }
  .right {
    cursor: pointer;
    color: #4587dd;
    font-size: 15px;
  }
}
/* 底部登录按钮行 */

.login-footer {
  // padding-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 298px;
  margin: 0 auto;
}

.login-footer-tips {
  /* margin-top: 21px; */
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
}

.login-footer-tips a {
  margin-left: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  text-decoration: underline;
  color: #ff6900;
  cursor: pointer;
}

.login-footer-btn {
  @include noselect;
  width: 100%;
  margin: 15px 0;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  background: #4587dd;
  border-radius: 20px;
  cursor: pointer;
}

.login-footer-btn:hover {
  // transform: translateY(-1px);
  box-shadow: 0 5px 10px #e6e4e4;
}

.login-footer-btn:active {
  box-shadow: 0 0 10px #e6e4e4;
}

.wm-antiplagiarism-header {
  position: relative;
  @include flex-between;
  // height: 64px;
  padding: 0 100px;
  background: #ffffff;
  z-index: 1;
  box-shadow: 0px 0px 13px 0px rgba(155, 157, 170, 0.13);
  .column {
    min-width: 150px;
    white-space: nowrap;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    height: 100%;
    align-items: center;
    img {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
  .navlist {
    // position: absolute;
    left: 50%;
    // transform: translateX(-50%);
    // width: 1338px;
    height: 100%;
    display: flex;
    justify-content: space-around;

    .navitem {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .itemActive {
      position: relative;
      color: #ff6900;
      &::before {
        content: '';
        position: absolute;
        top: 3.875rem;
        left: calc(50% - 1.25rem);
        width: 40px;
        height: 2px;
        background: #ff6900;
      }
    }
  }

  // .newHeader {
  //   width: 1200px;
  // }

  &-left {
    @include flex-start;
    height: 100%;

    &-logo {
      cursor: pointer;
      margin-left: 25px;
      img {
        image-rendering: -webkit-optimize-contrast;
        // width: 153px;
        // height: 43px;
      }
    }

    &-nav {
      margin-left: 55px;
      // width: 500px;
      height: 100%;
    }
  }

  &-right {
    @include flex-start;
    // margin-right: 30px;

    .netw {
      .blue {
        color: #ff6900;
      }
    }

    .vipServer {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-right: 27px;

      img {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }

    .color {
      color: #fff;
    }

    .netw,
    .download {
      @include flex-start;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .about {
      margin-left: 30px;
      font-size: 16px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
    }
    .download {
      margin-left: 40px;
    }

    .user {
      @include flex-start;
      // margin-left: 40px;
      cursor: pointer;

      img {
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }

      .login-link {
        font-size: 14px;
        font-weight: 400;
        color: #4587dd;
        white-space: nowrap;
      }
    }

    .color {
      .login-link {
        color: #fff !important;
      }
    }
  }

  .userInfoPopup {
    ::v-deep .el-dialog {
      margin: 0px;
      position: absolute;
      right: 160px;
      top: 64px;
      /* prettier-ignore */
      // height: 300Px;
      background: #ffffff;
      box-shadow: 0px 2px 5px 0px rgba(148, 145, 145, 0.49);
      border-radius: 4px;
      width: 380px;
      height: 610px;
      .el-dialog__header {
        padding: 0;
      }

      .el-dialog__body {
        /* prettier-ignore */
        padding: 0px 10Px;
        position: relative;

        .user_info_box {
          margin-top: 15px;
          .user {
            height: 75.7px;
            background: url('~assets/images/bgc.png');
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 20px 30px;
            position: relative;

            .exitLogin {
              cursor: pointer;
              position: absolute;
              padding: 10px 20px;
              border-radius: 40px 0px 0 40px;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              font-weight: bold;
              color: #fff;
              background: rgba(0, 0, 0, 0.3);
            }
            .headImg {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }

            .userName {
              margin-left: 20px;
              font-size: 16px;
              font-weight: bold;
              color: #fff;

              .img_box {
                display: flex;
                align-items: center;
                span {
                  margin-left: 10px;
                }
              }

              .name {
                margin-top: 0px;
              }
            }

            .login {
              margin-left: 20px;
              font-size: 16px;
              font-weight: bold;
              .text {
                color: #fff;
              }
              .btn {
                margin-top: 11px;
                width: 82px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
                background: #ffffff;
                border-radius: 15px;
                font-size: 14px;
                font-weight: bold;
                color: #ff6500;
              }
            }
          }
        }
        .tab_box {
          margin-top: 20px;
          display: flex;
          align-items: center;
          // box-shadow: 0px 0px 13px 0px rgba(184, 184, 184, 0.31);
          border-radius: 3px;
          height: 60px;
          .tab_item {
            display: flex;
            align-items: center;
            cursor: pointer;
            flex-direction: column;
            width: 33%;
            font-size: 14px;
            img {
              width: 30px;
              margin-bottom: 10px;
            }

            // &:nth-child(2) {
            //   border-left: 1px solid #efefef;
            //   border-right: 1px solid #efefef;
            // }
            // &:nth-child(3) {
            //   border-right: 1px solid #efefef;
            // }
          }
        }
        .equity_text {
          margin: 20px 0;
          font-size: 12px;
          color: #cccccc;
        }
        .equity_box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .equity_item {
            width: 169px;

            height: 110px;
            background: #ffffff;
            box-shadow: 0px 0px 13px 0px rgba(184, 184, 184, 0.31);
            border-radius: 5px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 18px 14px 15px;
            position: relative;
            .dialog1 {
              position: absolute;
              width: 300px;
              // height: 500px;
              overflow: auto;
              background: #fff;
              z-index: 999;
              right: -310px;
              top: 50%;
              transform: translateY(-50%);
              padding: 10px;
              border-radius: 5px;
              .title {
                font-size: 15px;
                font-weight: bold;
                text-align: center;
              }
              li {
                margin: 5px 0;
                font-size: 12px;
              }
            }
            .dialog2 {
              position: absolute;
              width: 400px;
              // height: 00px;
              overflow: auto;
              background: #fff;
              z-index: 999;
              left: -200px;
              // right: -180px;
              bottom: 0px;
              padding: 10px;
              border-radius: 5px;
              .h {
                font-size: 15px;
                font-weight: bold;
                text-align: center;
              }
              .title {
                font-size: 15px;
                font-weight: bold;
                // text-align: center;
                margin: 10px 0;
              }
              .content {
                display: inline-block;
                margin: 5px 0;
                font-size: 12px;
                padding-left: 10px;
              }
            }
            .icon {
              width: 40px;
              height: 40px;
              position: absolute;
              top: 4px;
              right: 4px;
            }

            .top {
              font-size: 14px;
              color: #666666;
            }
            .centre {
              color: #ff6500;
              font-weight: bold;
              font-size: 16px;

              span {
                font-size: 14px;
              }
              .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 82px;
                height: 26px;
                background: linear-gradient(90deg, #ff6500, #ff9600);
                border-radius: 13px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                line-height: 26px;
                cursor: pointer;
              }
            }
            .bottom {
              font-size: 12px;
              color: #999999;
            }
          }
        }
        .exit {
          margin-bottom: 10px;
          font-size: 14px;
          color: #808080;
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }
}

.bgcC {
  background: none;
  .login-link {
    color: #fff;
  }
}

// 消息中心弹窗
.message_dialog {
  ::v-deep .el-dialog {
    position: absolute;
    top: 65px;
    width: 47%;
    right: 80px;

    .el-dialog__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #f4f6f7;

      .el-dialog__title {
        font-size: 14px;
      }

      .el-dialog__headerbtn {
        position: static;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  ::v-deep .el-tabs__content {
    height: 100%;
    overflow: auto;
  }
  ::v-deep .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0;
  }

  ::v-deep .el-tabs__item {
    height: 60px;
    display: flex !important;
    align-items: center;
  }

  ::v-deep .is-active {
    background-color: #ededed;
  }

  ::v-deep .el-tab-pane {
    position: relative;
    // height: 100%;

    .imgclass {
      margin: 80px auto;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      .img {
        display: block;
        width: 116px;
        height: 122px;
        margin-bottom: 20px;
      }
    }
  }

  .comments_bottom {
    z-index: 20;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    width: 71%;
    right: 1px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
    background-color: #f4f6f7;

    .delete {
      cursor: pointer;
      color: red;
    }
  }

  .message_tabs {
    .user_box {
      display: flex;
      .user_img {
        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }

      .user_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .user_name {
          display: flex;
          justify-content: space-between;
          height: 30px;
          .name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
        .user_content {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content {
            width: 150px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: #ccc;
          }
          .sum {
            text-align: center;
            line-height: 16px;
            border-radius: 50%;
            background-color: red;
            color: #fff;
            width: 16px;
            height: 16px;
            font-size: 8px;
          }
        }
      }
    }

    .user_message {
      .user {
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;
      }

      .message {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        height: 450px;
        overflow: auto;
        background-color: #ededed;
        text-align: center;
        .date {
          padding: 5px;
          display: inline-block;
          background-color: #dbdbdb;
          color: #fff;
          margin: 20px auto;
        }
        .left_mesage {
          align-items: center;
          margin: 10px 0;
          display: flex;
          width: 50%;

          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            margin-right: 20px;
          }

          .text {
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            line-height: 1.5;
          }
        }

        .rigth_mesage {
          position: absolute;
          right: 20px;
          display: flex;
          width: 50%;
          margin: 10px 0;
          justify-content: flex-end;
          text-align: right;
          align-items: center;

          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            margin-left: 20px;
          }

          .text {
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            line-height: 1.5;
          }
        }
      }

      .reply {
        position: relative;
        ::v-deep .el-textarea__inner {
          border: none;
          height: 165px;
          resize: none;
          font-size: 16px;
        }

        ::v-deep .el-input__count {
          right: 100px;
        }

        .el-button {
          position: relative;
          left: 87%;
          bottom: 30px;
        }
      }
    }
    .message_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 220px;
      text-align: left;

      .img_box {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }

      .sum {
        text-align: center;
        line-height: 16px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        width: 16px;
        height: 16px;
        font-size: 8px;
      }
    }

    .comments {
      box-sizing: border-box;
      position: relative;
      height: 100%;
      overflow: auto;
      padding-bottom: 34px;

      .message_item_box {
        padding: 10px 20px;

        .message_title {
          font-weight: 800;
          font-size: 16px;
          padding: 0 0 20px 0;
        }

        .message_content {
          font-size: 14px;
        }
      }

      .img {
        margin: 80px auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          display: block;
          width: 116px;
          height: 122px;
          margin-bottom: 20px;
        }
      }

      .comments_top {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #cccc;

        .text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #000;
        }

        .operation {
          color: #999999;
          cursor: pointer;
        }

        .red {
          color: red;
          cursor: pointer;
        }
      }

      .comments_center {
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #ccc;

        .center_box_top {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          padding: 10px;

          .leftTemp {
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC;
            color: #000;

            .choose {
              align-items: center;
              position: relative;

              .el-checkbox {
                position: absolute;
                left: -20px;
                top: 20px;

                ::v-deep .el-checkbox__label {
                  display: none;
                }
              }

              .choose_box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                  line-height: 1.5;
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  color: #000;
                  font-weight: 800;
                }

                .rigth {
                  color: #999999;
                }
              }
            }
          }
        }

        .center_box_bottom {
          margin-top: 10px;
          line-height: 1.5;

          .color_red {
            color: red;
            cursor: pointer;
          }
        }

        .praise_top {
          display: flex;
          align-items: center;
          font-weight: 800;

          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
          }
        }

        .praise_center {
          margin: 10px 0;
          display: flex;
          align-items: center;

          img {
            margin-left: 5px;
            width: 16px;
            height: 16px;
          }
        }

        .praise_bottom {
          padding: 20px;
          background-color: #fbfbfb;

          .title {
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-weight: 800;
            font-size: 16px;
          }

          .p {
            line-height: 1.5;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
</style>
