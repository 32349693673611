<template>
  <div class="FreeGetVip">
    <!-- <div class="cy_logo">
      <img :src="require('assets/images/logowit.png')" alt="" />
      <span>省时省心给力</span>
    </div> -->

    <div class="info">
      <div class="item" v-for="(item, index) in list" :key="index">
        <img :src="require('assets/images/big_bgVip_dui.png')" alt="" />
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="btns">
      <div class="immediatelyGet" @click="immediatelyGet">
        <span>领取</span>
      </div>
      <!-- <div class="lookVipPower" @click="openVipPage">
        <img :src="require('assets/images/diamond_yellow.png')" alt="" />
        <span>查看权益列表</span>
      </div> -->
    </div>
    <!-- <div class="footer">
      <img :src="require('assets/images/code4.png')" alt="" />
      <div class="label">深圳写手智能科技公司</div>
    </div> -->
  </div>
</template>

<script>
import { check, bind } from 'api/common'
export default {
  data() {
    return {
      list: [
        'AI成文  随意创作',
        '专业文库  每日更新',
        '优质模板  正版授权',
        '闪亮金句  灵感源泉',
      ],
    }
  },
  methods: {
    async immediatelyGet() {
      const res = await check()
      if (res.code == 200) {
        if (res.data) {
          this.$message('您已经领取过体验卡')
        } else {
          const res = await bind()
          if (res.code == 200) {
            this.$alert('成功领取1天VIP会员体验', '领取成功', {
              confirmButtonText: '确定',
              callback: (action) => {
                window.location.reload()
              },
            })
          }
        }
      }
    },
    openVipPage() {
      this.$router.push('https://www.writemall.com/VIPcenter')
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.FreeGetVip {
  width: 367px;
  height: 600px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  background-image: url('~@/assets/images/big_bgVip.png');
  // font-family: "Source Han Sans CN";
  background-size: 100% 100%;
  z-index: 2001;
  .el-icon-close {
    cursor: pointer;
    position: absolute;
    color: #fff;
    top: 10px;
    right: 10px;
  }
  .cy_logo {
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 87%;
    padding-top: 20px;

    > img {
      width: 80px;
      height: 30px;
    }
    span {
      font-size: 14px;
      font-family: PingFang SC;
      color: #ffffff;
    }
  }
  .info {
    width: 330px;
    height: 270px;
    margin: 0 auto;
    background: url('~@/assets/images/label_bg.png');
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    padding-top: 30px;
    box-sizing: border-box;
    .item {
      display: flex;
      align-items: center;
      margin: 10px auto;
      width: 50%;

      img {
        width: 14px;
        margin-right: 10px;
        height: 14px;
      }
      span {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;

        color: #e9271d;
        // line-height: 913px;
      }
    }
  }
  .btns {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 78%;
    width: 60%;
    margin: 0 auto;
    .immediatelyGet {
      cursor: pointer;
      // display: flex;
      // align-items: center;
      line-height: 43px;
      // justify-content: space-evenly;
      text-align: center;
      height: 50px;
      margin: 10px 0;
      background-image: url('~@/assets/images/text_bg.png');
      background-size: 100% 100%;
      span {
        // width: 47px;
        // height: 22px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #730600;
        // line-height: 1100px;
      }
      img {
        height: 40px;
        width: 40px;
        margin-left: 45px;
        // margin: 0 20px;
        // margin-right: 30px;
      }
    }
    .lookVipPower {
      cursor: pointer;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      height: 50px;
      margin: 10px 0;
      background-image: url('~@/assets/images/red_bg.png');
      background-size: 100% 100%;
      span {
        font-family: Microsoft YaHei;
        color: #fff2c5;
        text-shadow: 0px 0px 8px rgba(207, 118, 2, 0.98);
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        flex: 1;
      }
      img {
        height: 40px;
        width: 40px;
        margin-left: 20px;
        // margin: 0 20px;
      }
    }
  }
  .footer {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3%;
    > img {
      width: 140px;
      height: 140px;
    }
    .label {
      font-family: PingFang SC;
      font-size: 13px;
      color: #ffffff;
    }
  }
}
</style>
