<template>
  <div id="app">
    <!-- <Suspension /> -->
    <Header v-show="$route.meta.show && headerShow" />
    <div :class="$route.meta.num == 1 ? 'container1' : 'container'">
      <router-view v-if="isRouterAlive" />
    </div>
    <!--公用实名提示弹窗-->
    <RealNamePopup />
    <!-- <shareGiftBag /> -->
    <Power />
    <!-- 底部 -->
    <!-- <Footer v-if="$route.name !== 'DownloadGuide'" /> -->

    <!-- <commonFooter /> -->
    <!-- 加载 -->
    <DiskDemo />
    <!-- <FreeGetVip /> -->
    <!-- <FreeGetBtns /> -->
    <BuyTips />
    <buyTipsLock />
    <!-- <InvitationFirend /> -->
    <VipCode />
    <welfare />
    <!-- 绑定用户 -->
    <userList />
  </div>
</template>

<script>
import Suspension from 'components/Suspension'
import Header from 'components/header/Header.vue'
import Cookies from 'js-cookie'
import commonFooter from 'components/footer/commonFooter.vue'
import Footer from 'components/footer/footer.vue'
import userList from '@/components/userList'
import RealNamePopup from 'components/RealNamePopup'
import welfare from 'components/welfare'
// import InvitationFirend from 'components/InvitationFirend'
// FreeGetBtns
import FreeGetBtns from 'components/FreeGetBtns'
import shareGiftBag from 'components/Gift_bag/shareGiftBag'
import Power from 'components/power.vue'
import buyTipsLock from './components/buyTipsLock.vue'
import BuyTips from './components/buyTips.vue'
import VipCode from './components/VipCode.vue'
import DiskDemo from 'components/loading.vue'
import { mapState } from 'vuex'
// import axios from 'axios'
import qs from 'qs'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return {
      loader: null,
      isRouterAlive: true,
      headerShow: true,
      listQuery:{
        page:2,
        limit:10,
      }
    }
  },
  computed: {
    ...mapState({
      playLifeLoading: (state) => state.loading,
    }),
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("---------------");
  //   next((vm) => {
  //     if (
  //       from.name == "libraryS" ||
  //       from.name == "templateS" ||
  //       from.name == "goldenSentenceS" ||
  //       from.name == "shoppingMallS" ||
  //       from.name == "netDiskS"
  //     ) {
  //       vm.$store.commit("createdShowNavfun");
  //       window.onheaderShow();
  //       // vm.shopNav = true;
  //     }
  //   });
  // },
  created() {
    let data = window.location.href

    let url = data.split('/').slice(3).join('/')
    let route = data.split('/').splice(3, 1)[0].split('?')[0]
    if (typeof window.orientation !== 'undefined') {
      if (route == 'login' || route == 'register') {
        window.location.href = 'https://mall.writemall.com/' + url
        return
      }
      window.location.href = 'https://mall.writemall.com/' + url
    }

    if (this.$route.path == '/AuthRedirect') {
      this.$router.push('/infoCompileIndex')
    }

    if (data.includes('isHeaderShow=')) {
      this.onheaderShow()
    }
    window.onheaderShow = this.onheaderShow
    // console.log(window)
//  axios({
//         method: 'post',
//         url: 'admin/user/pageList',
//         data: qs.stringify(this.listQuery),
//       }).then(res => {
//         console.log(res);
//       })

  },
  mounted() {
    // window.onheaderShow = this.onheaderShow;
  },

  methods: {
    onheaderShow: function () {
      this.headerShow = false
    },
    // 通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
  },
  watch: {
    playLifeLoading(status) {
      if (status === true) {
        this.loader = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)',
        })
      } else {
        this.loader && this.loader.close()
      }
    },
  },
  components: {
    // commonFooter,
    Header,
    RealNamePopup,
    DiskDemo,
    Power,
    // Suspension,
    BuyTips,
    buyTipsLock,
    // shareGiftBag,
    // FreeGetVip,
    // FreeGetBtns,
    // InvitationFirend,
    VipCode,
    userList,
    welfare,
  },
}
</script>

<style lang="scss" scoped>
#app {
  // background: #f9f9fa;
  // min-height: 100vh;
  .container {
    width: 1450px;
    margin: 30px auto 45px;
  }
  .container1 {
    margin: 30px auto 45px;
  }
}
</style>
<style>
.el-menu-item.is-active {
  color: #ff6900 !important;
}
.el-submenu__title:hover {
  background-color: #ffbf6914 !important;
}
.el-menu-item:hover {
  background-color: #ffbf6914 !important;
}
.el-message.el-message--success {
  width: auto !important;
  min-width: 0;
}
.el-message.el-message--error {
  width: auto !important;
  min-width: 0;
}
.el-message.el-message--info {
  width: auto !important;
  min-width: 0;
}

/*播放按钮设置成宽高一致，圆形，居中*/
.vjs-custom-skin > .video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
}
.video-player {
  position: relative !important;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
