import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from 'store/index'
import 'utils/directives'
import 'assets/scss/index.scss'
import './plugins/element'
import rem from './rem.js'
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
import uploader from 'vue-simple-uploader'
import { MessageBox } from 'element-ui'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'fGIW3SGQ90RpH7a6usaKMta7bf5DODlu',
})
Vue.use(VideoPlayer)
Vue.use(uploader)
Vue.config.productionTip = false
Vue.prototype.$confirm = MessageBox.confirm
// 全局eventbus
let EventBus = new Vue()
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus
    },
  },
})
//跳转页面到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
