<template>
  <div v-if="isDialogShow" class="registrationDialog">
    <div class="registrationDialog_box">
      <div class="title_img"></div>

      <div class="close_box" @click="closeClick"></div>

      <div class="text_img"></div>

      <div class="text_box">
        <div class="text_1">
          1.点击专属链接注册的账号，可获赠5000字符；并绑定为粉丝账号，为期一年。
        </div>
        <div class="text_1">
          2.使用优惠码在购买VIP会员、语境库、金句库、模板库等，可以获得专属折扣，未被绑定的用户账号使用优惠码则被绑定为粉丝账号，为期一年。
        </div>
        <div class="text_1">
          3.注册链接、优惠码，均可以单独嵌入软文，或者放在合适的展示平台。
        </div>
      </div>

      <div class="input_box">
        <div class="registered">
          <div class="text">注册链接</div>
          <div class="content">
            <div
              class="link_test"
              :title="'http://passport.writemall.com/register?code=' + code"
            >
              http://passport.writ...
            </div>
            <div
              class="copy_text"
              :title="'http://passport.writemall.com/register?code=' + code"
              @click="copyClick(obj.shareUrl)"
            >
              复制
            </div>
          </div>
        </div>
        <div class="registered">
          <div class="text">专属优惠码</div>
          <div class="content">
            <div class="link_test">{{ code }}</div>
            <div class="copy_text" @click="copyClick(code)">复制</div>
          </div>
        </div>

        <div class="registered">
          <div class="text">营销文本</div>
          <div class="content">
            <div
              class="link_test"
              :title="
                '写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
                code +
                '  ' +
                discountMsg
              "
            >
              写手智能科技公司专...
            </div>
            <div
              class="copy_text"
              :title="
                '写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
                code +
                '  ' +
                discountMsg
              "
              @click="copyClick(1)"
            >
              复制
            </div>
          </div>
        </div>
        <div class="registered">
          <div class="text">软文链接</div>
          <div class="content">
            <div
              class="link_test"
              :title="'http://passport.writemall.com/?code=' + code"
            >
              http://passport.wri..
            </div>
            <div
              class="copy_text"
              :title="
                '写手智能科技公司专属注册链接，点击  http://passport.writemall.com/?code=' +
                code
              "
              @click="copyClick('http://passport.writemall.com/?code=' + code)"
            >
              复制
            </div>
          </div>
        </div>
      </div>

      <div class="log_box"></div>
    </div>
  </div>
</template>
<script>
import { findUserRecommendCode } from "api/header";
export default {
  name: "registrationDialog",
  data() {
    return {
      isDialogShow: false,
      code: "",
      characterNum: "",
      discountMsg: "",
      isShowBtn: false,
      ishaveid: "",
      obj: "",
    };
  },
  watch: {
    isDialogShow: {
      handler: function (val) {
        if (val) {
          this.getFindUserRecommendCode();
        }
      },
    },
  },
  methods: {
    handelClick() {
      this.$emit("registerClick", true);
    },

    closeClick() {
      this.isDialogShow = false;
    },

    async getFindUserRecommendCode() {
      let user = JSON.parse(window.localStorage.getItem("vuex"));
      this.haveid = user.user_info.haveid;
      if (user.user_info) {
        this.isShowBtn = true;
        this.ishaveid = user.user_info.haveid;
      } else {
        this.isShowBtn = false;
        this.ishaveid = "";
      }
      const res = await findUserRecommendCode();
      // console.log(res);
      if (res.code != 200) {
        return this.$message({
          message: res.message,
        });
      }
      this.obj = res.data;
      this.code = res.data.code;
      this.characterNum = res.data.characterNum;
      this.discountMsg = res.data.discountMsg;
    },

    copyClick(val) {
      let oInput = document.createElement("input");
      val == 1
        ? (oInput.value =
            "写手智能科技公司专属注册链接，点击 " +
            this.obj.shareUrl +
            "     " +
            this.discountMsg)
        : (oInput.value = val);
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;

      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.registrationDialog {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;

  .registrationDialog_box {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 330.1px;
    height: 615.5px;
    padding-top: 60px;
    background: url("~assets/images/exclusive-registration/bgc1.png") no-repeat;
    background-size: 100% 100%;
    .title_img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 127.1px;
      height: 46.1px;
      transform: translateX(-50%);
      background: url("~assets/images/exclusive-registration/title1.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .close_box {
      position: absolute;
      top: 35px;
      right: 15px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: url("~assets/images/exclusive-registration/close.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .text_img {
      margin: 0 auto 0;
      width: 134.1px;
      height: 36.3px;
      background: url("~assets/images/exclusive-registration/text7.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .text_box {
      padding: 10px 20px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      .text_1 {
        line-height: 21px;
      }
    }

    .input_box {
      margin: 0 auto;
      width: 310.2px;
      height: 250.4px;
      background: url("~assets/images/exclusive-registration/bgc3.png")
        no-repeat;
      background-size: 100% 100%;
      transform: translateY(-15px);
      .registered {
        transform: translateY(20px);
        color: #fff;
        display: flex;
        margin: 10px 0;
        justify-content: center;
        align-items: center;
        text-align: center;

        .text {
          text-shadow: 0px 0px 28px #1067e8;
          width: 70px;
          font-size: 13px;
          text-align: center;
        }

        .content {
          margin-left: 5px;
          padding: 0 10px;
          display: flex;
          box-sizing: border-box;
          font-size: 13px;
          background-color: #248eff;
          justify-content: space-between;
          align-items: center;
          width: 210px;
          height: 40px;
          background: #248eff;
          border: 2px solid #ffffff;
          border-radius: 19px;

          .copy_text {
            cursor: pointer;
          }
        }
      }

      .zifu_text {
        width: 172px;
        margin: 15px auto 0;
        color: #fff;
        line-height: 1.5;
        font-size: 12px;
        text-align: center;
        opacity: 0.7;
      }
    }

    .log_box {
      margin: 15px auto 10px;

      width: 192px;
      height: 54px;
      background: url("~assets/images/exclusive-registration/log.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
