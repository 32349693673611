<template>
  <div class="commonFooter">
    <div class="nav">
      <div
        class="nav_item"
        v-for="item in navlist"
        :key="item.name"
        @click="
          $router.push({
            name: item.urlName,
          })
        "
      >
        {{ item.name }}
      </div>
    </div>

    <div class="logo_box">
      <div class="leftTemp">
        <img
          class="tempImg"
          :src="require('assets/images/logowit1.png')"
          alt=""
        />

        <div class="text">省时省心给力</div>
      </div>
      <div class="right">
        <div class="title">联系电话</div>
        <div class="telephone">400-825-6828</div>
      </div>
    </div>

    <div class="Qrcode_box">
      <div class="leftTemp">
        <div class="img_box">
          <img :src="require('assets/images/IdiomCoupon/xcx.png')" alt="" />

          微信小程序
        </div>
        <div class="img_box">
          <img :src="require('assets/images/IdiomCoupon/erwm.png')" alt="" />

          微信公众号
        </div>
        <div class="img_box">
          <img :src="require('assets/images/IdiomCoupon/dy.png')" alt="" />

          抖音官方号
        </div>
        <div class="img_box">
          <img
            :src="require('assets/images/commonFooter/Qrcode2.png')"
            alt=""
          />

          Q群技术支持
        </div>
      </div>
      <div class="right">
        <div class="img_box">
          <!-- <div class="img_item">
            <img :src="require('assets/images/yz.png')" alt="" />

            微信客服企业号
          </div>
          <div class="img_item">
            <img :src="require('assets/images/myh.png')" alt="" />

            微信客服企业号
          </div> -->
        </div>

        <div class="business_negotiation">
          <div class="title">商务洽谈</div>

          <!-- <div class="phone">13828584514</div> -->
          <div class="link">writemall@126.com</div>
        </div>
      </div>
    </div>

    <div class="ip_record">
      深圳写手智能科技有限公司&nbsp;Shenzhen&nbsp;writer&nbsp;Intelligent&nbsp;Technology&nbsp;Co.,&nbsp;Ltd&nbsp;&nbsp;<a
        href="https://beian.miit.gov.cn/"
        target="_blank"
        >粤ICP备20062435号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'commonFooter',
  data() {
    return {
      navlist: [
        {
          name: '关于我们',
          urlName: 'aboutus',
        },
        // {
        //   name: "数据信息",
        //   urlName: "showData",
        // },
        {
          name: '帮助文档',
          urlName: 'help_center',
        },
        {
          name: '联系我们',
          urlName: 'feedBackPage',
        },
        {
          name: '加入我们',
          urlName: 'join_about',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.commonFooter {
  position: relative;
  z-index: 1;
  padding: 22px 290px 0;
  background-color: #fff;
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .nav_item {
      cursor: pointer;
      font-size: 16px;
    }
  }
  .logo_box {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftTemp {
      display: flex;
      align-items: flex-end;
      img {
        margin-right: 10px;
        width: 134px;
      }
      .tempImg {
        width: 77px;
      }
      .text {
        padding-left: 10px;
        border-left: 2px solid #ff6900;
      }
    }
    .right {
      text-align: right;
      .title {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #ff6900;
      }
      .telephone {
        margin-top: 20px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }
  }
  .Qrcode_box {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftTemp {
      display: flex;
      align-items: center;
      .img_box {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 102px;

          margin-bottom: 15px;
        }
      }
    }
    .right {
      display: flex;
      align-items: flex-end;
      .img_box {
        display: flex;
        align-items: center;
        .img_item {
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 102px;
            height: 102px;
            margin-bottom: 15px;
          }
        }
      }

      .business_negotiation {
        margin-left: 42px;
        text-align: right;
        .title {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #ff6900;
        }

        .phone {
          margin-top: 20px;
          font-size: 18px;
        }

        .link {
          margin-top: 15px;
          font-size: 18px;
        }
      }
    }
  }

  .ip_record {
    margin-top: 40px;
    text-align: right;
    border-top: 1px solid #edeff5;
    padding: 23px 0;
    color: #ff6900;
    a {
      color: #ff6900;
    }
  }
}
</style>
