import request from 'api/request'
import oauthrequest from 'api/oauthrequest'
import crewordRequest from 'api/crewordRequest'

//密码登录
export function passLogin(data) {
  return request({
    url: '/user/pwdLogin',
    method: 'post',
    data,
  })
}
// 获取已绑定卡信息
export function findPlusContextual() {
  return request({
    url: 'card/findPlusContextual',
    method: 'get',
  })
}
//验证码登录
export function codeLogin(data) {
  return oauthrequest({
    url: `/oauth/token?grant_type=sms_code&mobile=${data.mobile}&code=${data.code}&scope=all&platform=pc_web&inviteCode=${data.inviteCode}`,
    method: 'post',
    data,
  })
}

// 微信登录
export function weixinLogin(data) {
  return oauthrequest({
    url: `/oauth/token?platform=pc_web`,
    method: 'post',
    data,
  })
}
// 微信登录
export function verify(data) {
  return oauthrequest({
    url: `/wxlogin/verify`,
    method: 'post',
    data,
  })
}
// 用户注册
export function register(data) {
  return oauthrequest({
    url: '/user/register',
    method: 'post',
    data,
  })
}
//发送验证码,获取验证码ID
export function getMobileAchCaptchas(data) {
  return oauthrequest({
    url: '/user/getMobileAchCaptchas',
    method: 'post',
    data,
  })
}
// 获取用户信息
export function getuserInfo(data) {
  return request({
    url: '/user/getUserInfo',
    method: 'get',
    data,
  })
}
// 密码登录
export function passwordLogin(data) {
  return oauthrequest({
    url: `/oauth/token?grant_type=password&username=${
      data.mobile
    }&password=${escape(data.password)}&scope=all&platform=pc_web`,
    method: 'post',
  })
}
// 修改用户设置
export function amendUpdate(data) {
  return crewordRequest({
    url: '/user/setting/update',
    method: 'post',
    data,
  })
}
// 修改密码
export function update(data) {
  return request({
    url: '/user/forgotPassword',
    method: 'post',
    data,
  })
}
// 关注列表
export function findFocusList(data) {
  return request({
    url: '/user/findFocusList',
    method: 'post',
    data,
  })
}
// 取消关注
export function focus(data) {
  return request({
    url: '/user/focus',
    method: 'post',
    data,
  })
}
// 修改资料
export function userInfoUpd(data) {
  return request({
    url: '/user/userInfoUpd',
    method: 'put',
    data,
  })
}

//获取用户设置
export function settings() {
  return crewordRequest({
    url: '/user/setting/find',
    method: 'get',
  })
}
// 查询卡券
export function getcheckCard(data) {
  return request({
    url: 'card/check',
    method: 'POST',
    data,
  })
}
// 绑定卡券
export function getbindCard(data) {
  return request({
    url: 'card/bind',
    method: 'POST',
    data,
  })
}
export function getStaffManage(data) {
  return request({
    url: '/user/staffManage',
    method: 'POST',
    data,
  })
}
