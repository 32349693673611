import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '../utils/auth.js'
import Cookies from 'js-cookie'
import versionTood from '../utils/versionUpdate'
import store from 'store/index'
Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {})
  } else {
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}

const routes = [
  {
    path: '/',
    component: () => import('views/Home'),
    redirect: '/indexPage',
  },
  // 登录
  {
    name: 'Login',
    path: '/register',
    component: () => import('views/login/Login'),
    // meta: {
    //   show: true,
    // },
  },
  //首页
  {
    name: 'Home',
    path: '/Home',
    component: () => import('views/Home'),
    meta: {
      show: true,
    },
  },
  //溯源
  {
    name: 'TraceTheSource',
    path: '/traceTheSource',
    component: () => import('views/home/traceTheSource'),
    meta: {
      show: true,
    },
  },

  //plus已绑定
  {
    name: 'bound',
    path: '/bound',
    component: () => import('views/credCoupon/bound'),
  },
  //首页
  {
    name: 'IndexPage',
    path: '/indexPage',
    component: () => import('views/indexPage/indexPage'),
    meta: {
      show: true,
    },
  },
  // 下载
  {
    name: 'downloadPage',
    path: '/downloadPage',
    component: () => import('views/indexPage/downloadPage'),
    meta: {
      show: true,
    },
  },
  // 企业动态
  {
    name: 'firmDynamic',
    path: '/firmDynamic',
    component: () => import('views/indexPage/firmDynamic'),
    meta: {
      show: true,
    },
  },
  // 企业动态
  {
    name: 'firmDetails',
    path: '/firmDetails',
    component: () => import('views/indexPage/firmDetails'),
    meta: {
      show: true,
    },
  },
  //电子签名
  {
    // ElectronicSignature
    name: 'ElectronicSignature',
    path: '/word',
    component: () => import('views/ElectronicSignature/word'),
    // meta: {
    //   show: true,
    // },
  },
  // 留言建议
  {
    name: 'feedBackPage',
    path: '/feedBackPage',
    component: () => import('views/indexPage/feedBackPage'),
    meta: {
      show: true,
    },
  },
  // 合作咨询
  {
    name: 'cooperationPage',
    path: '/cooperationPage',
    component: () => import('views/indexPage/cooperationPage'),
    meta: {
      show: true,
    },
  },
  // 极速办介绍
  {
    name: 'speedPage',
    path: '/speedPage',
    component: () => import('views/indexPage/speedPage'),
    meta: {
      show: true,
    },
  },
  //核校
  // {
  //   name: 'Checking',
  //   path: '/checking',
  //   component: () => import('views/checking/checkingIndex'),
  //   meta: {
  //     show: true,
  //   },
  // },
  {
    // TwentyBig
    path: '/TwentyBig',
    name: 'TwentyBig',
    component: () => import('views/TwentyBig/TwentyBig'),
    meta: {
      show: true,
    },
  },
  {
    name: 'library',
    path: '/library',
    component: () => import('views/library/library'),
    meta: {
      show: true,
    },
    redirect: '/MyAttention',
    children: [
      // 我的关注
      {
        name: 'MyAttention',
        path: '/MyAttention',
        component: () => import('views/library/MyAttention'),
        meta: {
          show: true,
        },
      },
      // 作者榜
      {
        name: 'AuthorRank',
        path: '/AuthorRank',
        component: () => import('views/library/AuthorRank'),
        meta: {
          show: true,
        },
      },
      {
        name: 'WorksRank',
        path: '/WorksRank',
        component: () => import('views/library/WorksRank'),
        meta: {
          show: true,
        },
      },
    ],
  },
  //puls卡券
  {
    name: 'check',
    path: '/check',
    component: () => import('views/credCoupon/index'),
    meta: {
      show: true,
    },
  },
  //puls卡券
  {
    name: 'bindCoupon',
    path: '/bindCoupon',
    component: () => import('views/credCoupon/index'),
    meta: {
      show: true,
    },
  },
  //存证
  {
    name: 'Store',
    path: '/store',
    component: () => import('views/home/store/store'),
    meta: {
      show: true,
    },
    redirect: 'store/storeRightBox',
    children: [
      {
        name: 'StoreRightBox',
        path: 'storeRightBox',
        component: () => import('views/home/store/storeRightBox'),
        meta: {
          show: true,
        },
      },
      {
        name: 'StoreRightList',
        path: 'storeRightList',
        component: () => import('views/home/store/storeRightList'),
        meta: {
          show: true,
        },
      },
      {
        name: 'StoreTraceList',
        path: 'storeTraceList',
        component: () => import('views/home/store/StoreTraceList'),
        meta: {
          show: true,
        },
      },
    ],
  },
  // 溯源
  {
    name: 'traceSource',
    path: '/traceSourceIndex',
    component: () => import('views/traceSource/TraceSourceRouterView'),
    redirect: '/traceSource',
    children: [
      //溯源首页
      {
        name: 'TraceSource',
        path: '/traceSource',
        component: () => import('views/traceSource/OfficeTraceSource'),
        meta: {
          show: true,
        },
      },
      //溯源结果页
      {
        name: 'TraceSourceResult',
        path: '/traceSourceResult',
        component: () => import('views/traceSource/TraceSourceResult'),
        meta: {
          show: true,
        },
      },
      //溯源文件报表
      {
        name: 'TraceSourceFile',
        path: '/traceSourceFile/:payType/:setId',
        component: () => import('views/traceSource/TraceSourceFile'),
        meta: {
          show: true,
        },
        props: true,
      },
      //历史溯源
      {
        name: '/TraceSourceHistory',
        path: '/traceSourceHistory',
        component: () => import('views/traceSource/TraceSourceHistory'),
        meta: {
          show: true,
        },
      },
    ],
  },
  // ocr
  {
    path: '/ocr',
    name: 'ocr',
    component: () => import('views/ocr/index'),
    meta: {
      show: true,
    },
  },
  // 作者详情
  {
    path: '/authorDetails/:userId?',
    name: 'authorDetails',
    component: () => import('views/library/authorDetails'),
    meta: {
      show: true,
    },
  },
  //版权收益
  {
    name: 'copyrightIncome',
    path: '/copyrightIncome',
    component: () => import('views/home/copyright-income/CopyrightIncomeCopy'),
    meta: {
      show: true,
    },
  },
  //历史引用
  {
    name: 'HistoricalReference',
    path: '/historicalReference',
    component: () => import('views/idiom/historicalReference'),
    meta: {
      show: true,
    },
  },
  //图片
  {
    path: '/image',
    name: 'Image',
    component: () => import('views/searchImage/searchImage'),
    meta: {
      show: true,
    },
  },
  //ai绘画
  {
    path: '/aiimage',
    name: 'AiImage',
    component: () => import('views/searchImage/AIimage'),
    meta: {
      show: true,
    },
  },
  // originalWorks
  {
    path: '/originalWorks',
    name: 'OriginalWorks',
    component: () => import('views/originalWorks/originalWorks'),
    meta: {
      show: true,
    },
  },
  //金句
  {
    name: 'quotes',
    path: '/quotes',
    component: () => import('views/idiom/quotes'),
    meta: {
      show: true,
    },
  },
  // 专业服务
  {
    name: 'professional',
    path: '/professional',
    component: () => import('views/professional/index'),
    meta: {
      show: true,
    },
  },
  //模本
  {
    name: 'templatetHtml',
    path: '/templatetHtml',
    component: () => import('views/stencil/stencilIndex'),
    meta: {
      show: true,
    },
    // redirect: "/stencilIndex",
    // children: [
    //   {
    //     meta: {
    //       show: true,
    //     },
    //     name: "stencilIndex",
    //     path: "/stencilIndex",
    //     component: () => import("views/stencil/stencilIndex"),
    //   },
    // ],
  },
  // 极速版跳转页面
  {
    name: 'extremeSpeed',
    path: '/extremeSpeed',
    component: () => import('views/extremeSpeed/extremeSpeed'),
    redirect: '/libraryS',
    children: [
      {
        name: 'libraryS',
        path: '/libraryS',
        component: () => import('views/extremeSpeed/libraryS'),
      },
      {
        name: 'templateS',
        path: '/templateS',
        component: () => import('views/extremeSpeed/templateS'),
      },
      {
        name: 'goldenSentenceS',
        path: '/goldenSentenceS',
        component: () => import('views/extremeSpeed/goldenSentenceS'),
      },

      {
        name: 'shoppingMallS',
        path: '/shoppingMallS',
        component: () => import('views/extremeSpeed/shoppingMallS'),
      },

      {
        name: 'orderS',
        path: '/orderS',
        component: () => import('views/extremeSpeed/orderS'),
      },
      {
        name: 'downloadS',
        path: '/downloadS',
        component: () => import('views/extremeSpeed/downloadS'),
      },
    ],
  },
  {
    name: 'AiImageS',
    path: '/AiImageS',
    component: () => import('views/extremeSpeed/AiImageS'),
  },
  {
    name: 'IdiomDetailsS',
    path: '/IdiomDetailsS',
    component: () => import('views/extremeSpeed/IdiomDetailsS'),
  },
  // 一键续写
  {
    name: 'OneclickwritingDilets',
    path: '/OneclickwritingDilets',
    component: () => import('views/extremeSpeed/OneclickwritingDilets'),
  },
  // AI成文
  {
    name: 'Oneclickwriting',
    path: '/Oneclickwriting',
    component: () => import('views/extremeSpeed/Oneclickwriting'),
    redirect: '/Oneclickwriting',
    children: [
      {
        name: 'Oneclickwriting',
        path: '/Oneclickwriting',
        component: () => import('views/extremeSpeed/Oneclickwriting'),
        meta: {
          num: 1,
        },
      },
      {
        name: 'ShoppingCart',
        path: '/ShoppingCart',
        component: () => import('views/extremeSpeed/ShoppingCart'),
        meta: {
          num: 1,
        },
      },

      {
        name: 'writtenAi',
        path: '/writtenAi',
        component: () => import('views/extremeSpeed/writtenAi/Leadership'),
        meta: {
          num: 1,
        },
      },
      {
        name: 'DraftAIGC',
        path: '/DraftAIGC',
        component: () => import('views/extremeSpeed/writtenAi/DraftAIGC'),
        meta: {
          num: 1,
        },
      },
      // {
      //   name: 'writtenAi',
      //   path: '/writtenAi',
      //   component: () => import('views/extremeSpeed/writtenAi/writtenAi'),
      //   meta: {
      //     num: 1,
      //   },
      // },
      {
        name: 'Dilets',
        path: '/Dilets',
        component: () => import('views/extremeSpeed/Dilets'),
        meta: {
          num: 1,
        },
      },
    ],
  },
  {
    name: 'netDiskS',
    path: '/netDiskS',
    component: () => import('views/extremeSpeed/netDiskS'),
  },
  // 信息汇编
  {
    name: 'InfoCompile',
    path: '/infoCompile',
    component: () => import('views/infoCompile/infoCompile'),
    meta: {
      show: true,
    },
    redirect: '/infoCompileIndex',
    children: [
      {
        name: 'InfoCompileIndex',
        path: '/infoCompileIndex',
        component: () => import('views/infoCompile/infoCompileIndex'),
        meta: {
          show: true,
        },
      },
      {
        name: 'AllText',
        path: '/alltext',
        component: () => import('views/infoCompile/alltext'),
        meta: {
          show: true,
        },
      },
    ],
  },
  //版权服务
  {
    name: 'copyrightService',
    path: '/copyrightService',
    component: () => import('views/home/copyright-service/CopyrightService'),
    meta: {
      show: true,
    },
    redirect: 'copyrightService/copyrightPractice',
    children: [
      //版权实务
      {
        name: 'CopyrightPractice',
        path: 'copyrightPractice',
        component: () =>
          import(
            'views/home/copyright-service/copyright-practice/CopyrightPractice'
          ),
        meta: {
          show: true,
        },
        redirect: 'copyrightPractice/copyrightPracticeIndex',
        children: [
          //版权实务首页
          {
            name: 'CopyrightPracticeIndex',
            path: 'copyrightPracticeIndex',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/CopyrightPracticeIndex'
              ),
            meta: {
              show: true,
            },
          },
          //我的关注
          {
            name: 'MyAttention',
            path: 'myAttention',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/MyAttention'
              ),
            meta: {
              show: true,
            },
          },
          //代办委托
          {
            name: 'AgencyEntrustment',
            path: 'agencyEntrustment',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/AgencyEntrustment'
              ),
            meta: {
              show: true,
            },
            redirect: 'agencyEntrustment/AgencyEntrustmentIndex',
            children: [
              {
                name: 'AgencyEntrustmentIndex',
                path: 'agencyEntrustmentIndex',
                component: () =>
                  import(
                    'views/home/copyright-service/copyright-practice/AgencyEntrustmentIndex'
                  ),
                meta: {
                  show: true,
                },
              },
              // 代办委托详情
              {
                name: 'AgencyEntrustmentDetailsContractor',
                path: 'AgencyEntrustmentDetailsContractor',
                component: () =>
                  import(
                    'views/home/copyright-service/copyright-practice/AgencyEntrustmentDetailsContractor'
                  ),
                meta: {
                  show: true,
                },
              },
              {
                name: 'AgencyEntrustmentDetailsUser',
                path: 'AgencyEntrustmentDetailsUser',
                component: () =>
                  import(
                    'views/home/copyright-service/copyright-practice/AgencyEntrustmentDetailsUser'
                  ),
                meta: {
                  show: true,
                },
              },
              // 执业规范
              {
                name: 'PracticeNorms',
                path: 'practiceNorms',
                component: () =>
                  import(
                    'views/home/copyright-service/copyright-practice/practiceNorms'
                  ),
                meta: {
                  show: true,
                },
              },
            ],
          },
          //订单中心
          {
            name: 'OrderCenter',
            path: 'orderCenter',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/OrderCenter'
              ),
            meta: {
              show: true,
            },
          },
          // 成语券消费
          {
            name: 'OrderIdiom',
            path: 'OrderIdiom',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/OrderIdiom'
              ),
            meta: {
              show: true,
            },
          },
          // 订单评论
          {
            name: 'OrderComments',
            path: 'orderComments',
            props: {},
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/OrderComments'
              ),
            meta: {
              show: true,
            },
          },
          // 订单详情
          {
            name: 'OrderDetails',
            path: 'orderDetails',
            props: {},
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/OrderDetails'
              ),
            meta: {
              show: true,
            },
          },
          //我的收藏
          {
            name: 'MyCollection',
            path: 'myCollection',
            component: () =>
              import(
                'views/home/copyright-service/copyright-practice/MyCollection'
              ),
            meta: {
              show: true,
            },
          },
        ],
      },

      // 实名认证
      {
        name: 'RealName',
        path: 'realName',
        component: () =>
          import('views/home/copyright-service/realName/realName'),
        meta: {
          show: true,
        },
        redirect: 'realName/personalRealName',
        children: [
          //个人实名
          {
            name: 'PersonalRealName',
            path: 'personalRealName',
            component: () =>
              import(
                'views/home/copyright-service/realName/personal/PersonalRealName'
              ),
            meta: {
              show: true,
            },
          },
          //填写个人实名信息
          {
            name: 'FillPersonalInfo',
            path: 'fillPersonalInfo',
            component: () =>
              import(
                'views/home/copyright-service/realName/personal/FillPersonalInfo'
              ),
            meta: {
              show: true,
            },
          },
          //填写个人实名 2-补充信息
          {
            name: 'SupplementInfo',
            path: 'supplementInfo',
            component: () =>
              import(
                'views/home/copyright-service/realName/personal/SupplementInfo'
              ),
            meta: {
              show: true,
            },
          },
          //填写个人实名 3-提交成功
          {
            name: 'RealNameSuccess',
            path: 'realNameSuccess',
            component: () =>
              import(
                'views/home/copyright-service/realName/personal/RealNameSuccess'
              ),
            meta: {
              show: true,
            },
          },
          //个人已实名
          {
            name: 'PersonalAlreadyRealName',
            path: 'personalAlreadyRealName/:realName',
            component: () =>
              import(
                'views/home/copyright-service/realName/personal/PersonalAlreadyRealName'
              ),
            meta: {
              show: true,
            },
            props: true,
          },
          //机构实名
          {
            name: 'OrganizationRealName',
            path: 'organizationRealName',
            component: () =>
              import(
                'views/home/copyright-service/realName/organization/OrganizationRealName'
              ),
            meta: {
              show: true,
            },
          },
          //填写机构实名信息
          {
            name: 'FillOrganizationInfo',
            path: 'fillOrganizationInfo',
            component: () =>
              import(
                'views/home/copyright-service/realName/organization/FillOrganizationInfo'
              ),
            meta: {
              show: true,
            },
          },
          //机构已实名
          {
            name: 'OrganizationAlreadyRealName',
            path: 'organizationAlreadyRealName/:realName',
            component: () =>
              import(
                'views/home/copyright-service/realName/organization/OrganizationAlreadyRealName'
              ),
            meta: {
              show: true,
            },
            props: true,
          },
        ],
      },
      // 收录确权
      {
        name: 'Included',
        path: 'included',
        component: () =>
          import('views/home/copyright-service/included/included'),
        meta: {
          show: true,
        },
      },
      //  指纹核验
      {
        name: 'Fingerprint',
        path: 'fingerprint',
        component: () =>
          import('views/home/copyright-service/fingerprint/fingerprint'),
        meta: {
          show: true,
        },
      },
      // 会员服务
      {
        name: 'VipServe',
        path: 'vipServe',
        component: () =>
          import('views/home/copyright-service/vipServe/vipServe'),
        meta: {
          show: true,
        },
      },
      //产品详情
      {
        name: 'CopyrightPracticeDetail',
        path: 'copyrightPracticeDetail/:id?',
        component: () =>
          import(
            'views/home/copyright-service/copyright-practice/CopyrightPracticeDetail'
          ),
        meta: {
          show: true,
        },
      },
    ],
  },
  //数据分析
  {
    name: 'DataAnalysis',
    path: '/dataAnalysis',
    component: () => import('views/dataAnalysis/DataAnalysis'),
    redirect: '/dataAnalysisIndex',
    children: [
      // 数据分析首页
      {
        name: 'DataAnalysisIndex',
        path: '/dataAnalysisIndex',
        component: () => import('views/dataAnalysis/DataAnalysisIndex'),
        meta: {
          show: true,
        },
      },
      // 分析样板
      {
        name: 'DataAnalysisModel',
        path: '/dataAnalysisModel',
        component: () => import('views/dataAnalysis/DataAnalysisModel'),
        meta: {
          show: true,
        },
      },
      // 分析报告样本
      {
        name: 'DataAnalysisInfo',
        path: '/dataAnalysisInfo',
        component: () => import('views/dataAnalysis/DataAnalysisInfo'),
        meta: {
          show: true,
        },
      },
      // 分析结果
      {
        name: 'DataAnalysisResult',
        path: '/dataAnalysisResult',
        component: () => import('views/dataAnalysis/DataAnalysisResult'),
        meta: {
          show: true,
        },
      },
      // 报告
      {
        name: 'DataAnalysiSeport',
        path: '/dataAnalysiSeport',
        component: () => import('views/dataAnalysis/DataAnalysiSeport'),
        meta: {
          show: true,
        },
      },
      //历史数据分析
      {
        name: 'DataAnalysisHistory',
        path: '/dataAnalysisHistory',
        component: () => import('views/dataAnalysis/DataAnalysisHistory'),
        meta: {
          show: true,
        },
      },
    ],
  },
  //关于我们
  {
    name: 'aboutus',
    path: '/aboutus',
    meta: {
      show: true,
    },
    component: () => import('views/aboutus1/index'),
  },
  // 加入我们

  {
    name: 'join_about',
    path: '/join_about',
    meta: {
      show: true,
    },
    component: () => import('views/join_about/index'),
  },

  // 帮助文档
  {
    name: 'help_center',
    path: '/help_center',
    meta: {
      show: true,
    },
    component: () => import('views/help_center/index'),
  },
  //意见bug反馈
  {
    name: 'Feedback',
    path: '/Feedback',
    meta: {
      show: true,
    },
    component: () => import('views/Feedback/Feedback'),
  },
  //商城
  {
    name: 'shoppingMall',
    path: '/shoppingMall',
    meta: {
      show: true,
    },
    component: () => import('views/shoppingMall/shoppingMall'),
  },
  // 商品详情
  {
    name: 'details',
    path: '/details/:id/:type?',
    meta: {
      show: true,
    },
    component: () => import('views/shoppingMall/details'),
  },
  // 实务详情详情
  {
    name: 'copyrightDetails',
    path: '/copyrightDetails/:id/:type?',
    meta: {
      show: true,
    },
    component: () => import('views/shoppingMall/copyrightDetails'),
  },
  //下载引导
  {
    name: 'DownloadGuide',
    path: '/downloadGuide',
    component: () => import('views/downloadguide/NewDownloadGuide'),
    meta: {
      show: true,
    },
  },
  //网盘
  {
    name: 'disk',
    path: '/disk',
    meta: {
      show: true,
    },
    component: () => import('views/disk/index'),
  },

  //账户记录
  {
    name: 'AccountRecords',
    path: '/accountRecords',
    meta: {
      show: true,
    },
    component: () => import('views/account-records/AccountRecords'),
  },
  //授权书
  {
    name: 'letterOfAuthorization',
    path: '/letterOfAuthorization',
    component: () =>
      import('views/letter-of-authorization/LetterOfAuthorization'),
  },
  // 成语写作课
  {
    name: 'videoWriting',
    path: '/videoWriting',
    meta: {
      show: true,
    },
    component: () => import('views/videoWriting/videoWriting'),
    redirect: '/videoWritingIndex',
    children: [
      // 成语写作课
      {
        name: 'videoWritingIndex',
        path: '/videoWritingIndex',
        component: () => import('views/videoWriting/videoWritingIndex'),
        meta: {
          show: true,
        },
      },
      // 成语写作课详情
      {
        name: 'videoWritingInfo',
        path: '/videoWritingInfo/:id',
        component: () => import('views/videoWriting/videoInfo'),
        meta: {
          show: true,
        },
      },
    ],
  },

  // //暂定：历史溯源报告
  // {
  //   name: "historicalTracing",
  //   path: "/historicalTracing",
  //   component: () => import("views/home/HistoricalTracing"),
  // },
  // //暂定：进行中溯源报告
  // {
  //   name: "traceTheSourceReporting",
  //   path: "/traceTheSourceReporting",
  //   component: () => import("views/home/TraceTheSourceReporting"),
  // },
  //推荐体验
  {
    name: 'RecommendExperience',
    path: '/recommendExperience',
    component: () => import('views/idiom/Idiom'),
    meta: {
      show: true,
    },
  },
  //金句
  {
    path: '/goldenSentence',
    name: 'GoldenSentence',
    component: () => import('views/goldenSentence/goldenSentence'),
    redirect: 'goldenSentence/goldenSentenceIndex',
    meta: {
      show: true,
    },
    children: [
      {
        name: 'GoldenSentenceIndex',
        path: 'goldenSentenceIndex',
        component: () => import('views/goldenSentence/goldenSentenceIndex'),
        meta: {
          show: true,
        },
      },
    ],
  },
  // 网页采集工具栏
  {
    name: 'DataCollectionToolBar',
    path: '/dataCollectionToolBar',
    component: () => import('views/dataCollection/DataCollectionToolBar'),
    meta: {
      show: true,
    },
  },
  //网页采集主体
  {
    name: 'DataCollecitonMain',
    path: '/dataCollectionMain',
    component: () => import('views/dataCollection/DataCollectionMain'),
  },
  //深入学习
  {
    name: 'DepthLearn',
    path: '/depthLearn',
    component: () => import('views/depth-learn/DepthLearn'),
    meta: {
      show: true,
    },
  },

  // 会员中心
  {
    name: 'VIPcenter',
    path: '/VIPcenter',
    meta: {
      show: true,
    },
    component: () => import('views/VIPcenter/VIPcenter'),
  },
  {
    name: 'AuthRedirect',
    path: '/AuthRedirect',
    meta: {
      show: true,
    },
    component: () => import('views/AuthRedirect/AuthRedirect'),
  },
  // 数据信息
  {
    name: 'showData',
    path: '/showData',
    meta: {
      show: true,
    },
    component: () => import('views/showData/showData'),
  },
  {
    name: 'HomePage',
    path: '/HomePage',
    meta: {
      show: true,
    },
    component: () => import('views/HomePage/HomePage'),
  },
  // //暂定：历史溯源报告
  // {
  //   name: "historicalTracing",
  //   path: "/historicalTracing",
  //   component: () => import("views/home/HistoricalTracing"),
  // },
  // //暂定：进行中溯源报告
  // {
  //   name: "traceTheSourceReporting",
  //   path: "/traceTheSourceReporting",
  //   component: () => import("views/home/TraceTheSourceReporting"),
  // },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// router.beforeEach((to,from,next) => {
//   if(to.path == "/home"){

//   }
// });
/* 添加路由守卫 */
router.afterEach((to, from) => {
  const path = to.path
  if (
    path == '/libraryS' ||
    path == '/templateS' ||
    path == '/goldenSentenceS' ||
    path == '/shoppingMallS' ||
    path == '/netDiskS'
  ) {
    console.log(store)

    store.commit('createdShowNavfun')
    window.onheaderShow()
  }
})

router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  const path = to.path
  //
  // localStorage.removeItem("auth_token");
  if (to.matched.length == 0) {
    return next('/infoCompileIndex')
  }
  //   /* 首先获取访问的路径 */

  const id = to.params.id
  const type = to.params.type

  let url = window.location.search
  let theRequest = new Object()

  if (url.includes('token=')) {
    let str = url.substr(1)
    console.log(str)
    let strs = str.split('&')

    console.log(strs)

    strs.forEach((item, i) => {
      theRequest[item.split('=')[0]] = item.split('=')[1]
    })
    console.log(theRequest)
    let obj = {
      access_token: theRequest.token,
      token_type: 'bearer',
    }

    localStorage.setItem('auth_token', JSON.stringify(theRequest.token))
    Cookies.set('auth_token', JSON.stringify(obj), {
      expires: 7,
      path: '/',
      domain: '.writemall.com',
    })
  }
  if (
    path == '/' ||
    path == '/libraryS' ||
    path == '/templateS' ||
    path == '/feedBackPage' ||
    path == '/firmDynamic' ||
    path == '/IdiomDetailsS' ||
    path == '/firmDetails' ||
    path == '/indexPage' ||
    path == '/speedPage' ||
    path == '/cooperationPage' ||
    path == '/downloadPage' ||
    path == '/goldenSentenceS' ||
    path == '/extremeSpeed' ||
    path == '/showData' ||
    path == '/HomePage' ||
    path == '/Home' ||
    path == '/shoppingMall' ||
    path == '/aboutus' ||
    path == '/copyrightService/copyrightPractice/copyrightPracticeIndex' ||
    path == '/infoCompileIndex' ||
    path == '/infoCompile' ||
    path == '/copyrightService/vipServe' ||
    path == '/realName' ||
    path == '/downloadGuide' ||
    path == '/Feedback' ||
    path == '/AuthRedirect' ||
    path == '/details/' + id + '/' + type ||
    path == '/details/' + id ||
    path == '/copyrightDetails/' + id ||
    path == '/help_center' ||
    path == '/join_about' ||
    path == '/quotes' ||
    path == '/stencilIndex' ||
    path == '/depthLearn' ||
    path == '/templatetHtml' ||
    path == '/professional' ||
    path == '/videoWriting' ||
    path == '/videoWritingIndex' ||
    path == '/videoWritingInfo/' + id ||
    path == '/AuthorRank' ||
    path == '/WorksRank' ||
    path == '/VIPcenter' ||
    path == '/goldenSentence/goldenSentenceIndex' ||
    path == '/recommendExperience' ||
    path == '/checking' ||
    path == '/traceSource' ||
    path == '/dataAnalysisIndex' ||
    path == '/dataCollectionToolBar' ||
    path == '/ocr' ||
    path == '/disk' ||
    path == '/TwentyBig' ||
    path == '/baidu_verify_codeva-GxdO4F8Tvd.html' ||
    path == '/register' ||
    path == '/word'
  ) {
    console.log('codeLoad')
    return next()
  }

  //   /* 没有令牌不可以访问的页面 */
  if (!getToken('auth_token')) {
    new Vue().$message({
      message: '请先登录！',
      duration: 1500,
    })
    try {
      return window.loginFun()
    } catch (error) {
      return next('/infoCompileIndex')
    }
  }
  //   /* 有令牌后页面都可以访问 */
  next()
})
export default router
