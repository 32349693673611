<template>
  <div class="userInfoDialog">
    <!-- vip权益 -->
    <el-dialog
      :visible.sync="dialogVisible"
      @open="getVipInfo"
      class="interest"
    >
      <div class="content">
        <div class="content-item" v-for="(item, index) in vipList" :key="index">
          <div class="content-item-title">
            <div class="content-item-line"></div>
            {{ item.tpsname }}
          </div>
          <div class="content-desc">
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon drillIcon"></i>
                <div class="desc-item-content-title">{{ item.tmldisk }}G</div>
                <div class="desc-item-content-story">智能网盘</div>
              </div>
            </div>
            <div class="desc-item">
              <div class="desc-item-content">
                <i class="desc-item-content-icon textIcon"></i>
                <div class="desc-item-content-title">
                  {{ item.tmlcharacter }}/月
                </div>
                <div class="desc-item-content-story">字符包</div>
              </div>
            </div>

            <div class="desc-bigBox">
              <div class="desc-bigBox-text">
                <div class="desc-bigBox-title">
                  年卡：<span>{{ item.tmlprice }}￥</span>
                </div>
                <div class="desc-bigBox-story">
                  {{ (item.tmlprice / 12).toFixed(2) }}/月
                </div>
                <div class="desc-bigBox-btn" @click="handleVip(1)">
                  {{ item.classify == 1 ? "开通VIP" : "开通SVIP" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 绑定体验卡 -->
    <el-dialog
      title="绑定体验卡"
      :visible.sync="dialogCard"
      class="experienceCard"
      :before-close="handleClose"
    >
      <div class="content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="卡号：">
            <el-input
              v-model="form.card"
              placeholder="请输入体验卡卡号"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer">
        <div class="footer-item sure" @click="SubmitCard">确定</div>
        <div class="footer-item" @click="handleClose">取消</div>
      </div>
    </el-dialog>
    <!-- 设置中心 -->
    <el-dialog title="设置中心" :visible.sync="dialogsetup" class="setupCenter">
      <div class="content">
        <div class="content-title">快捷支付字符</div>
        <el-radio v-model="payType" label="1">快捷支付字符</el-radio>
        <el-radio v-model="payType" label="2">手动确认扣减</el-radio>
      </div>
      <div class="contentStyle">
        <div class="contentStyle-title">内容溯源样式</div>
        <div class="contentStyle-item">
          <div class="contentStyle-item-label">重复字段标注：</div>
          <div class="contentStyle-item-radio">
            <el-radio v-model="fieldStyle" label="1">红色字体</el-radio>
            <el-radio v-model="fieldStyle" label="2">蓝色字体</el-radio>
            <el-radio v-model="fieldStyle" label="3">下划线</el-radio>
          </div>
        </div>
        <div class="contentStyle-item">
          <div class="contentStyle-item-label">来源出处标注：</div>
          <div class="contentStyle-item-radio">
            <el-radio v-model="sourceLocation" label="1">脚注</el-radio>
            <el-radio v-model="sourceLocation" label="2">尾注</el-radio>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn sure" @click="handleSetupSure">确定</div>
        <div class="footer-btn" @click="dialogsetup = false">取消</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTraceSetup, postTraceSetup, bindCard } from "api/traceTheSource";
import { diskRightsOpenInfo } from "api/header";
export default {
  data() {
    return {
      dialogVisible: false, //vip权益
      // 网盘中会员开通信息
      vipList: [],
      dialogCard: false, //绑定体验卡
      form: {
        card: "",
      },
      dialogsetup: false, //设置中心
      payType: "",
      fieldStyle: "",
      sourceLocation: "",
      setUpId: null,
    };
  },
  methods: {
    // 网盘中会员开通信息
    async getVipInfo() {
      const res = await diskRightsOpenInfo();
      if (res.code !== 200) {
        return this.$message({
          type: "error",
          message: res.message,
        });
      }

      this.vipList = res.data;
    },
    // 跳转到vip
    handleVip(id) {
      this.$router.push({
        name: "CopyrightPracticeDetail",
        params: { id },
      });
      this.dialogVisible = false;
      this.$parent.userInfo = false;
    },
    handleClose() {
      this.form.card = "";
      this.dialogCard = false;
    },
    async getTraceSourceSetUp() {
      const res = await getTraceSetup();
      if (res.code === 200) {
        this.payType = res.data.payType.toString();
        this.fieldStyle = res.data.fieldStyle.toString();
        this.sourceLocation = res.data.sourceLocation.toString();
        this.setUpId = res.data.id;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async handleSetupSure() {
      const data = {
        id: this.setUpId,
        payType: parseInt(this.payType),
        fieldStyle: parseInt(this.fieldStyle),
        sourceLocation: parseInt(this.sourceLocation),
      };
      const res = await postTraceSetup(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "设置成功！",
        });
        this.dialogsetup = false;
        this.getTraceSourceSetUp();
        this.$bus.$emit("traceSetup");
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 绑定体验卡/优惠码 确定
    async SubmitCard() {
      if (this.form.card == "") {
        this.$message({
          type: "error",
          message: "请输入体验卡/优惠码！",
        });
        return;
      }
      let data = {
        code: this.form.card,
      };
      const res = await bindCard(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "绑定成功！",
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
  created() {
    this.getTraceSourceSetUp();
  },
};
</script>

<style lang="scss" scoped>
.userInfoDialog {
  .interest {
    ::v-deep .el-dialog {
      width: 513px;
      height: 450px;
      background: #ffffff;
      border: 1px solid #e8eaef;
      box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
      border-radius: 12px;
      .el-dialog__close {
        font-size: 20px;
      }
      .el-dialog__body {
        padding: 30px 40px;
        .content {
          .content-item {
            margin-top: 40px;
            .content-item-title {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              .content-item-line {
                width: 4px;
                height: 17px;
                background: #f5d693;
                box-shadow: 2px 0px 0px 0px #fff6d3;
                margin-right: 7px;
              }
            }
            .content-desc {
              margin-top: 20px;
              display: flex;
              .desc-item {
                width: 107px;
                height: 122px;
                background: #ffffff;
                box-shadow: 0px 4px 16px 0px rgba(188, 188, 188, 0.3);
                border-radius: 6px;
                margin-right: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                .desc-item-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .desc-item-content-icon {
                    width: 28px;
                    height: 26px;
                  }
                  .drillIcon {
                    @include backgroundGroup("~assets/images/drillIcon.png");
                  }
                  .textIcon {
                    @include backgroundGroup("~assets/images/textIcon.png");
                  }
                  .desc-item-content-title {
                    margin-top: 12px;
                    font-size: 18px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #222222;
                  }
                  .desc-item-content-story {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    margin-top: 10px;
                  }
                }
              }
              .desc-bigBox {
                width: 154px;
                height: 122px;
                margin-left: 6px;
                text-align: center;
                .desc-bigBox-title {
                  margin-top: 20px;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #222222;
                  span {
                    color: #ea2020;
                  }
                }
                .desc-bigBox-story {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  margin-top: 5px;
                }
                .desc-bigBox-btn {
                  width: 153px;
                  height: 39px;
                  border-image: linear-gradient(0deg, #f9d7a8, #feedce) 10 10;
                  background: linear-gradient(90deg, #f9d7a9 0%, #fdeac9 100%);
                  border-radius: 19px;
                  line-height: 39px;
                  margin-top: 15px;
                  font-size: 16px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  cursor: pointer;
                  color: #2d2e31;
                }
              }
            }
          }
          .content-item:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .experienceCard {
    ::v-deep .el-dialog {
      width: 418px;
      height: 257px;
      background: #ffffff;
      box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
      border-radius: 5px;
      .el-dialog__body {
        padding: 30px 25px;
        .el-form-item__label {
          text-align: center;
        }
        .footer {
          margin-top: 50px;
          display: flex;
          justify-content: flex-end;
          .footer-item {
            width: 68px;
            height: 40px;
            background: #f1f1f1;
            border-radius: 5px;
            margin-right: 14px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            &:last-of-type {
              margin-right: 0px;
            }
          }
          .sure {
            background: #ff6900;
            color: #fff;
          }
        }
      }
    }
  }
  .setupCenter {
    ::v-deep .el-dialog {
      width: 513px;
      height: 446px;
      background: #ffffff;
      border: 1px solid #e8eaef;
      box-shadow: 0px 3px 16px 0px rgba(129, 135, 144, 0.22);
      border-radius: 12px;
      .el-dialog__body {
        padding: 43px 24px 0 24px;
        .el-radio__inner {
          border-radius: 0;
          width: 16px;
          height: 16px;
        }
        .el-radio__input.is-checked .el-radio__inner {
          border-color: #ff6900;
          background: #ff6900;
        }
        .el-radio__input.is-checked .el-radio__inner::after {
          content: "";
          width: 6px;
          height: 3px;
          border: 2px solid white;
          border-top: transparent;
          border-right: transparent;
          text-align: center;
          display: block;
          position: absolute;
          top: 4px;
          left: 3px;
          vertical-align: middle;
          transform: rotate(-62deg);
          border-radius: 2px;
          background: none;
        }
        .el-radio__label {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
        .content {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          .content-title {
            margin-bottom: 21px;
          }
        }
        .contentStyle {
          margin-top: 40px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          .contentStyle-item {
            display: flex;
            margin-top: 20px;
            .contentStyle-item-label {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-right: 50px;
              line-height: 30px;
            }
            .contentStyle-item-radio {
              width: 258px;
              line-height: 30px;
            }
          }
        }
        .footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 35px;
          .footer-btn {
            width: 68px;
            height: 40px;
            background: #f1f1f1;
            border-radius: 5px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            line-height: 40px;
            text-align: center;
            margin-right: 14px;
            cursor: pointer;
            &:last-of-type {
              margin-right: 14px;
            }
          }
          .sure {
            color: #fff;
            background: #ff6900;
          }
        }
      }
    }
  }
}
</style>
