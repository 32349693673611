import request from "api/request";

// 获取字符显示数量
export function checkInterestsNumber(data) {
  return request({
    method: "post",
    url: "/userInterests/checkInterestsNumber",
    data,
  });
}
//发送富文本溯源内容
export function traceSource(data) {
  return request({
    method: "post",
    url: "/creation/traceSource",
    data,
  });
}
//获取富文本溯源结果
export function getTraceSourceResult(params) {
  return request({
    url: "/creation/getTraceSourceResult",
    method: "get",
    params,
  });
}
// 扣减字符
export function deductInterestsNumber(data) {
  return request({
    method: "post",
    url: "/userInterests/deductInterestsNumber",
    data,
  });
}
// 获取历史溯源报告列表
export function findTraceReport(data) {
  return request({
    method: "post",
    url: "/creation/findTraceReport",
    data,
  });
}
// 作品详情
export function getWorkDetails(data) {
  return request({
    method: "post",
    url: `/portfolio/info?md5=${data}`,
    data,
  });
}
// 生成授权书
export function generateAuthorization(data) {
  return request({
    method: "post",
    url: `/portfolio/generationOfAuthorization`,
    data,
  });
}

//批量溯源 -- 完成后 -- findTraceReportByPage分页查询
export function batchTraceSource() {
  return request({
    url: "/creation/batchTraceSource",
    method: "post",
  });
}

//分页查询溯源报表数据
export function findTraceReportByPage(data) {
  return request({
    url: "/creation/findTraceParseQueue",
    method: "get",
    data,
  });
}

//查询批量溯源总字符
export function findTraceParseQueueTotalCharacter() {
  return request({
    url: "/creation/findTraceParseQueueTotalCharacter",
    method: "get",
  });
}

//删除已上传溯源任务列表
export function delTraceSourceTask(data) {
  return request({
    url: "/creation/delTraceSourceTask",
    method: "post",
    data,
  });
}

//清空批量溯源文件队列
export function delTracesourceQueue() {
  return request({
    url: "/uploader/delTracesourceQueue",
    method: "post",
  });
}

//查询溯源记录中的可溯源文件列表
export function findTraceAuthorizationList(params) {
  return request({
    // url : "/creation/findTraceAuthorizationList/" + data.get("reportId"),
    url: "/creation/findTraceAuthorizationList",
    method: "get",
    params,
  });
}

// 获取溯源设置
export function getTraceSetup() {
  return request({
    url: "/creation/getTraceSourceSetting",
    method: "get",
  });
}

// 修改溯源设置
export function postTraceSetup(data) {
  return request({
    url: "/creation/traceSourceSetting",
    method: "post",
    data,
  });
}

// 授权生成支付二维码
export function payOtherOrderPay(data) {
  return request({
    url: "/pay/otherOrderPay",
    method: "post",
    data,
  });
}

// 查询微信二维码扫码状态
export function PaywechatOtherOrderQueryQrcode(data) {
  return request({
    url: "/pay/wechatOtherOrderQueryQrcode",
    method: "post",
    data,
  });
}

// 字符支付
export function payAuthorizationCharacterPay(data) {
  return request({
    url: "/pay/authorizationCharacterPay",
    method: "post",
    data,
  });
}

//删除历史溯源
export function delHisTraceSource(data) {
  return request({
    url: "/creation/delTraceSource",
    method: "post",
    data,
  });
}

//删除历史溯源
export function bindCard(data) {
  return request({
    url: "/recommendcode/bind",
    method: "post",
    data,
  });
}
//插件版溯源
export function pluginTraceSource(data) {
  return request({
    url: "/creation/pluginTraceSource",
    method: "post",
    data,
  });
}

// 上传word文档
export function wrodFileToHtml(data) {
  return request({
    url: "/common/wrodFileToHtml",
    method: "post",
    data,
  });
}

// 文件上传溯源
export function uploadFileTraceSource(data) {
  return request({
    url: "/creation/uploadFileTraceSource",
    method: "post",
    data,
  });
}
// 文件上传溯源
export function getTraceSourceResultList(params) {
  return request({
    url: "/creation/getTraceSourceResultList",
    method: "get",
    params,
  });
}

export function preserveWord(data) {
  return request({
    url: "/common/htmlToWordDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
