import crewordRequest from "./crewordRequest";
import request from "./request";

export function boughtAndChecked(params) {
  return crewordRequest({
    method: "GET",
    url: "/contextual/boughtAndChecked",
    params,
  });
}
export function findSelectedLib(params) {
  return crewordRequest({
    method: "GET",
    url: "/contextual/findSelectedLib",
    params,
  });
}
export function setting(params) {
  return crewordRequest({
    method: "GET",
    url: "/contextual/setting",
    params,
  });
}
export function contextualList(params) {
  return crewordRequest({
    url: "/contextual/list",
    method: "get",
    params,
  });
}
export function contextualRecommend(params) {
  return crewordRequest({
    url: "/contextual/recomend",
    method: "get",
    params,
  });
}
export function getProductListGroup(data) {
  return request({
    url: "/side/getProductListGroup",
    method: "post",
    data,
  });
}

// 文库菜单列表
export function getlibrarylist(data) {
  return crewordRequest({
    url: "/contextual/library",
    method: "post",
    data,
  });
}

export function gettemplateAndSayingLibrary(data) {
  return crewordRequest({
    url: "/contextual/templateAndSayingLibrary",
    method: "post",
    data,
  });
}
