import axios from 'axios'
import store from 'store/index'
import { getToken } from '@/utils/auth'
import { Loading } from 'element-ui'
import router from '@/router'
import Cookies from 'js-cookie'
import { MessageBox } from 'element-ui'
// let time = true

const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV == 'production'
      ? `${process.env.VUE_APP_BASE_API}api`
      : '/api',
  timeout: 300000,
})

myAxios.interceptors.request.use(
  (config) => {
    let TokenObj = getToken()
    TokenObj = TokenObj ? JSON.parse(TokenObj) : ''
    let Token = JSON.parse(localStorage.getItem('auth_token'))
    if (TokenObj || Token) {
      config.headers.Authorization = TokenObj
        ? `${TokenObj.token_type} ${TokenObj.access_token}`
        : `bearer  ${Token}`
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return config
  },
  // (config) => {
  //   let TokenObj = getToken();
  //   if (TokenObj) {
  //     TokenObj = JSON.parse(TokenObj);
  //     config.headers.Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`;
  //   } else {
  //     config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN;
  //   }
  //   return config;
  // },
  (error) => {
    store.commit('playLifeLoading', false)
    return Promise.reject(error)
  }
)

myAxios.interceptors.response.use(
  (config) => {
    return config.data
  },
  (error) => {
    let code = error.toString().split('code')[1]
    let token = getToken()

    if (code == 401 && token && store.state.tipsSwitch) {
      let loadingInstance2 = Loading.service({ fullscreen: true })
      loadingInstance2.close()
      // time = false
      store.commit('setTipsSwitch', false)
      if (window.localStorage.getItem('auth_token')) {
        window.localStorage.removeItem('auth_token')
      }
      if (token) {
        Cookies.set('auth_token', '', {
          expires: -1,
          path: '/',
          domain: '.writemall.com',
        })
        // window.location.reload(true)
      }
      MessageBox.alert(
        '登录过期或在其他电脑设备上登录,请重新登录',
        '登录状态异常',
        {
          confirmButtonText: '确定',
          callback: (action) => {
            window.loginFun()
            store.commit('setTipsSwitch', true)
          },
        }
      )
    }
    if (code == 401 && store.state.tipsSwitch && !token) {
      let loadingInstance2 = Loading.service({ fullscreen: true })
      loadingInstance2.close()
      // console.log('反抄袭无token');

      if (window.localStorage.getItem('auth_token')) {
        window.localStorage.removeItem('auth_token')
      }
      if (getToken()) {
        Cookies.set('auth_token', '', {
          expires: -1,
          path: '/',
          domain: '.writemall.com',
        })
        // window.location.reload(true)
      }

      if (
        router.currentRoute.path != '/AuthRedirect' &&
        router.currentRoute.path != '/' &&
        router.currentRoute.path != '/infoCompileIndex' &&
        router.currentRoute.path != '/templatetHtml' &&
        router.currentRoute.path != '//goldenSentence/goldenSentenceIndex' &&
        router.currentRoute.path != '/traceSource' &&
        router.currentRoute.path != '/dataAnalysisIndex' &&
        router.currentRoute.path != '/dataCollectionToolBar' &&
        router.currentRoute.path != '/ocr' &&
        router.currentRoute.path != '/downloadGuide' &&
        router.currentRoute.path != '/VIPcenter' &&
        router.currentRoute.path != '/shoppingMall' &&
        router.currentRoute.path != '/recommendExperience' &&
        router.currentRoute.path != '/checking' &&
        router.currentRoute.path != '/disk' &&
        router.currentRoute.path != '/register' &&
        router.currentRoute.path != '/professional' &&
        router.currentRoute.path != '/indexPage'
      ) {
        store.commit('setTipsSwitch', false)
        MessageBox.alert('检测到当前未登录,请重新登录', '登录状态异常', {
          confirmButtonText: '确定',
          callback: (action) => {
            window.loginFun()
            store.commit('setTipsSwitch', true)
          },
        })
      }
    }
    return Promise.reject(error)
  }
)

export default myAxios
