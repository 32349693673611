<template>
  <div class="welfare" v-show="isShow">
    <i class="el-icon-close close" @click="isShow = false"></i>
    <div class="tab">
      <div
        class="tab-item"
        :class="{ active: currentIndex == index }"
        v-for="(item, index) in tabList"
        :key="index"
        @click="handleClick(item, index)"
      >
        <img
          :src="
            currentIndex == index
              ? require('assets/images/gift-active.png')
              : require('assets/images/gift.png')
          "
          alt=""
        />
        {{ item.label }}
      </div>
    </div>
    <component :is="component"></component>
  </div>
</template>

<script>
import FreeGetVip from "./FreeGetVip";
import InvitationFirend from "./InvitationFirend";
import activityPage from "./activityPage";
export default {
  components: {
    FreeGetVip,
    activityPage,
    InvitationFirend,
  },
  created() {
    this.$bus.$on("showGrit", (flag = 0) => {
      this.isShow = true;
      if (flag == 1) {
        this.component = "InvitationFirend";
        this.currentIndex = 1;
      }
    });
  },
  data() {
    return {
      currentIndex: 0,
      component: "FreeGetVip",
      isShow: false,
      tabList: [
        {
          id: 1,
          label: "免费领取",
          component: "FreeGetVip",
        },
        {
          id: 2,
          label: "邀请有礼",
          component: "InvitationFirend",
        },
        {
          id: 3,
          label: "活动页面",
          component: "activityPage",
        },
      ],
    };
  },
  methods: {
    handleClick(item, index) {
      this.component = item.component;
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.welfare {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 367px;
  background: #fff;
  z-index: 9999999;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .tab {
    display: flex;
    width: 94%;
    margin: 0 auto;
    .active {
      color: #ff6900;
    }
    background: #fff;
    height: 40px;
    .tab-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
      font-size: 16px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}
</style>