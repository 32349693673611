<template>
  <div class="FreeGetBtns">
    <img
      @click="
        $router.push({
          name: 'TwentyBig',
        })
      "
      :src="require('assets/bgc333.png')"
      alt=""
    />
    <img
      @click="freeGet"
      v-if="!isShow"
      :src="require('assets/images/freeGet.png')"
      alt=""
    />
    <img
      @click="invitation"
      :src="require('assets/images/firend.png')"
      alt=""
    />
  </div>
</template>

<script>
import { getToken } from "utils/auth.js";
import { check } from "api/common";
export default {
  data() {
    return {
      isShow: false,
    };
  },
  created() {
    this.check();
  },
  methods: {
    freeGet() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      this.$bus.$emit("showFreeGetVip");
    },
    async check() {
      const res = await check();
      this.isShow = res.data;
    },
    invitation() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });
        return window.loginFun();
      }
      this.$bus.$emit("showInvitationFirend");
    },
  },
};
</script>

<style lang="scss" scoped>
.FreeGetBtns {
  position: absolute;
  top: 100px;
  left: 100px;
  display: flex;
  z-index: 10;
  flex-direction: column;
  img {
    margin: 10px 0;
    width: 133px;
    height: 46px;
    cursor: pointer;
  }
}
</style>
