<template>
  <div class="newHeader">
    <div
      class="header"
      :class="
        routeName == 'InfoCompileIndex' ||
        routeName == 'templatetHtml' ||
        routeName == 'GoldenSentenceIndex' ||
        routeName == 'downloadPage' ||
        routeName == 'firmDynamic' ||
        routeName == 'cooperationPage' ||
        routeName == 'feedBackPage' ||
        routeName == 'join_about' ||
        routeName == 'IndexPage' ||
        routeName == 'shoppingMall' ||
        routeName == 'aboutus' ||
        routeName == 'professional' ||
        routeName == 'speedPage' ||
        routeName == 'help_center'
          ? 'color'
          : ''
      "
    >
      <img
        @click="navClick({ url: '/indexPage' })"
        class="logo"
        :src="
          routeName == 'InfoCompileIndex' ||
          routeName == 'templatetHtml' ||
          routeName == 'GoldenSentenceIndex' ||
          routeName == 'downloadPage' ||
          routeName == 'firmDynamic' ||
          routeName == 'cooperationPage' ||
          routeName == 'feedBackPage' ||
          routeName == 'join_about' ||
          routeName == 'IndexPage' ||
          routeName == 'shoppingMall' ||
          routeName == 'aboutus' ||
          routeName == 'professional' ||
          routeName == 'speedPage' ||
          routeName == 'help_center'
            ? require('assets/images/logowit.png')
            : require('assets/images/logowit1.png')
        "
        alt=""
      />
      <div class="nav">
        <div
          class="nav-item"
          v-for="(item, i) in navList"
          @click="navClick(item)"
          @mouseover="handleMouseOver(item)"
          :class="item.children ? 'showItemInfo' : ''"
          :key="i"
        >
          {{ item.title }}
          <i v-if="item.children" class="el-icon-arrow-down"></i>

          <div class="downMenu">
            <div class="sidebarList">
              <div
                class="list_item"
                @click.stop="infoClick(item1.labelId, item1.path)"
                v-for="item1 in sidebarList[index].children"
                :key="item1.labelId"
              >
                {{ item1.conName }}
              </div>

              <div class="perch_item" v-for="item in 5" :key="item"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getlibrarylist } from '@/api/dropDownList'
import qs from 'qs'
export default {
  name: 'newHeader',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    routeName: {},
  },
  data() {
    //这里存放数据
    return {
      navIndex: '0',
      childIndex: 0,
      index: 0,
      isShowDownMenu: false,
      navList: [
        // {
        //   title: 'AI创作平台',
        //   url: 'http://work.writemall.com/',
        // },
        {
          title: '专业服务',
          url: '/professional',
          children: 1,
        },
        {
          title: '专业文库',
          url: '/infoCompileIndex',
          children: 1,
        },
        {
          title: '优质模板',
          url: '/templatetHtml',
          children: 1,
        },
        {
          title: '闪亮金句',
          url: '/goldenSentence/goldenSentenceIndex',
          children: 1,
        },

        {
          title: '软件下载',
          url: '/downloadPage',
        },
        {
          title: '企业动态',
          url: '/firmDynamic',
        },
        {
          title: '合作咨询',
          url: '/cooperationPage',
        },
        // {
        //   title: '商城服务',
        //   url: '/shoppingMall',
        // },
        // {
        //   title: "加入我们",
        //   url: "/join_about",
        // },
      ],
      sidebarList: [
        {
          name: '文库',
          index: '1',
          children: [],
        },
        {
          name: '金句',
          index: '2',
          children: [],
        },
        {
          name: '模板',
          index: '3',
          children: [],
        },
        {
          name: '专业服务',
          index: '4',
          children: [
            {
              labelId: '11',
              conName: '微平台',
              path: 'compliance',
            },
            {
              labelId: '12',
              conName: '小语直播',
              path: 'broadcast',
            },
            {
              labelId: '13',
              conName: '契言企业合规',
              path: 'Microplatform',
            },
            {
              labelId: '14',
              conName: '挖潜智库',
              path: 'tanks',
            },
            {
              labelId: '15',
              conName: '专业服务',
              path: 'services',
            },
            {
              labelId: '16',
              conName: '小语绘画',
              path: 'xiaoyu',
            },
          ],
        },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    $route(to, from) {
      this.navList.forEach((item, index) => {
        if (to.path == 'HomePage') {
          this.navIndex = '0'
        }
        if (item.child) {
          const isHas = item.child.some((obj) => {
            // console.log(this.$route.path, obj.url);
            return obj.url == to.path
          })
          // console.log(isHas);
          if (isHas) {
            this.navIndex = item.url
          }
        } else {
          if (to.path == item.url) {
            this.navIndex = item.url
          }
        }
      })
    },
  },
  //方法集合
  methods: {
    // 获取侧边栏数据
    async getSidebarList(parentId, index) {
      const data = {
        labelId: '',
        parentId,
      }

      const res = await getlibrarylist(qs.stringify(data))

      if (res.code == 200) {
        this.sidebarList[index].children = res.data
      } else {
        this.$message({
          message: res.message,
        })
      }
    },

    navClick(val) {
      console.log(val.url)
      if (val.title === 'AI创作平台') {
        // window.location.href = val.url;
        window.open(val.url)
        return
      }
      if (val.title === '专业服务') {
        this.$bus.$emit('professional', 'home')
        return
      }
      this.$router.push({ path: val.url })
    },

    childClick(val) {
      this.childIndex = val.index1
      let name = val.item.name
      let url = val.item.url
      val.item.name = val.item1.name
      val.item.url = val.item1.url
      val.item1.name = name
      val.item1.url = url
      this.$router.push({ path: val.item1.url })
    },

    handleMouseOver(item) {
      if (item.title == '专业文库') {
        this.index = 0
      }
      if (item.title == '优质模板') {
        this.index = 1
      }
      if (item.title == '闪亮金句') {
        this.index = 2
      }
      if (item.title == '专业服务') {
        this.index = 3
      }
      if (item.children) {
        this.isShowDownMenu = true
      } else {
        this.isShowDownMenu = false
      }
    },

    handleMouseOut() {
      this.isShowDownMenu = false
    },

    infoClick(labelId, val) {
      if (this.index === 3) {
        this.$bus.$emit('professional', val)
        this.$bus.$emit('changeNavIndex', '文库')
        this.$router.push({
          name: 'professional',
        })
      }
      if (this.index === 0) {
        this.$bus.$emit('changeNavIndex', '文库')
        this.$router.push({
          name: 'InfoCompileIndex',
          query: {
            labelId,
          },
        })
      }

      if (this.index === 1) {
        this.$bus.$emit('changeNavIndex', '模板')
        this.$router.push({
          name: 'templatetHtml',
          query: {
            labelId,
          },
        })
      }

      if (this.index === 2) {
        this.$bus.$emit('changeNavIndex', '金句')
        this.$router.push({
          name: 'GoldenSentenceIndex',
          query: {
            labelId,
          },
        })
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSidebarList(7, 0)
    this.getSidebarList(3, 1)
    this.getSidebarList(2, 2)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style lang="scss" scoped>
.newHeader {
  .header {
    // position: absolute;
    // top: 0;
    // z-index: 11;
    // width: 100%;
    display: flex;
    align-items: center;
    height: 65px;
    // box-sizing: border-box;
    .logo {
      cursor: pointer;
      width: 110px;
      height: auto;
    }

    .downMenu {
      display: none;
      box-shadow: 1px 3px 12px 1px rgba(166, 188, 212, 0.39);
      background-color: rgba(255, 255, 255, 1);
      transition: all 0.3s;
      position: absolute;
      z-index: 11;
      bottom: 0;
      width: 700px;
      border-radius: 5px;
      transform: translateY(100%);
      z-index: 2006;

      .sidebarList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .perch_item {
          width: 25%;
        }
        .list_item {
          line-height: 14px;
          font-size: 14px;
          font-weight: normal;
          width: 25%;
          text-align: center;
          margin: 10px 0;
          color: #000;

          &:hover {
            color: #ff6900;
          }
        }
      }
    }
    .isShow {
      height: 313px;
    }
    .nav {
      // flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      .nav-item {
        height: 100%;
        line-height: 65px;
        font-size: 16px;
        color: #333;
        // margin-right: 59px;
        width: 140px;
        cursor: pointer;
        &:hover {
          font-weight: bold;
          font-size: 18px;
        }
      }

      .showItemInfo {
        &:hover {
          .downMenu {
            display: block;
          }
        }
      }
    }

    > img {
      width: 108px;
      height: 37px;
      margin-right: 74px;
    }
  }
  .color {
    > .nav {
      .nav-item {
        color: #fff;
      }
    }
  }
  .open {
    background-color: rgba(255, 255, 255, 0.9);
    > .nav {
      .nav-item {
        color: #333;
        cursor: pointer;
      }
    }
    .userInfo {
      .vipServer {
        color: #333;
      }
      .user {
        color: #333;
      }
    }
  }
}
</style>
