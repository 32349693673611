<template>
  <div class="Suspension" :class="{ hide: isShowMenu }">
    <div class="code" @click="showCode">
      <img :src="require('assets/images/code.png')" alt="" />官方QQ
      <div>微信群</div>
    </div>
    <div class="service" @click="showCustomerService">
      <img :src="require('assets/images/service.png')" alt="" />联系客服
    </div>
    <!-- <div class="phone" @click="showPhone">
      <img :src="require('assets/images/phone.png')" alt="" />联系电话
    </div>
    <div class="Telephone" @click="showCustomerServicePhone">
      <img :src="require('assets/images/Telephone.png')" alt="" />客服专线
    </div> -->

    <!-- <div class="shopping" @click="openShopping">
      <img :src="require('assets/images/shopping.png')" alt="" />成语官网
    </div> -->

    <div class="customerServiceCode" v-show="isShowCode2">
      <img :src="path" alt="" />
      <div>联系电话</div>
      <div>400-825-6828</div>
      <div>商务洽谈</div>
      <div>13828584514</div>
      <div>客服专线</div>
      <div>19925122169</div>
    </div>
    <div class="serviceCode" v-show="isShowCode">
      <div>
        <img :src="require('assets/images/xcx.jpg')" alt="" />
        <div>成语小程序</div>
      </div>
      <div>
        <img :src="require('assets/images/code1.jpg')" alt="" />
        <div>微信公众号</div>
      </div>
      <div>
        <img :src="require('assets/images/dy_code.png')" alt="" />
        <div>抖音官方号</div>
      </div>
      <div>
        <img :src="require('assets/images/qq_code.png')" alt="" />
        <div>QQ群技术支持</div>
      </div>
    </div>
    <!-- <div class="servicePhone">
      <div>联系电话</div>
      <div>400-825-6828</div>
      <div>商务洽谈</div>
      <div>13828584514</div>
    </div>
    <div class="customerServicePhone">
      <div>客服专线</div>
      <div>19925122169</div>
    </div> -->
    <!-- <img :src="require('assets/images/service.png')" alt="" /> -->
    <!-- <div class="out" v-if="!isShowMenu" @click="showMenu">
      <img :src="require('assets/images/service.png')" alt="" />
      <div>客<br />服</div>
    </div>
    <div v-else class="inner" @click="showMenu">
      <i class="el-icon-arrow-right"></i>
    </div> -->
  </div>
</template>

<script>
import { getToken } from "utils/auth";
export default {
  data() {
    return {
      isShowCode: false,
      isShowCode2: false,

      isShowMenuBtn: true,
      isShowMenu: false,
      imageArr: [
        // require("assets/images/zh.png"),
        require("assets/images/myh.png"),
        require("assets/images/yz.png"),
        // require("assets/images/zj.png"),
      ],

      path: null,
    };
  },
  methods: {
    showCode() {
      this.isShowCode = !this.isShowCode;
      this.isShowCode2 = false;

      if (this.isShowCode) {
        this.isShowMenuBtn = false;
      } else {
        this.isShowMenuBtn = true;
      }
      // this.isShowMenuBtn = false;
    },
    showMenu() {
      this.isShowMenu = !this.isShowMenu;
      this.isShowCode = false;
      this.isShowCode2 = false;
    },

    showCustomerService() {
      this.isShowCode = false;
      this.isShowCode2 = !this.isShowCode2;
      if (this.isShowCode2) {
        const random = Math.floor(Math.random() * 2 - 0.01);
        // console.log(random);
        this.path = this.imageArr[random];
        this.isShowMenuBtn = false;
      } else {
        this.isShowMenuBtn = true;
      }
    },
    openShopping() {
      try {
        let TokenObj = getToken();
        let access_token = "";
        if (TokenObj) {
          TokenObj = JSON.parse(TokenObj);
          access_token = `${TokenObj.access_token}`;
        }
        // eslint-disable-next-line
        OpenBrowser(`https://www.writemall.com?token=${access_token}`);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    const random = Math.floor(Math.random() * 2 - 0.01);
    // console.log(random);
    this.path = this.imageArr[random];
  },
};
</script>

<style scoped>
.Suspension {
  position: fixed;
  z-index: 9999;
  top: 40%;
  right: 1%;
  min-width: 90px;
  height: 250px;
  background: #ffffff;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  border-radius: 38px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  /* z-index: 99; */
  /* transform: translateX(92px); */
  transition: all 0.3s;
}

.Suspension .inner {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  left: -30px;
  top: 40%;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  background: #ffffff;
  z-index: 10;
  font-size: 18px;
}

.Suspension .out {
  position: absolute;
  width: 40px;
  height: 80px;
  border-radius: 20px 0 0 20px;
  left: -40px;
  top: 40%;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  background: #ffffff;
  z-index: 10;
  font-size: 14px;
}
/* .btn i {
  margin-right: 7px;
} */
.hide {
  transform: translateX(0px) !important;
}

.Suspension > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 12px;
  cursor: pointer;
}
/* customerServiceCode */
.Suspension .customerServiceCode {
  position: absolute;
  /* width: 150px;
  height: 150px; */
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  top: -40px;
  left: -165px;
}
.Suspension .customerServiceCode::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -5px;
  width: 0;
  height: 0;
  transform: rotate(-135deg);
  border-left: 10 px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #fff;
}
.customerServiceCode img {
  width: 130px !important;
  height: 130px !important;
}
.Suspension .serviceCode {
  position: absolute;
  width: 600px;
  height: 180px;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  top: -30px;
  left: -620px;
  text-align: center;
}
.Suspension .servicePhone {
  position: absolute;
  /* width: 150px;
  height: 150px; */
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  top: 50px;
  left: -145px;
  font-size: 16px;
}
/* customerServicePhone */
.Suspension .customerServicePhone {
  position: absolute;
  /* width: 150px;
  height: 150px; */
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  box-shadow: 0px 0px 21px 3px rgba(88, 93, 113, 0.14);
  top: 140px;
  left: -140px;
  font-size: 16px;
}

.Suspension .serviceCode {
  display: flex;
  flex-direction: row;
  /* font-size: 16; */
}
.serviceCode > div {
  flex: 1;
}
.Suspension .customerServicePhone::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -5px;
  width: 0;
  height: 0;
  transform: rotate(-135deg);
  border-left: 10 px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #fff;
}
.serviceCode img {
  width: 100% !important;
  height: 100% !important;
}
.Suspension .servicePhone::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -5px;
  width: 0;
  height: 0;
  transform: rotate(-135deg);
  border-left: 10 px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #fff;
}
.Suspension .serviceCode::before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -5px;
  width: 0;
  height: 0;
  transform: rotate(-135deg);
  border-left: 10 px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid #fff;
}
.Suspension img {
  width: 30px;
  height: 30px;
}
.customerServiceCode > div:nth-child(even) {
  font-size: 18px;
  color: #3683f2;
  font-weight: bold;
  margin: 10px 0;
}
.customerServiceCode > div:nth-child(odd) {
  margin-bottom: 10px;
  font-size: 16px;
}
.servicePhone > div:nth-child(odd) {
  font-size: 18px;
  color: #3683f2;
  font-weight: bold;
  margin: 10px 0;
}
</style>
