import request from './request'
import { requestApi } from '@/utils/request'
import crewordRequest from './crewordRequest'

//发送验证码,获取验证码ID
export function getMobileAchCaptchas(data) {
  return request({
    url: '/common/getMobileAchCaptchas',
    method: 'post',
    data,
  })
}

//发送验证码,获取验证码ID
export function getMobileAchCaptcha(data) {
  return request({
    url: '/common/getMobileAchCaptchas',
    method: 'post',
    data,
  })
}

//激活会员码
export function bindCardNumber(data) {
  return request({
    url: '/card/bindCardNumber',
    method: 'post',
    data,
  })
}

//校验验证码
export function checkMobileAchCaptchas(data) {
  return request({
    url: '/common/checkMobileAchCaptchas',
    method: 'post',
    data,
  })
}

//上传文件
export function upload(data) {
  return request({
    url: '/common/upload',
    method: 'post',
    data,
  })
}

// 获取合同
export function findContract() {
  return request({
    url: '/common/findContract',
    method: 'post',
  })
}

// common/signContract
export function signContract(data) {
  return request({
    url: '/common/signContract',
    method: 'post',
    data
  })
}
export function findContractByCode(data) {
  return request({
    url: '/common/findContractByCode',
    method: 'post',
    data
  })
}
export function verifyIdNumber(data) {
  return request({
    url: '/common/verifyIdNumber',
    method: 'post',
    data
  })
}
// 下载文件
export function downloadFile(data) {
  return request({
    url: '/common/downloadFile',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

//返回预览地址
export function previewUrl(data) {
  return request({
    url: '/common/preview',
    method: 'post',
    data,
  })
}

//html to word
export function preserveWord(data) {
  return request({
    url: '/common/htmlToWordDownload',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function check() {
  return request({
    url: '/card/check/3DaysPLUSCard',
    method: 'post',
  })
}

// 添加热度
export function addHotRecord(data) {
  return crewordRequest({
    url: '/common/addHotRecord',
    method: 'post',
    data,
  })
}

//判断用户是否有免费次数
export function isHasFreeCount(data) {
  return crewordRequest({
    url: '/menuSetting/check',
    method: 'post',
    data,
  })
}

export function userPortfolioInfo(data) {
  return request({
    url: '/portfolio/userPortfolioInfo',
    method: 'post',
    data,
  })
}

export function bind() {
  return request({
    url: '/card/bind/3DaysPLUSCard',
    method: 'post',
  })
}

// export function check() {
//   return request({
//     url: "/card/check/3DaysPLUSCard",
//     method: "post",
//   });
// }

// 支付宝支付
export function pcOrderPay(data) {
  return request({
    url: '/alipay/pcOrderPay',
    method: 'post',
    data,
  })
}

export function getEsDateNum() {
  return crewordRequest({
    url: '/copyright/getEsDateNum',
    method: 'post',
  })
}

// 添加热度
export function LibraryIndexList() {
  return crewordRequest({
    url: '/common/LibraryIndexList',
    method: 'post',
  })
}

export function preservePDF(data) {
  return request({
    url: '/common/htmlToPdfDownload',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

// 企业动态tab
export function findEnterpriseDynamics() {
  return request({
    url: '/helpManual/findEnterpriseDynamics',
    method: 'get',
  })
}

// 企业动态list
export function findDynamicInfoList(data) {
  return request({
    url: '/helpManual/findDynamicInfoList',
    method: 'post',
    data,
  })
}

export function getFindRightsAndInterests() {
  return crewordRequest({
    url: '/common/findRightsAndInterests',
    method: 'post',
  })
}
