<template>
  <div class="activityPage">
    <div class="main">
      <img class="images" :src="require('assets/images/activity.png')" alt="" />
      <img
        class="btn"
        @click="submit"
        :src="require('assets/images/activityBtn.png')"
        alt=""
      />
      <div class="content_url">
        <input type="text" v-model="url" placeholder="请输入链接" />
      </div>
      <div class="upload">
        <el-upload
          :http-request="uploadUserIndex"
          class="avatar-uploader"
          action=""
          accept=".gif,.jpg,.jpeg,.png,.gif,.jpg,.png"
          :show-file-list="false"
        >
          <img
            v-if="imageUrl"
            :src="'https://fafs.antiplagiarize.com/' + imageUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </div>

    <!-- activityBtn.png -->
  </div>
</template>

<script>
import { upload } from "api/common.js";
import { addContactUs } from "api/header";
export default {
  data() {
    return {
      url: "",
      imageUrl: "",
    };
  },
  methods: {
    async uploadUserIndex(files) {
      const { file } = files;
      let from = new FormData();
      from.append("file", file);
      const res = await upload(from);
      if (res.code == 200) {
        this.imageUrl = res.data;
      }
    },
    async submit() {
      if (this.url == "") {
        this.$message.error("请输入作品链接");
        return;
      }
      if (this.imageUrl == "") {
        this.$message.error("请上传对应平台个人首页截图");
        return;
      }
      const temp = this.$store.state.user_info;
      const params = {
        content: this.url,
        mailbox: "",
        mobile: temp.mobile,
        name: temp.nickName,
        pictures: [this.imageUrl],
        platform: 3,
        type: 2,
      };
      const res = await addContactUs(params);
      if (res.code == 200) {
        this.$message.success("提交成功");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activityPage {
  width: 367px;
  height: 650px;
  overflow: auto;
  .main {
    position: relative;
    .images {
      width: 100%;
    }
    .upload {
      position: absolute;
      bottom: 310px;
      border-radius: 6px;
      left: 17%;
      background: #fff;
      border: 1px solid #d9d9d9;
      &:hover {
        border-color: #409eff;
      }
      // transform: translateX(-50%);
    }
    .content_url {
      position: absolute;
      width: 70%;
      bottom: 430px;
      left: 50%;
      transform: translateX(-50%);
      height: 40px;
      background: #fff;
      border: 2px solid #8bcdf8;
      border-radius: 24px;
      box-sizing: border-box;
      padding-left: 15px;
      input {
        width: 90%;
        height: 35px;
      }
    }
    .btn {
      cursor: pointer;
      position: absolute;
      width: 70%;
      bottom: 250px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .avatar-uploader {
    ::v-deep .el-upload {
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
}
</style>