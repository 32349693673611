<template>
  <div class="power" v-show="isShowDownLoadTips">
    <el-dialog
      title="提示"
      :visible.sync="isShowDownLoadTips"
      :modal-append-to-body="true"
      width="300px"
    >
      <div style="text-align: center">更多功能请下载成语极速版/插件版体验</div>
      <span slot="footer" class="dialog-footer">
        <div class="option">
          <div class="close" @click="isShowDownLoadTips = false">取消</div>
          <div
            class="downLoad"
            @click="
              $router.push('/downloadGuide');
              isShowDownLoadTips = false;
            "
          >
            下载
          </div>
        </div>
      </span>
    </el-dialog>
    <!-- <div class="title">更多功能请下载插件版成语体验</div>
    <div class="option">
      <div class="close" @click="isShowDownLoadTips = false">取消</div>
      <div
        class="downLoad"
        @click="
          $router.push('/downloadGuide');
          isShowDownLoadTips = false;
        "
      >
        下载
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowDownLoadTips: false,
    };
  },

  mounted() {
    window.ShowDownLoadTips = () => {
      this.isShowDownLoadTips = true;
    };
  },
};
</script>

<style lang="scss" scoped>
.power {
  .title {
    height: 70px;
    line-height: 50px;
    text-align: center;
    // flex: 1;
  }
  .option {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    > div {
      cursor: pointer;
    }
    .downLoad {
      color: #eb441e;
    }
  }
}
</style>
