import { render, staticRenderFns } from "./FreeGetVip.vue?vue&type=template&id=969c6042&scoped=true&"
import script from "./FreeGetVip.vue?vue&type=script&lang=js&"
export * from "./FreeGetVip.vue?vue&type=script&lang=js&"
import style0 from "./FreeGetVip.vue?vue&type=style&index=0&id=969c6042&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "969c6042",
  null
  
)

export default component.exports