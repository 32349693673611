import Vue from "vue";
import vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);

import { checkUserRealName } from "api/copyrightService";

const SET_USER_INFO = "SET_USER_INFO";
const CLEAR_REAL_NAME = "CLEAR_REAL_NAME";
const CHECK_REAL_NAME = "CHECK_REAL_NAME";
const CHECK_USER_OPTIONS = "CHECK_USER_OPTIONS";

const state = {
  user_info: null,
  sidebarActive: 0,
  tipsSwitch: true,
  imgList: [],
  copyrightServiceSideBar: [
    {
      title: "版权实务",
      name: "CopyrightPractice",
      active: true,
    },
    {
      title: "信息汇编",
      name: "InfoCompileIndex",
      active: false,
    },
    {
      title: "实名认证",
      name: "RealName",
      active: false,
    },
    {
      title: "收录确权",
      name: "Included",
      active: false,
    },
    {
      title: "会员服务",
      name: "VipServe",
      active: false,
    },
    {
      title: "电子数据指纹核验",
      name: "Fingerprint",
      active: false,
    },
  ],
  realNameData: null,
  onlineBasePath: "https://fafs.antiplagiarize.com/",
  loading: false,
  shopNav: false,
  lockClickText: {},
  buyTipsInfo: {},
};

const mutations = {
  beforeShowNavfun(state) {
    state.shopNav = false;
  },
  createdShowNavfun(state) {
    state.shopNav = true;
  },
  //设置用户信息
  [SET_USER_INFO](state, payload) {
    state.user_info = payload.userInfo;
  },
  GetimgList(state, payload) {
    state.imgList = payload;
  },
  //清除实名数据
  [CLEAR_REAL_NAME](state) {
    state.realNameData = null;
  },
  //实名验证请求
  [CHECK_REAL_NAME](state) {
    checkUserRealName()
      .then((res) => {
        if (res.code == 200) {
          state.realNameData = res.data;
        } else {
          state.realNameData = null;
        }
      })
      .catch((e) => {
        state.realNameData = null;
      });
  },
  /**
   * 全局验证登录和实名信息
   * @param { Boolean } payload.ignoreReal 是否忽略实名验证
   * @param { Function } payload.afterCheck 在验证完成后回调
   * */
  [CHECK_USER_OPTIONS](state, payload) {
    const vm = new Vue();
    if (!state.user_info) {
      vm.$message({
        type: "success",
        message: "请先登录账号",
      });
      vm.$bus.$emit("unAuthorized");

      return;
    }
    if (!payload.ignoreReal && !state.realNameData) {
      vm.$message({
        type: "success",
        message: "请先完成实名认证",
      });
      vm.$bus.$emit("realNamePopup");
      return;
    }
    if (payload.afterCheck && typeof payload.afterCheck === "function") {
      payload.afterCheck();
    }
  },
  ChangeSideBarId(state, payload) {
    state.sidebarActive = payload;
  },
  setLockClickText(state, payload) {
    state.lockClickText = payload;
  },
  setBuyTipsInfo(state, payload) {
    state.buyTipsInfo = payload;
  },
  setTipsSwitch(state, payload) {
    state.tipsSwitch = payload;
  },
  changeCopyrightServiceSideBar(state, payload) {
    state.copyrightServiceSideBar.map((item) => {
      item.active = false;
    });
    payload.item.active = true;
    state.copyrightServiceSideBar.map((item) => {
      if (item.name == payload.item.name) {
        item = payload.item;
      }
    });
  },
  //设置全局loading状态
  playLifeLoading(state, status) {
    state.loading = status;
  },
};

const actions = {};

export default new vuex.Store({
  state,
  mutations,
  actions,
  plugins: [createPersistedState()],
});
