var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newHeader"},[_c('div',{staticClass:"header",class:_vm.routeName == 'InfoCompileIndex' ||
      _vm.routeName == 'templatetHtml' ||
      _vm.routeName == 'GoldenSentenceIndex' ||
      _vm.routeName == 'downloadPage' ||
      _vm.routeName == 'firmDynamic' ||
      _vm.routeName == 'cooperationPage' ||
      _vm.routeName == 'feedBackPage' ||
      _vm.routeName == 'join_about' ||
      _vm.routeName == 'IndexPage' ||
      _vm.routeName == 'shoppingMall' ||
      _vm.routeName == 'aboutus' ||
      _vm.routeName == 'professional' ||
      _vm.routeName == 'speedPage' ||
      _vm.routeName == 'help_center'
        ? 'color'
        : ''},[_c('img',{staticClass:"logo",attrs:{"src":_vm.routeName == 'InfoCompileIndex' ||
        _vm.routeName == 'templatetHtml' ||
        _vm.routeName == 'GoldenSentenceIndex' ||
        _vm.routeName == 'downloadPage' ||
        _vm.routeName == 'firmDynamic' ||
        _vm.routeName == 'cooperationPage' ||
        _vm.routeName == 'feedBackPage' ||
        _vm.routeName == 'join_about' ||
        _vm.routeName == 'IndexPage' ||
        _vm.routeName == 'shoppingMall' ||
        _vm.routeName == 'aboutus' ||
        _vm.routeName == 'professional' ||
        _vm.routeName == 'speedPage' ||
        _vm.routeName == 'help_center'
          ? require('assets/images/logowit.png')
          : require('assets/images/logowit1.png'),"alt":""},on:{"click":function($event){return _vm.navClick({ url: '/indexPage' })}}}),_c('div',{staticClass:"nav"},_vm._l((_vm.navList),function(item,i){return _c('div',{key:i,staticClass:"nav-item",class:item.children ? 'showItemInfo' : '',on:{"click":function($event){return _vm.navClick(item)},"mouseover":function($event){return _vm.handleMouseOver(item)}}},[_vm._v(" "+_vm._s(item.title)+" "),(item.children)?_c('i',{staticClass:"el-icon-arrow-down"}):_vm._e(),_c('div',{staticClass:"downMenu"},[_c('div',{staticClass:"sidebarList"},[_vm._l((_vm.sidebarList[_vm.index].children),function(item1){return _c('div',{key:item1.labelId,staticClass:"list_item",on:{"click":function($event){$event.stopPropagation();return _vm.infoClick(item1.labelId, item1.path)}}},[_vm._v(" "+_vm._s(item1.conName)+" ")])}),_vm._l((5),function(item){return _c('div',{key:item,staticClass:"perch_item"})})],2)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }