import axios from 'axios'
import store from 'store/index'
import { getToken } from '@/utils/auth'
import { MessageBox } from 'element-ui'
import { Loading } from 'element-ui'
import router from '@/router'
import Cookies from 'js-cookie'
let pending = []
let cancelToken = axios.CancelToken
let removePending = (config) => {
  //查找数组中是否存在相同请求，存在则取消
  for (let p in pending) {
    if (pending[p].u === config.url.split('?')[0] + '&' + config.method) {
      pending[p].f() //执行取消操作
      pending.splice(p, 1) //数组移除当前请求
    }
  }
}
const myAxios = axios.create({
  baseURL: '/crewordapi',
  timeout: 50000,
})

myAxios.interceptors.request.use(
  (config) => {
    if (config.url == '/copyright/queryOfficicalWord') {
      removePending(config)
      config.cancelToken = new cancelToken((c) => {
        // pending存放每一次请求的标识，config.url请求路径，config.params参数，config.method请求方法
        pending.push({
          u: config.url.split('?')[0] + '&' + config.method,
          f: c,
        })
      })
    }
    let TokenObj = getToken()
    TokenObj = TokenObj ? JSON.parse(TokenObj) : ''
    let Token = JSON.parse(localStorage.getItem('auth_token'))
    if (TokenObj || Token) {
      config.headers.Authorization = TokenObj
        ? `${TokenObj.token_type} ${TokenObj.access_token}`
        : `bearer  ${Token}`
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN
    }
    return config
  },
  // (config) => {
  //   let TokenObj = getToken();
  //   if (TokenObj) {
  //     TokenObj = JSON.parse(TokenObj);
  //     config.headers.Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`;
  //   } else {
  //     config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN;
  //   }
  //   return config;
  // },
  (error) => {
    store.commit('playLifeLoading', false)
    return Promise.reject(error)
  }
)

myAxios.interceptors.response.use(
  (config) => {
    return config.data
  },
  (error) => {
    let code = error.toString().split('code')[1]
    let token = getToken()
    console.log(token, store.state.tipsSwitch, code)
    if (code == 401 && token && store.state.tipsSwitch) {
      let loadingInstance2 = Loading.service({ fullscreen: true })
      loadingInstance2.close()
      // time = false
      // console.log('成语有token');
      store.commit('setTipsSwitch', false)
      if (window.localStorage.getItem('auth_token')) {
        window.localStorage.removeItem('auth_token')
      }
      console.log('code11')
      if (token) {
        Cookies.set('auth_token', '', {
          expires: -1,
          path: '/',
          domain: '.writemall.com',
        })
        // window.location.reload(true)
      }
      // console.log('code22');
      MessageBox.alert(
        '登录过期或在其他电脑设备上登录,请重新登录',
        '登录状态异常',
        {
          confirmButtonText: '确定',
          callback: (action) => {
            window.loginFun()
            store.commit('setTipsSwitch', true)
          },
        }
      )
    }
    if (code == 401 && store.state.tipsSwitch && !token) {
      let loadingInstance2 = Loading.service({ fullscreen: true })
      loadingInstance2.close()

      // console.log('成语无token');
      if (window.localStorage.getItem('auth_token')) {
        window.localStorage.removeItem('auth_token')
      }
      if (getToken()) {
        Cookies.set('auth_token', '', {
          expires: -1,
          path: '/',
          domain: '.writemall.com',
        })
        // window.location.reload(true)
      }

      if (
        router.currentRoute.path != '/AuthRedirect' &&
        router.currentRoute.path != '/' &&
        router.currentRoute.path != '/infoCompileIndex' &&
        router.currentRoute.path != '/templatetHtml' &&
        router.currentRoute.path != '//goldenSentence/goldenSentenceIndex' &&
        router.currentRoute.path != '/traceSource' &&
        router.currentRoute.path != '/dataAnalysisIndex' &&
        router.currentRoute.path != '/dataCollectionToolBar' &&
        router.currentRoute.path != '/ocr' &&
        router.currentRoute.path != '/downloadGuide' &&
        router.currentRoute.path != '/VIPcenter' &&
        router.currentRoute.path != '/shoppingMall' &&
        router.currentRoute.path != '/recommendExperience' &&
        router.currentRoute.path != '/checking' &&
        router.currentRoute.path != '/disk' &&
        router.currentRoute.path != '/register' &&
        router.currentRoute.path != '/professional' &&
        router.currentRoute.path != '/indexPage'
      ) {
        store.commit('setTipsSwitch', false)
        MessageBox.alert('检测到当前未登录,请重新登录', '登录状态异常', {
          confirmButtonText: '确定',
          callback: (action) => {
            window.loginFun()
            store.commit('setTipsSwitch', true)
          },
        })
      }
    }
    return Promise.reject(error)
  }
)

export default myAxios
