<template>
  <div v-if="isDialogShow" class="registrationDialog">
    <div class="registrationDialog_box">
      <div class="title_img"></div>

      <div class="close_box" @click="closeClick"></div>

      <div class="text_img"></div>

      <div class="input_box">
        <div class="registered">
          <div class="text">邀请小伙伴注册会员</div>
          <div class="content">
            <div class="link_test">https://www.writemall...</div>
            <div class="cup" @click="copyClick(1)">复制</div>
          </div>

          <div class="posters">
            <div
              class="registered"
              v-for="item in shartList"
              :key="item.id"
              @click="sg(item.id, item.shareUrl, item.shareMsg)"
            >
              <div class="content" v-if="item.id == 0">
                <div class="name">生成海报</div>
                <img :src="item.icon" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div class="zifu_text">你和小伙伴都将免费获得7天VIP权益</div>
      </div>

      <div class="log_box"></div>
    </div>
  </div>
</template>
<script>
import { findUserRecommendCode } from "api/header";
export default {
  name: "registrationDialog",
  data() {
    return {
      isPosters: false,
      sgObj: {},
      shartList: [],
      isDialogShow: false,
      code: "",
      characterNum: "",
      discountMsg: "",
      isShowBtn: false,
      ishaveid: "",
      obj: "",
    };
  },
  watch: {
    isDialogShow: {
      handler: function (val) {
        if (val) {
          this.getFindUserRecommendCode();
        }
      },
    },
  },
  methods: {
    handelClick() {
      this.$emit("registerClick", true);
    },

    closeClick() {
      this.isDialogShow = false;
    },

    async getFindUserRecommendCode() {
      let user = JSON.parse(window.localStorage.getItem("vuex"));

      if (user.user_info) {
        this.isShowBtn = true;
        this.ishaveid = user.user_info.haveid;
      } else {
        this.isShowBtn = false;
        this.ishaveid = "";
      }
      const res = await findUserRecommendCode();
      // console.log(res);
      if (res.code != 200) {
        return this.$message.success({
          message: res.message,
        });
      }
      this.obj = res.data;
      this.code = res.data.code;
      this.characterNum = res.data.characterNum;
      this.discountMsg = res.data.discountMsg;
    },

    copyClick(val) {
      let oInput = document.createElement("textarea");
      val == 1
        ? (oInput.value =
            "我的邀请码是：" +
            this.code +
            "\n" +
            this.discountMsg +
            "\n" +
            "写手智能科技公司专属注册地址:" +
            this.obj.shareUrl)
        : (oInput.value = this.code);
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;

      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: "已成功复制到剪切板",
        type: "success",
      });
      oInput.remove();
    },

    openShareGiftBag: function () {
      this.isDialogShow = true;

      this.getFindUserRecommendCode();
    },
  },
  mounted() {
    window.openShareGiftBag = this.openShareGiftBag;
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.shart {
  width: 80%;
}
.link_test {
  font-size: 13px;
}
.cup {
  cursor: pointer;
}
.registrationDialog {
  z-index: 3000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;

  .registrationDialog_box {
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 329.9px;
    height: 429.8px;
    padding-top: 60px;
    background: url("~assets/images/exclusive-registration/bgc2.png") no-repeat;
    background-size: 100% 100%;
    .title_img {
      position: absolute;
      top: -15px;
      left: 50%;
      width: 160px;
      height: 76px;
      transform: translateX(-50%);
      background: url("~assets/images/exclusive-registration/title.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .close_box {
      position: absolute;
      top: 35px;
      right: 15px;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background: url("~assets/images/exclusive-registration/close.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .text_img {
      margin: 0 auto 0;
      width: 294.6px;
      height: 89.8px;
      background: url("~assets/images/exclusive-registration/text9.png")
        no-repeat;
      background-size: 100% 100%;
    }

    .input_box {
      margin: 0 auto;
      width: 310.2px;
      height: 191.4px;
      background: url("~assets/images/exclusive-registration/bgc5.png")
        no-repeat;
      background-size: 100% 100%;
      transform: translateY(-10px);
      .registered {
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .text {
          text-shadow: 0px 0px 28px #1067e8;
          margin: 30px 0 20px;
          text-align: center;
        }

        .content {
          display: flex;
          box-sizing: border-box;
          padding: 20px;
          background-color: #248eff;
          justify-content: space-between;
          align-items: center;
          width: 251px;
          height: 40px;
          background: #248eff;
          border: 2px solid #ffffff;
          border-radius: 19px;

          .copy_text {
            cursor: pointer;
          }
        }

        .registered {
          transform: translateY(20px);
          color: #fff;
          display: flex;

          justify-content: center;
          align-items: center;
          text-align: center;

          .text {
            text-shadow: 0px 0px 28px #1067e8;
            width: 70px;
            font-size: 13px;
            text-align: center;
          }

          .content {
            margin-left: 5px;
            padding: 20px;
            display: flex;
            box-sizing: border-box;
            font-size: 13px;
            background-color: #248eff;
            justify-content: space-between;
            align-items: center;
            width: 251px;
            height: 40px;
            background: #248eff;
            border: 2px solid #ffffff;
            border-radius: 19px;

            color: #fff;
            font-weight: bold;
            .name {
              font-size: 16px;
              text-align: left;
            }
            img {
              width: 27px;
              height: 27px;
            }

            .copy_text {
              cursor: pointer;
            }
          }
        }
      }

      .zifu_text {
        transform: translateY(20px);

        margin: 0px auto 0;
        color: #fff;
        line-height: 1.5;
        font-size: 13px;
        text-align: center;
      }
    }

    .log_box {
      margin: 20px auto;

      width: 159px;
      height: 53px;
      background: url("~assets/images/exclusive-registration/log1.png")
        no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
