<template>
  <div class="userList" v-show="userShow">
    <div class="head">
      <h3>
        绑定用户总数: <span>{{ dataTotal }}</span
        ><img src="~assets/user.png" alt="" />
      </h3>
      <div class="inputs">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="keyWords"
          @change="search"
        >
        </el-input>
        <img @click="userShow = false" src="~assets/cha.png" alt="" />
      </div>
    </div>
    <div class="baner">
      <div class="text" v-for="item in dataList" :key="item.id">
        <div class="imgher">
          <img v-if="!item.headImg" src="../../assets/zhuomian.png" alt="" />
          <img v-if="item.headImg" :src="item.headImg" alt="" />
        </div>
        <div class="tit">
          <h2>
            {{ item.realName ? item.realName : item.nickName }}
            <div class="headImgs">
              <div class="grade vipIcon" v-if="item.memberlevel == 2"></div>
              <div class="grade svipIcon" v-if="item.memberlevel == 3"></div>
            </div>
          </h2>
          <div>{{ item.mobile }}</div>
        </div>
      </div>
    </div>
    <div class="food">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="currentChange"
        :page-size="12"
        :total="dataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getStaffManage } from "api/service";
export default {
  name: "userList",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      userShow: false,
      dataList: [],
      dataTotal: 0,
      page: 1,
      keyWords: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 搜索
    search() {
      this.getStaffManage();
    },
    // 分页
    currentChange(val) {
      this.page = val;
      this.getStaffManage();
    },
    // 数据获取
    async getStaffManage() {
      let params = {
        type: 1,
        key: this.keyWords,
        page: this.page,
        limit: 12,
      };
      let data = await getStaffManage(params);
      if (data.code == 200) {
        this.dataList = data.data.data;
        this.dataTotal = data.data.total;
      } else {
        this.dataList = "";
        this.dataTotal = 0;
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getStaffManage();
    this.$bus.$on("showuserList", () => {
      this.userShow = true;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
.userList {
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  width: 913px;
  height: 521px;
  background: #f6f8fc;
  box-shadow: 0px 0px 15px 3px rgba(193, 193, 193, 0.3);
  border-radius: 10px;
  padding: 24px 20px;
  box-sizing: border-box;
  .head {
    display: flex;
    justify-content: space-between;
    .inputs {
      display: flex;
      img {
        cursor: pointer;
        width: 12px;
        height: 12px;
        margin-left: 30px;
      }
    }
  }
  h3 {
    display: flex;
    // align-items: center;
    font-size: 20px;
    color: #333333;
    span {
      color: #4587ff;
      margin: 0 10px;
    }
    img {
      width: 24px;
      height: 24px;
      margin-top: -10px;
    }
  }
  .baner {
    margin-top: 13px;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-between;
    .text {
      display: flex;
      width: 280px;
      height: 94px;
      background: #ffffff;
      border-radius: 5px;
      margin-top: 16px;
      padding: 23px 20px;
      box-sizing: border-box;
      .imgher {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 18px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    .tit {
      h2 {
        display: flex;
        font-size: 16px;
        color: #333333;
        margin-bottom: 10px;
        .grade {
          width: 60px;
          height: 20px;
          margin-left: 9px;
        }
        .vipIcon {
          @include backgroundGroup("~assets/vip_icon.png");
        }

        .svipIcon {
          @include backgroundGroup("~assets/SVIP_icon.png");
        }
      }
    }
  }
  .food {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    box-sizing: border-box;
  }
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 2px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
